import React, { useContext, useState } from "react";
import Store from "store";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";

export default function Login({ match }) {
  const { state, dispatch } = useContext(Store);

  dispatch({ type: "USER_LOGOUT" });
  return <Redirect to="/user/login" />;
}
