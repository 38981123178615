import React from "react";

// Store Context is the global context that is managed by reducers.

let url = {
  app: "https://admin.formito.com",
  do: "https://do.formito.com",
  api: "https://fbtest-27dd8.appspot.com/v1",
  apiWeb: "https://fbtest-27dd8.appspot.com",
  storage: "https://storage.googleapis.com/formitoups/",
};

let apiKeys = {
  stripe: "pk_live_BVw5EaflG90MrQum2jZ3joII00LOf9BjCz",
};

if (process.env.NODE_ENV !== "production") {
  url = {
    app: "http://localhost:3000",
    do: "http://do.formito.test",
    api: "http://api.formito.test/v1",
    apiWeb: "http://localhost:8000",
    storage: "http://api.formito.test/storage/",
  };

  apiKeys = {
    stripe: "pk_test_V9jj6qcRBSdvFwEClXR5QfAA00QFqtK9Ir",
  };
}

const app = {
  ready: false,
  url,
  apiKeys,
};

const user = {
  auth: false,
  id: 1,
  name: "Hossein",
  token: "",
};

const forms = [];

const form = {
  id: 1,
  slug: null,
  version: "1.0",
  name: "",
  activeResponse: 0,
  /*
  cover: {
    title: 'Cover Title',
    subtitle: 'Cover Subtitle',
    img_bg: ''
  },
  blocks: [
    {
      type: 'text',
      align: 'left',
      props: {
        text: 'Hi there,'
      },
      validation: {},
    },
    {
      type: 'text',
      align: 'left',
      props: {
        text: 'How may I help you?'
      },
      validation: {},
    },
    {
      type: 'input',
      align: 'right',
      props: {

      }
    },
    {
      type: 'select',
      align: 'right',
      props: {
        options: [ 'Apple', 'Orange', 'Banana' ],
      }
    }
  ],
  settings: {
    is_public: true,
    show_progress: true,
  },
  responses: [
    {
      id: 1,
      date: '12313454354',
      ip: '127.0.0.1',
      url: 'http://example.com',
      messages: [
        {
          type: 'text',
          align: 'left',
          name: '',
          content: 'Hi there,'
        },
        {
          type: 'text',
          align: 'left',
          name: '',
          content: 'What is your name?'
        },
        {
          type: 'input',
          align: 'right',
          name: 'name',
          content: 'Hossein'
        },
        {
          type: 'text',
          align: 'left',
          name: '',
          content: 'Nice to meet you. How old are you?'
        },
        {
          type: 'select',
          align: 'right',
          name: 'age',
          content: '24-35'
        },
      ]
    },
    {
      id: 2,
      date: '12313454354',
      ip: '127.0.0.1',
      url: 'http://example.com',
      messages: [
        {
          type: 'text',
          align: 'left',
          name: '',
          content: 'Hi there,'
        },
        {
          type: 'text',
          align: 'left',
          name: '',
          content: 'What is your name?'
        }
      ]
    },
    {
      id: 3,
      date: '12313454354',
      ip: '127.0.0.1',
      url: 'http://example.com',
      messages: [
        {
          type: 'text',
          align: 'left',
          name: '',
          content: 'Hi there,'
        },
        {
          type: 'text',
          align: 'left',
          name: '',
          content: 'This is the third one...'
        }
      ]
    }
  ]
  */
};

const editor = {
  inspector: {
    isOpen: false,
    blockIndex: -1,
  },
};

const site = {};
const responses = [];

const Store = React.createContext({
  app,
  user,
  forms,
  form,
  editor,
  site,
  responses,
});

export default Store;
