import React, {Component} from 'react';
import axios from 'axios';
import {CardElement, injectStripe} from 'react-stripe-elements';


const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};


class CheckoutForm extends Component {
  state = {
    errorMessage: '',
    plan: 'standard-y1-v1',
    paying: false,
  };

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  async submit(ev) {
    this.setState({paying: true});
    this.setState({errorMessage: ''});
    let {token} = await this.props.stripe.createToken({name: "Name"});

    if (token === undefined) {
      this.setState({paying: false});
      return false;
    }

    axios.post(`/user/subscription/create`, {stripe_token: token.id, plan: this.state.plan})
    .then(response => {
      console.log(response);
      window.location.reload();
    })
    .catch(error => {
      if (error.data.message) {
        this.setState({errorMessage: error.data.message});
      }
      else {
        this.setState({errorMessage: 'An error happened. Please try again later.'});
      }
      this.setState({paying: false});
    })

    /*
    let response = await fetch("/charge", {
      method: "POST",
      headers: {"Content-Type": "text/plain"},
      body: token.id
    });

    if (response.ok) console.log("Purchase Complete!")
    */
  }

  render() {
    return (
      <div className="checkout">
        <select className="custom-select" value={this.state.plan} onChange={(e) => this.setState({plan: e.target.value})}>
          <option value="lite-m1-v1">Lite Plan (monthly) — $18 now and every month</option>
          <option value="standard-m1-v1">Standard Plan (monthly) — $35 now and every month</option>
          <option value="pro-m1-v1">Pro Plan (monthly) — $70 now and every month</option>
          <option value="lite-y1-v1">Lite Plan (yearly) — $180 now and every year</option>
          <option value="standard-y1-v1">Standard Plan (yearly) — $360 now and every year</option>
          <option value="pro-y1-v1">Pro Plan (yearly) — $720 now and every year</option>
        </select>
        <br /><br />
        <CardElement onChange={this.handleChange} {...createOptions()} />
        <div className="text-danger" role="alert">
          {this.state.errorMessage}
        </div>
        <br />
        <button className="btn btn-block btn-info" onClick={this.submit} disabled={this.state.paying}>{this.state.paying ? 'One Second...' : 'Pay'}</button>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
