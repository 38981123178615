import { useEffect } from "react";

export function usePersistedContext(context, key = "formito_app_state") {
  /*
  const persistedContext = localStorage.getItem(key);
  let st = JSON.parse(persistedContext);
  st.app.ready = false;
  st.forms = [];
  st.form = {};
  st.editor.inspector = {
    blockIndex: -1
  };
  return persistedContext ? st : context;
  */

  const persistedContext = localStorage.getItem('formito_app_user');
  const user = JSON.parse(persistedContext);
  return persistedContext ? {...context, user} : context;
}

export function usePersistedReducer([state, dispatch], key = "formito_app_state") {
  /*
  useEffect(() => localStorage.setItem(key, JSON.stringify(state)), [state]);
  return [state, dispatch];
  */


  useEffect(() => localStorage.setItem('formito_app_user', JSON.stringify(state.user)), [state.user]);
  return [state, dispatch];
}
