import React, { useContext, useState, useEffect } from "react";
import Store from "store";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import { TwitterPicker } from "react-color";
import Popup from "./popup";
import PlaceholderAvatar from "assets/img/avatar.png";
import RepeaterArray from "components/repeaterArray";
import TheEditor from "components/TheEditor";

export default function FormSettings() {
  const { state, dispatch } = useContext(Store);
  const [icon, setIcon] = useState();
  const [btnName, setBtnName] = useState("Save Icon");
  const settings = state.form.settings;
  let can_remove_branding = false;

  if (
    ["standard-y1-v1", "standard-m1-v1", "pro-y1-v1", "pro-m1-v1"].includes(
      state.user.stripe_plan
    )
  ) {
    can_remove_branding = true;
  }

  let email_block_name = "";
  state.form.blocks.map((block) => {
    if (block.type === "email" && block.name) {
      email_block_name = "{{" + block.name + "}}";
      return false;
    }
  });

  const def_email_me = {
    send_to: state.user.email,
    reply_to: email_block_name,
    subject: "Formito: New response to {{formito_name}}",
    message:
      'Your formito "{{formito_name}}" has a new response:\n\n{{all_answers}}\n\nView the response in your account:\n{{formito_responses_url}}\n\nYou can turn off or configure notification message in notifications section of settings page.\n\nSincerely\nFormito Team',
  };

  const def_email_respondent = {
    notify: false,
    send_incomplete: false,
    send_to: email_block_name,
    reply_to: state.user.email,
    subject: "Formito: Thank you for filling out {{formito_name}}",
    message: "Hello,\n\nWe’ve received your submission.\n\nThank you.",
  };

  let available_variables = "";
  available_variables += "{{formito_name}} \t\t" + state.form.name + "\n";
  available_variables += "{{formito_slug}} \t\t" + state.form.slug + "\n";
  available_variables +=
    "{{formito_responses_url}} \t" +
    "https://admin.formito.com/form/" +
    state.form.slug +
    "/responses" +
    "\n";
  available_variables +=
    "{{all_answers_html}} \t\tAll of the followings in HTML format" + "\n";
  available_variables +=
    "{{all_answers_plain}} \t\tAll of the followings in plain text format" +
    "\n";
  state.form.blocks.map((block, index) => {
    if (block.align === "right" && block.name) {
      available_variables += "{{" + block.name + "}}" + "\n";
    }
  });

  const saveLauncher = () => {
    setBtnName("Saving...");
    const data = new FormData();
    data.append("icon", icon);

    axios
      .post(`/form/update/${state.form.slug}`, data)
      .then((response) => {
        setBtnName("Save Image");
      })
      .catch((error) => {});
  };

  const saveIcon = () => {
    setBtnName("Saving...");
    const data = new FormData();
    data.append("media", icon);
    data.append("removeOld", state.form.settings.icon);

    axios
      .post(`/form/upload/icon/${state.form.slug}`, data)
      .then((response) => {
        setBtnName("Saved");
        dispatch({ type: "FORM_ICON_SET", payload: response.data.url });
        setTimeout(() => {
          setBtnName("Save Icon");
          setIcon(null);
          document.getElementById("btn-form-save").click();
        }, 500);
      })
      .catch((error) => {});
  };

  return (
    <section className="main-content">
      <div className="container">
        <br />
        <div className="accordion accordion-arrow-right" id="acc-settings">
          <div className="card">
            <h6 className="card-title">
              <a
                className="collapsed"
                data-toggle="collapse"
                href="#tab-launcher"
              >
                Launcher
              </a>
            </h6>

            <div
              id="tab-launcher"
              className="collapse"
              data-parent="#acc-settings"
            >
              <div className="card-body">
                <div className="form-group row align-items-center">
                  <label className="col-3 col-form-label">Icon</label>
                  <div className="col-9">
                    <div className="uploader-image custom-file file-group">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => setIcon(e.target.files[0])}
                      />
                      <label className="file-value" htmlFor="customFile">
                        <img
                          src={
                            state.form.settings.icon
                              ? state.app.url.storage + state.form.settings.icon
                              : PlaceholderAvatar
                          }
                        />
                        <span>Click to change icon</span>
                      </label>

                      {icon && (
                        <button
                          className="btn btn-sm btn-secondary ml-5"
                          onClick={saveIcon}
                        >
                          {btnName}
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <hr />

                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    Welcome Message
                  </label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control mb-4"
                      placeholder="Title"
                      value={state.form.popup.title || ""}
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_POPUP_SET",
                          payload: { title: e.target.value },
                        })
                      }
                    />

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Message"
                      value={state.form.popup.text || ""}
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_POPUP_SET",
                          payload: { text: e.target.value },
                        })
                      }
                    />
                  </div>
                </div>

                <hr />

                <div className="form-group row">
                  <label className="col-3 col-form-label">Buttons</label>
                  <div className="col-9">
                    {!state.user.subscribed && !state.user.on_trial && (
                      <div className="alert alert-warning mb-7">
                        <div className="flexbox">
                          <div>
                            This feature is not included in your billing plan.
                            Please upgrade your plan to unlock this feature.
                          </div>
                          <Link
                            to="/billing"
                            className="btn btn-sm btn-warning"
                          >
                            Upgrade
                          </Link>
                        </div>
                      </div>
                    )}

                    <RepeaterArray
                      array={state.form.settings.launcher.buttons}
                      //index={props.index}
                      title="name"
                      dispatch="FORM_BUTTONS_SET"
                      defaultAddState={{ name: "Button Name", slug: "" }}
                      render={(item, set, index) => (
                        <>
                          <div className="form-group row">
                            <label className="col-4 col-form-label">Name</label>
                            <div className="col-8">
                              <input
                                type="text"
                                className="form-control"
                                value={item.name}
                                onChange={(e) => set("name", e, index)}
                                placeholder="Button Name"
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-4 col-form-label">
                              Chatbot
                            </label>
                            <div className="col-8">
                              <select
                                className="custom-select"
                                value={item.slug || ""}
                                onChange={(e) => set("slug", e, index)}
                              >
                                <option value="" key="none-0"></option>
                                {state.forms.map((form, index) => (
                                  <option value={form.slug} key={index}>
                                    {form.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                    />

                    {/*
                    <br /><br /><br />

                    <div className="switch" onClick={() => dispatch({type: 'FORM_LAUNCHER_SET', payload: {buttonsAlwaysVisible: !state.form.settings.launcher.buttonsAlwaysVisible} })}>
                      <input type="checkbox" className="switch-input"
                        checked={state.form.settings.launcher.buttonsAlwaysVisible}
                        onChange={(e) => dispatch({type: 'FORM_LAUNCHER_SET', payload: {buttonsAlwaysVisible: e.target.checked} })}
                      />
                      <label className="switch-label">&nbsp;&nbsp;Display buttons all the time.</label>
                    </div>
                    */}
                  </div>
                </div>

                <hr />

                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    Display launcher on
                  </label>
                  <div className="col-9">
                    <div
                      className="switch"
                      onClick={() =>
                        dispatch({
                          type: "FORM_LAUNCHER_DEVICES_SET",
                          payload: {
                            mobile: !state.form.settings.launcher.devices
                              .mobile,
                          },
                        })
                      }
                    >
                      <input
                        type="checkbox"
                        className="switch-input"
                        checked={state.form.settings.launcher.devices.mobile}
                        onChange={(e) =>
                          dispatch({
                            type: "FORM_LAUNCHER_DEVICES_SET",
                            payload: { mobile: e.target.checked },
                          })
                        }
                      />
                      <label className="switch-label">&nbsp;&nbsp;Mobile</label>
                    </div>

                    <div
                      className="switch"
                      onClick={() =>
                        dispatch({
                          type: "FORM_LAUNCHER_DEVICES_SET",
                          payload: {
                            tablet: !state.form.settings.launcher.devices
                              .tablet,
                          },
                        })
                      }
                    >
                      <input
                        type="checkbox"
                        className="switch-input"
                        checked={state.form.settings.launcher.devices.tablet}
                        onChange={(e) =>
                          dispatch({
                            type: "FORM_LAUNCHER_DEVICES_SET",
                            payload: { tablet: e.target.checked },
                          })
                        }
                      />
                      <label className="switch-label">&nbsp;&nbsp;Tablet</label>
                    </div>

                    <div
                      className="switch"
                      onClick={() =>
                        dispatch({
                          type: "FORM_LAUNCHER_DEVICES_SET",
                          payload: {
                            desktop: !state.form.settings.launcher.devices
                              .desktop,
                          },
                        })
                      }
                    >
                      <input
                        type="checkbox"
                        className="switch-input"
                        checked={state.form.settings.launcher.devices.desktop}
                        onChange={(e) =>
                          dispatch({
                            type: "FORM_LAUNCHER_DEVICES_SET",
                            payload: { desktop: e.target.checked },
                          })
                        }
                      />
                      <label className="switch-label">
                        &nbsp;&nbsp;Desktop
                      </label>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="form-group row">
                  <label className="col-3 col-form-label">Position</label>
                  <div className="col-9">
                    <select
                      className="custom-select"
                      value={
                        state.form.settings.launcher.position || "bottom-right"
                      }
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_LAUNCHER_SET",
                          payload: { position: e.target.value },
                        })
                      }
                    >
                      <option value="bottom-right">Bottom Right</option>
                      <option value="bottom-left">Bottom Left</option>
                    </select>
                  </div>
                </div>

                <hr />

                <div className="form-group row">
                  <label className="col-3 col-form-label">Blacklist URLs</label>
                  <div className="col-9">
                    <textarea
                      className="form-control"
                      rows={3}
                      placeholder="example.com/blog/"
                      value={state.form.settings.launcher.blacklist}
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_LAUNCHER_SET",
                          payload: { blacklist: e.target.value },
                        })
                      }
                    />
                    <small className="form-text text-mute">
                      Insert each URL in a single line. Launcher do not display
                      on these URLs.
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <h6 className="card-title">
              <a
                className="collapsed"
                data-toggle="collapse"
                href="#tab-landing"
              >
                Landing Page
              </a>
            </h6>

            <div
              id="tab-landing"
              className="collapse"
              data-parent="#acc-settings"
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-3 col-form-label">Title</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={state.form.settings.landing.title}
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_LANDING_SET",
                          payload: { title: e.target.value },
                        })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Text</label>
                  <div className="col-9">
                    <TheEditor
                      content={settings.landing.text}
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_LANDING_SET",
                          payload: { text: e },
                        })
                      }
                    />

                    {/*
                    <textarea className="form-control" rows={3}
                      value={state.form.settings.landing.text}
                      onChange={(e) => dispatch({type: 'FORM_LANDING_SET', payload: {text: e.target.value}})}
                    />
                    */}

                    <br />
                    <p className="small mb-0">
                      Your landing page URL is:{" "}
                      <a
                        href={`${state.app.url.do}/e/${state.form.slug}`}
                        target="_blank"
                      >{`${state.app.url.do}/e/${state.form.slug}`}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <h6 className="card-title">
              <a className="collapsed" data-toggle="collapse" href="#tab-notif">
                Notifications
              </a>
            </h6>

            <div
              id="tab-notif"
              className="collapse"
              data-parent="#acc-settings"
            >
              <div className="card-body">
                <div className="form-group row align-items-center">
                  <label className="col-3 col-form-label">Email me</label>
                  <div className="col-9">
                    <div
                      className="switch switch-inline"
                      onClick={() =>
                        dispatch({
                          type: "FORM_SETTINGS_NOTIFY_EMAIL",
                          payload: !settings.notification.email_each_response,
                        })
                      }
                    >
                      <input
                        type="checkbox"
                        className="switch-input"
                        checked={
                          settings.notification.email_each_response || false
                        }
                        onChange={(e) =>
                          dispatch({
                            type: "FORM_SETTINGS_NOTIFY_EMAIL",
                            payload: e.target.checked,
                          })
                        }
                      />
                      <label className="switch-label"></label>
                    </div>
                  </div>
                </div>

                {settings.notification.email_each_response && (
                  <>
                    <div className="form-group row align-items-center">
                      <label className="col-3 col-form-label">
                        Email even incomplete conversations
                      </label>
                      <div className="col-9">
                        <div
                          className="switch switch-inline"
                          onClick={() =>
                            dispatch({
                              type: "FORM_SETTINGS_NOTIFY_EMAIL_INCOMPLETE",
                              payload: !settings.notification
                                .email_incomplete_response,
                            })
                          }
                        >
                          <input
                            type="checkbox"
                            className="switch-input"
                            checked={
                              settings.notification.email_incomplete_response ||
                              false
                            }
                            onChange={(e) =>
                              dispatch({
                                type: "FORM_SETTINGS_NOTIFY_EMAIL_INCOMPLETE",
                                payload: e.target.checked,
                              })
                            }
                          />
                          <label className="switch-label"></label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-3 col-form-label">
                        Send email to
                      </label>
                      <div className="col-9">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={
                            settings.notification.email_me.send_to ||
                            def_email_me.send_to
                          }
                          onChange={(e) =>
                            dispatch({
                              type: "FORM_SETTINGS_EMAIL_ME",
                              payload: { send_to: e.target.value },
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-3 col-form-label">Reply to</label>
                      <div className="col-9">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={
                            settings.notification.email_me.reply_to ||
                            def_email_me.reply_to
                          }
                          onChange={(e) =>
                            dispatch({
                              type: "FORM_SETTINGS_EMAIL_ME",
                              payload: { reply_to: e.target.value },
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-3 col-form-label">Subject</label>
                      <div className="col-9">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={
                            settings.notification.email_me.subject ||
                            def_email_me.subject
                          }
                          onChange={(e) =>
                            dispatch({
                              type: "FORM_SETTINGS_EMAIL_ME",
                              payload: { subject: e.target.value },
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-3 col-form-label">Message</label>
                      <div className="col-9">
                        <textarea
                          className="form-control form-control-sm"
                          rows={9}
                          value={
                            settings.notification.email_me.message ||
                            def_email_me.message
                          }
                          onChange={(e) =>
                            dispatch({
                              type: "FORM_SETTINGS_EMAIL_ME",
                              payload: { message: e.target.value },
                            })
                          }
                        />
                      </div>
                    </div>
                  </>
                )}

                <hr />

                <div className="form-group row align-items-center">
                  <label className="col-3 col-form-label">
                    Email respondent
                  </label>
                  <div className="col-9">
                    <div
                      className="switch switch-inline"
                      onClick={() =>
                        dispatch({
                          type: "FORM_SETTINGS_EMAIL_RESPONDENT",
                          payload: {
                            notify: !settings.notification.email_respondent
                              .notify,
                          },
                        })
                      }
                    >
                      <input
                        type="checkbox"
                        className="switch-input"
                        checked={
                          settings.notification.email_respondent.notify || false
                        }
                        onChange={(e) =>
                          dispatch({
                            type: "FORM_SETTINGS_EMAIL_RESPONDENT",
                            payload: { notify: e.target.checked },
                          })
                        }
                      />
                      <label className="switch-label"></label>
                    </div>
                  </div>
                </div>

                {settings.notification.email_respondent.notify && (
                  <>
                    {!state.user.subscribed && !state.user.on_trial && (
                      <div className="alert alert-warning mb-7">
                        <div className="flexbox">
                          <div>
                            This feature is not included in your billing plan.
                            Please upgrade your plan if you wish to notify
                            respondent by email.
                          </div>
                          <Link to="/billing" className="btn btn-warning">
                            Upgrade
                          </Link>
                        </div>
                      </div>
                    )}

                    <div className="form-group row align-items-center">
                      <label className="col-3 col-form-label">
                        Email even incomplete conversations
                      </label>
                      <div className="col-9">
                        <div
                          className="switch switch-inline"
                          onClick={() =>
                            dispatch({
                              type: "FORM_SETTINGS_EMAIL_RESPONDENT",
                              payload: {
                                send_incomplete: !settings.notification
                                  .email_respondent.send_incomplete,
                              },
                            })
                          }
                        >
                          <input
                            type="checkbox"
                            className="switch-input"
                            checked={
                              settings.notification.email_respondent
                                .send_incomplete || false
                            }
                            onChange={(e) =>
                              dispatch({
                                type: "FORM_SETTINGS_EMAIL_RESPONDENT",
                                payload: { send_incomplete: e.target.checked },
                              })
                            }
                          />
                          <label className="switch-label"></label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-3 col-form-label">
                        Send email to
                      </label>
                      <div className="col-9">
                        {/*
                      <select className="custom-select"
                        value={settings.notification.email_respondent.send_to || def_email_respondent.send_to}
                        onChange={(e) => dispatch({type: 'FORM_SETTINGS_EMAIL_RESPONDENT', payload: {send_to: e.target.value}})}
                      >
                        {state.form.blocks.map( (block, index) => {
                          if (block.type === 'email') {
                            return <option value={block.name} key={index}>{block.name}</option>
                          }
                        })}
                      </select>
                      */}
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={
                            settings.notification.email_respondent.send_to ||
                            def_email_respondent.send_to
                          }
                          onChange={(e) =>
                            dispatch({
                              type: "FORM_SETTINGS_EMAIL_RESPONDENT",
                              payload: { send_to: e.target.value },
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-3 col-form-label">Reply to</label>
                      <div className="col-9">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={
                            settings.notification.email_respondent.reply_to ||
                            def_email_respondent.reply_to
                          }
                          onChange={(e) =>
                            dispatch({
                              type: "FORM_SETTINGS_EMAIL_RESPONDENT",
                              payload: { reply_to: e.target.value },
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-3 col-form-label">Subject</label>
                      <div className="col-9">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={
                            settings.notification.email_respondent.subject ||
                            def_email_respondent.subject
                          }
                          onChange={(e) =>
                            dispatch({
                              type: "FORM_SETTINGS_EMAIL_RESPONDENT",
                              payload: { subject: e.target.value },
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-3 col-form-label">Message</label>
                      <div className="col-9">
                        <textarea
                          className="form-control form-control-sm"
                          rows={9}
                          value={
                            settings.notification.email_respondent.message ||
                            def_email_respondent.message
                          }
                          onChange={(e) =>
                            dispatch({
                              type: "FORM_SETTINGS_EMAIL_RESPONDENT",
                              payload: { message: e.target.value },
                            })
                          }
                        />
                      </div>
                    </div>
                  </>
                )}

                <hr />

                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    Available variables
                  </label>
                  <div className="col-9">
                    <p>
                      You can use any of the following values inside the
                      notification messages.
                    </p>
                    <pre style={{ whiteSpace: "pre" }}>
                      {available_variables}
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <h6 className="card-title">
              <a
                className="collapsed"
                data-toggle="collapse"
                href="#tab-design"
              >
                Design
              </a>
            </h6>

            <div
              id="tab-design"
              className="collapse"
              data-parent="#acc-settings"
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-3 col-form-label">Color</label>
                  <div className="col-9">
                    <TwitterPicker
                      color={state.form.settings.design.primary_color}
                      onChangeComplete={(color, e) =>
                        dispatch({
                          type: "FORM_DESIGN_SET",
                          payload: { primary_color: color.hex },
                        })
                      }
                      triangle="hide"
                    />
                    <div className="mt-4">
                      <button
                        className="btn btn-sm btn-secondary"
                        onClick={(e) =>
                          dispatch({
                            type: "FORM_DESIGN_SET",
                            payload: { primary_color: "#50a1ff" },
                          })
                        }
                      >
                        Set To Default
                      </button>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    Display progressbar
                  </label>
                  <div className="col-9">
                    <div
                      className="switch switch-inline"
                      onClick={() =>
                        dispatch({
                          type: "FORM_DESIGN_SET",
                          payload: {
                            progressbar: !state.form.settings.design
                              .progressbar,
                          },
                        })
                      }
                    >
                      <input
                        type="checkbox"
                        className="switch-input"
                        checked={
                          state.form.settings.design.progressbar || false
                        }
                        onChange={(e) =>
                          dispatch({
                            type: "FORM_DESIGN_SET",
                            payload: { progressbar: e.target.checked },
                          })
                        }
                      />
                      <label className="switch-label"></label>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    Remove Formito branding
                  </label>
                  <div className="col-9">
                    {can_remove_branding && (
                      <div
                        className="switch switch-inline"
                        onClick={() =>
                          dispatch({
                            type: "FORM_DESIGN_SET",
                            payload: {
                              remove_branding: !state.form.settings.design
                                .remove_branding,
                            },
                          })
                        }
                      >
                        <input
                          type="checkbox"
                          className="switch-input"
                          defaultChecked={
                            state.form.settings.design.remove_branding || false
                          }
                          //onChange={(e) => dispatch({type: 'FORM_DESIGN_SET', payload: {remove_branding: e.target.checked} })}
                        />
                        <label className="switch-label"></label>
                      </div>
                    )}

                    {!can_remove_branding && (
                      <div
                        className="switch switch-inline"
                        data-toggle="modal"
                        data-target="#modal-remove-branding"
                      >
                        <input
                          type="checkbox"
                          className="switch-input"
                          checked={false}
                          disabled
                        />
                        <label className="switch-label"></label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-remove-branding" tabIndex="-1">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <p className="lead">Unable to remove branding</p>
              <p className="mb-0">
                Only paid customers on Standard or Pro plan can remove the
                Formito branding. So upgrade your plan if you wish to remove the
                branding.
              </p>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Keep Formito Branding
              </button>
              <a
                href={state.app.url.app + "/billing"}
                className="btn btn-primary"
              >
                Upgrade Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
