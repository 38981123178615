import React, { useContext, useState, useEffect } from "react";
import Store from "store";
import { IoMdSend } from "react-icons/io";
import * as Utils from 'utils';

export function Preview(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  return (
    <div className="input-group">
      <textarea rows="2" className="form-control" placeholder={block.props.placeholder} autoComplete="off" />
      <button><IoMdSend /></button>
    </div>
  );
}



export function Inspector(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];
  const set = (name, value) => dispatch( Utils.Block.update(name, value, block, props.index) );


  return (
    <div className="quickview-body">

      <div className="form-group row">
        <label className="col-4 col-form-label">Name</label>
        <div className="col-8">
          <input type="text" className="form-control" onChange={(e) => set('name', e)} value={block.name} />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">Placeholder</label>
        <div className="col-8">
          <input type="text" className="form-control" onChange={(e) => set('props.placeholder', e)} value={block.props.placeholder} />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">Delay <small>(sec)</small></label>
        <div className="col-8">
          <input type="number" className="form-control" min="0" max="20" step="0.5"
            onChange={(e) => set('props.delay', e.target.value)}
            value={block.props.delay || 0}
          />
        </div>
      </div>

      <div className="form-group flexbox d-none">
        <label className="col-form-label">Multiline</label>
        <div className="switch switch-inline" onClick={() => set('props.multiline', !block.props.multiline)}>
          <input type="checkbox" className="switch-input" checked={block.props.multiline || false} onChange={(e) => set('props.multiline', e.target.checked)} />
          <label className="switch-label"></label>
        </div>
      </div>


      <div className="divider d-none">Validation</div>


      <div className="form-group row d-none">
        <label className="col-4 col-form-label">Value Type</label>
        <div className="col-8">
          <select className="custom-select" onChange={(e) => set('validation.type', e)} value={block.validation.type}>
            <option value="any">Any</option>
            <option value="email">Email</option>
            <option value="number">Number</option>
          </select>
        </div>
      </div>

      <div className="form-group flexbox">
        <label className="col-form-label">Is required</label>
        <div className="switch switch-inline" onClick={() => set('validation.required', !block.validation.required)}>
          <input type="checkbox" className="switch-input" checked={block.validation.required || false} onChange={(e) => set('validation.required', e.target.checked)} />
          <label className="switch-label"></label>
        </div>
      </div>

    </div>
  )
}


/**
 * Response message
 */
export function Response({message}) {
  return <div className={`message-item block-${message.type}`} dangerouslySetInnerHTML={{__html: message.content}} />
}
