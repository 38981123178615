import React, { useContext, useState } from "react";
import Store from "store";
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from "react-sortable-hoc";
import { IoMdTrash, IoMdCopy, IoMdMove } from "react-icons/io";
import * as Utils from "utils";

const SortableContainer = sortableContainer(({ children }) => {
  return <ol className="repeater">{children}</ol>;
});

const DragHandle = sortableHandle(() => (
  <a className="item-move" href="#">
    <IoMdMove />
  </a>
));

const SortableItem = sortableElement(
  ({ item, itemIndex, props, activeIndex, setActiveIndex }) => {
    const { state, dispatch } = useContext(Store);
    const block = props.block;

    const update = item => {
      dispatch({
        type: "BLOCK_UPDATE",
        payload: {
          index: props.index,
          item: item
        }
      });
    };

    const set = (key, value, index) => {
      let nextBlock = Object.assign({}, JSON.parse(JSON.stringify(block)));

      if (value.target !== undefined) {
        value = value.target.value;
      }

      const nextItem = Utils.Block.next(
        key,
        value,
        nextBlock.props.items[index]
      );

      nextBlock.props.items[index] = nextItem;
      update(nextBlock);
    };

    const remove = index => {
      let nextBlock = Object.assign({}, JSON.parse(JSON.stringify(block)));
      nextBlock.props.items = nextBlock.props.items.filter(
        (e, i) => i !== index
      );
      update(nextBlock);
      return false;
    };

    const duplicate = index => {
      let nextBlock = Object.assign({}, JSON.parse(JSON.stringify(block)));
      nextBlock.props.items.splice(index, 0, nextBlock.props.items[index]);
      update(nextBlock);
      return false;
    };

    const toggleOpen = index => {
      if (index === activeIndex) {
        setActiveIndex(-1);
      } else {
        setActiveIndex(index);
      }
    };

    return (
      <li
        className={
          "repeater-item" + (activeIndex === itemIndex ? " active" : "")
        }
        key={itemIndex}
      >
        <div
          className="repeater-item-header"
          onClick={() => toggleOpen(itemIndex)}
        >
          <div className="header-text">{item[props.title]}</div>
          <div className="repeater-actions">
            <a href="#" onClick={() => duplicate(itemIndex)}>
              <IoMdCopy />
            </a>
            <DragHandle />
            <a
              className="text-danger"
              href="#"
              onClick={() => remove(itemIndex)}
            >
              <IoMdTrash />
            </a>
          </div>
        </div>

        <div className="repeater-item-content">
          {props.render(item, set, itemIndex)}
        </div>
      </li>
    );
  }
);

export default function Repeater(props) {
  const { state, dispatch } = useContext(Store);
  const [activeIndex, setActiveIndex] = useState(-1);
  const block = props.block;

  const update = item => {
    dispatch({
      type: "BLOCK_UPDATE",
      payload: {
        index: props.index,
        item: item
      }
    });
  };

  const add = e => {
    let nextBlock = Object.assign({}, JSON.parse(JSON.stringify(block)));
    nextBlock.props.items.push(props.defaultAddState);
    update(nextBlock);
  };

  function doSort({ oldIndex, newIndex }) {
    dispatch({
      type: "BLOCK_REPEATER_REORDER",
      payload: { index: props.index, oldIndex, newIndex }
    });
    if (activeIndex === oldIndex) {
      setActiveIndex(newIndex);
    }
  }

  return (
    <>
      <SortableContainer
        onSortEnd={doSort}
        useDragHandle
        useWindowAsScrollContainer={true}
      >
        {block.props.items.map((item, index) => (
          <SortableItem
            key={index}
            index={index}
            itemIndex={index}
            item={item}
            props={props}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        ))}
      </SortableContainer>

      <button className="btn repeater-add" onClick={add}>
        Add New
      </button>
    </>
  );
}
