import React, { useContext, useState } from "react";
import Store from "store";

export default function Help() {
  const { state, dispatch } = useContext(Store);


  return (
    <section className="main-content">

      <div className="container">
        <br />
        <div className="accordion accordion-arrow-right" id="acc-help">

          <div className="card">
            <h6 className="card-title">
              <a data-toggle="collapse" href="#tab-profile">I need help with creating my chatbot or displaying it in my website.</a>
            </h6>

            <div id="tab-profile" className="collapse" data-parent="#acc-help">
              <div className="card-body">
                No problem. Please contact us and describe the difficulty you encountered. We'll be happy to help.
              </div>
            </div>
          </div>



          <div className="card">
            <h6 className="card-title">
              <a data-toggle="collapse" href="#tab-wp">How can I display widget in my WordPress website?</a>
            </h6>

            <div id="tab-wp" className="collapse" data-parent="#acc-help">
              <div className="card-body">
                We'll introduce our own WordPress plugin a few months later. Meanwhile, you can use a free plugin such as <a href="https://wordpress.org/plugins/oh-add-script-header-footer/" target="_blank">this one</a> to add our script to your website or any of the single pages. If you had any problem, feel free to contact us for help.
              </div>
            </div>
          </div>



          <div className="card">
            <h6 className="card-title">
              <a data-toggle="collapse" href="#tab-contact-us">How can I contact you?</a>
            </h6>

            <div id="tab-contact-us" className="collapse" data-parent="#acc-help">
              <div className="card-body">
                You can contact us using the Formito on <a href="https://do.formito.com/e/11k" target="_blank">https://do.formito.com/e/11k</a> or send us an email directly to hello@formito.com.
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}
