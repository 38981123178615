import React, { useContext, useState } from "react";
import Store from "store";
import { Link, NavLink, Route, Switch } from "react-router-dom";
import ImgLetter from 'assets/img/letter-light.png';

import { IoMdHome, IoMdConstruct, IoMdBrush, IoMdEye, IoMdSettings, IoMdShare, IoMdChatbubbles, IoMdAlbums, IoIosAddCircle, IoIosAddCircleOutline, IoMdGitBranch, IoMdHelpCircle, IoMdCard } from "react-icons/io";

const menus = [
  {
    url: '/',
    title: 'Home',
    icon: 'home',
  }
]

export default function Sidebar({match}) {
  const { state, dispatch } = useContext(Store);

  const FormNavItems = ({match}) => (
    <>
      <li>
        <NavLink exact to={`/form/${match.params.slug}/editor`}><IoMdConstruct /></NavLink>
        <span className="sidebar-tooltip">Editor</span>
      </li>

      {/*
      <li>
        <NavLink exact to={`/form/${match.params.slug}/design`}><IoMdBrush /></NavLink>
        <span className="sidebar-tooltip">Design</span>
      </li>

      <li>
        <NavLink exact to={`/form/${match.params.slug}/preview`}><IoMdEye /></NavLink>
        <span className="sidebar-tooltip">Preview</span>
      </li>
      */}

      <li>
        <NavLink exact to={`/form/${match.params.slug}/connect`}><IoMdGitBranch /></NavLink>
        <span className="sidebar-tooltip">Connect</span>
      </li>

      <li>
        <NavLink exact to={`/form/${match.params.slug}/share`}><IoMdShare /></NavLink>
        <span className="sidebar-tooltip">Share</span>
      </li>

      <li>
        <NavLink exact to={`/form/${match.params.slug}/responses`}><IoMdChatbubbles /></NavLink>
        <span className="sidebar-tooltip">Responses</span>
      </li>

      <li>
        <NavLink exact to={`/form/${match.params.slug}/settings`}><IoMdSettings /></NavLink>
        <span className="sidebar-tooltip">Settings</span>
      </li>
    </>
  )


  const AddFormNav = () => (
    <li>
      <NavLink exact to="/create"><IoIosAddCircle /></NavLink>
      <span className="sidebar-tooltip">New Formito</span>
    </li>
  )



  return (
    <aside className="sidebar">
      <ul className="sidebar-menu">
        <li className="sidebar-item-header">
          <NavLink exact to="/"><img src={ImgLetter} alt="logo" /></NavLink>
        </li>

        <li>
          <NavLink exact to="/"><IoMdAlbums /></NavLink>
          <span className="sidebar-tooltip">All Forms</span>
        </li>

        <Switch>
          <Route path="/form/:slug" component={FormNavItems} />
          {/*<Route component={AddFormNav} />*/}
        </Switch>

        <li className="mt-auto">
          <NavLink to={`/help`}><IoMdHelpCircle /></NavLink>
          <span className="sidebar-tooltip">Help Center</span>
        </li>

        <li className="">
          <NavLink to={`/billing`}><IoMdCard /></NavLink>
          <span className="sidebar-tooltip">Billing</span>
        </li>

        <li className="">
          <NavLink to={`/settings`}><IoMdSettings /></NavLink>
          <span className="sidebar-tooltip">Settings</span>
        </li>
      </ul>
    </aside>
  )
}
