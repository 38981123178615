import React, { useContext, useState } from "react";
import Store from "store";
import { IoMdTrash, IoMdCopy, IoMdMove } from "react-icons/io";

export default function RepeaterArray(props) {
  const { state, dispatch } = useContext(Store);
  const [activeIndex, setActiveIndex] = useState(-1);
  const array = props.array;

  const update = obj => {
    dispatch({
      type: props.dispatch,
      payload: obj
    });
  };

  const set = (key, value, index) => {
    let nextBlock = [...array];

    if (value.target !== undefined) {
      value = value.target.value;
    }

    nextBlock[index][key] = value;
    update(nextBlock);
  };

  const add = e => {
    let nextBlock = [...array];
    nextBlock.push(props.defaultAddState);
    update(nextBlock);
  };

  const remove = (e, index) => {
    e.preventDefault();
    let nextBlock = [...array];
    nextBlock = nextBlock.filter((e, i) => i !== index);
    update(nextBlock);
    return false;
  };

  const duplicate = (e, index) => {
    e.preventDefault();
    let nextBlock = [...array];
    nextBlock.splice(index, 0, nextBlock[index]);
    update(nextBlock);
    return false;
  };

  return (
    <>
      <ol className="repeater">
        {array.map((item, index) => (
          <li
            className={
              "repeater-item" + (activeIndex === index ? " active" : "")
            }
            key={index}
          >
            <div
              className="repeater-item-header"
              onClick={() => setActiveIndex(index)}
            >
              <div className="header-text">{item[props.title] || "None"}</div>
              <div className="repeater-actions">
                <a href="#" onClick={e => duplicate(e, index)}>
                  <IoMdCopy />
                </a>
                <a
                  className="text-danger"
                  href="#"
                  onClick={e => remove(e, index)}
                >
                  <IoMdTrash />
                </a>
              </div>
            </div>

            <div className="repeater-item-content">
              {props.render(item, set, index)}
            </div>
          </li>
        ))}
      </ol>

      <button className="btn repeater-add" onClick={add}>
        Add New
      </button>
    </>
  );
}
