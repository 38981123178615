import React, { useContext, useState } from "react";
import Store from "store";
import axios from 'axios';

export default function Designer({match}) {
  const { state, dispatch } = useContext(Store);
  const [ file, setFile ] = useState();
  const [ title, setTitle ] = useState();
  const [ subtitle, setSubtitle ] = useState();

  const cover = state.form.cover;

  if ( cover === null ) {
    dispatch({
      type: "FORM_UPDATE_COVER",
      payload: {},
    });
  }

  const setCover = (name, value) => {
    let nextCover = Object.assign({},cover);
    nextCover[name] = value;
    dispatch({
      type: "FORM_UPDATE_COVER",
      payload: nextCover,
    });
  }

  const save = () => {
    const data = new FormData();
    data.append('title', title);
    data.append('subtitle', subtitle);
    data.append('cover', file);

    axios.post('/form/'+ state.form.id +'/cover', data)
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error);
    })
  }

  return (
    <section className="main-content">
      Let's design the UI of {match.params.id}

      <hr />

      <div className="form-group">
        <input type="text" className="form-control" placeholder="Cover Title" value={cover.title} onChange={ (e) => setCover('title', e.target.value) } />
      </div>

      <div className="form-group">
        <input type="text" className="form-control" placeholder="Cover Subtitle" value={cover.subtitle} onChange={ (e) => setCover('subtitle', e.target.value) } />
      </div>

      <input type="file" onChange={ (e) => setCover('img_bg', e.target.files[0]) } />

      <button className="btn btn-primary" onClick={save}>Save</button>
    </section>
  )
}
