import React, { useContext, useState, useEffect, useRef } from "react";
import $ from 'jquery';
import Store from "../store";
import axios from 'axios';
import { Link, Redirect } from "react-router-dom";
import { IoMdMore, IoIosAddCircleOutline } from "react-icons/io";

export default function Onboarding() {
  const { state, dispatch } = useContext(Store);

  if (state.forms.length) {
    //return(<Redirect to='/' />);
  }



  return (
    <p>Onboarding</p>
  )
}
