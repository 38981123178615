import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import Store from "store";
import Inspector from "./Inspector";
import Inserter from "./Inserter";
import Builder from "./Builder";

import { FaHome } from "react-icons/fa";

export default function Editor({ match }) {
  const { state, dispatch } = useContext(Store);
  const [isLoading, setIsLoading] = useState(true);
  const [sticky, setSticky] = useState(false);
  const refInserter = useRef(null);

  useEffect(() => {
    axios
      .get(`/form/show/${match.params.slug}`)
      .then(response => {
        dispatch({ type: "FORM_SYNC", payload: response.data });
        dispatch({ type: "INSPECTOR_OPEN", payload: -1 });
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
      });

    window.onscroll = function() {
      if (refInserter === null || refInserter.current === null) {
        return;
      }

      if (window.pageYOffset > refInserter.current.offsetTop - 110) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };
  }, []);

  function publish() {
    axios
      .post("/script", state.form)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  if (window.innerWidth < 1025) {
    return (
      <section className="main-content">
        <div className="alert alert-warning">
          Our editor doesn't support mobile and tablet devices yet. So please
          use a laptop or desktop computer to create your chatbot.
        </div>
      </section>
    );
  }

  if (isLoading) {
    return (
      <div className="spinner-wrapper">
        <div className="spinner-grow text-info" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <Inspector
        isOpen={state.editor.inspector.isOpen}
        blockIndex={state.editor.inspector.blockIndex}
      />

      <section className="main-content">
        <div className="row">
          <div className="col-3" ref={refInserter}>
            <Inserter position="left" sticky={sticky} />
          </div>

          <div className="col-6" style={{ marginBottom: "50px" }}>
            <Builder />
          </div>

          <div className="col-3">
            <Inserter position="right" sticky={sticky} />
          </div>
        </div>
      </section>
    </>
  );
}
