import React, { useContext, useState, useEffect } from "react";
import Store from "store";
import { IoMdSend } from "react-icons/io";
import * as Utils from "utils";

// Pass information you already know into your typeform via the URL to personalize and segment
export function Preview(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  return (
    <div className="input-group">
      <input
        type="email"
        className="form-control"
        placeholder="Hidden Input"
        readOnly
      />
    </div>
  );
}

export function Inspector(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];
  const set = (name, value) =>
    dispatch(Utils.Block.update(name, value, block, props.index));

  return (
    <div className="quickview-body">
      <div className="form-group row">
        <label className="col-4 col-form-label">Name</label>
        <div className="col-8">
          <input
            type="text"
            className="form-control"
            onChange={e => set("name", e)}
            value={block.name}
          />
        </div>
      </div>

      <div className="divider mt-8">How to use</div>

      <p>
        Pass information you already know into your Formito via a Javascript
        object to personalize and segment.
      </p>
      {/*<p>Consider "Hidden Input" as a private variable that can be set by you. You can set it using a javascript object.</p>*/}
      <p>
        Add the following code to the page you added formito.js script file.
        Feel free to add any other entry to "formito_predefined_variables"
        variable.
      </p>
      <pre
        style={{ whiteSpace: "pre" }}
      >{`window.formito_predefined_variables = {
  "${block.name}": "xyz",
}`}</pre>

      {/*
      <br />
      <h6>URL Parameter</h6>
      <p>Add your hidden input name and values as a parameter to the URL of your chatbot.</p>
      <small style={{color:'#bbb'}}>{`${state.app.url.do}/e/${state.form.slug}`}<span className="text-dark">{`?${block.name}=xyz`}</span></small>
      */}
    </div>
  );
}

/**
 * Response message
 */
export function Response({ message }) {
  return (
    <div className={`message-item block-${message.type}`}>
      {message.content}
    </div>
  );
}
