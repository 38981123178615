import React from "react";
import * as Block from "form/blocks";

export default function TheBlock({ type, children, ...props }) {
  type = type.charAt(0).toUpperCase() + type.slice(1);
  if (!Block[`Block${type}Preview`]) {
    return null;
  }
  return React.createElement(Block[`Block${type}Preview`], props, children);
}
