
export default function editor(state={}, {type, payload}) {
  switch (type) {

    // Inspector
    //
    case 'INSPECTOR_OPEN':
      return {
        ...state,
        inspector: {
          ...state.inspector,
          isOpen: true,
          blockIndex: payload,
        }
      };

    case 'INSPECTOR_CLOSE':
      return {
        ...state,
        inspector: {
          ...state.inspector,
          isOpen: false,
          blockIndex: -1,
        }
      };

    default:
      return state;
  }
}
