import React, { useContext, useState } from "react";
import Store from "store";
import * as Utils from 'utils';
import Repeater from 'components/repeater';

export function Preview(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  return block.props.items.map( (item, index) => (
    <div key={index} className="link-item">
      <h6>{item.title}</h6>
      {item.text && <p>{item.text}</p>}
    </div>
  ))
}



export function Inspector(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];
  const set = (name, value) => dispatch( Utils.Block.update(name, value, block, props.index) );


  return (
    <div className="quickview-body">
      <div className="form-group row">
        <label className="col-4 col-form-label">Delay <small>(sec)</small></label>
        <div className="col-8">
          <input type="number" className="form-control" min="0" max="20" step="0.5"
            onChange={(e) => set('props.delay', e.target.value)}
            value={block.props.delay || 0}
          />
        </div>
      </div>


      <h6 className="divider d-none">Links</h6>

      <Repeater
        block={block}
        index={props.index}
        title='title'
        defaultAddState={{title: 'Title', text: '', slug: ''}}
        render={(item, set, index) => (
          <>
            <div className="form-group row">
              <label className="col-4 col-form-label">Title</label>
              <div className="col-8">
                <input type="text" className="form-control" value={item.title} onChange={e => set('title', e, index)} />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-4 col-form-label">Text</label>
              <div className="col-8">
                <input type="text" className="form-control" value={item.text} onChange={e => set('text', e, index)} />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-4 col-form-label">Open</label>
              <div className="col-8">
                <select className="custom-select" value={item.slug} onChange={e => set('slug', e, index)}>
                  <option value="" key="none-0">None</option>
                  {state.forms.map( (form, index) => (
                    <option value={form.slug} key={index}>{form.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </>
      )} />

    </div>
  )
}


/**
 * Response message
 */
export function Response({message}) {
  if (!message.props || message.props.items === undefined) {
    return null;
  }

  return (
    <div className={`message-item block-${message.type}`}>
      {message.props.items.map( (item, index) => <div key={index} className="link-item">{item.text}</div> )}
    </div>
  )
}
