import React, { useContext, useState, useEffect } from "react";
import Store from "store";
import * as Block from "form/blocks";
import { IoMdHelpCircle } from "react-icons/io";

export default function Inspector() {
  const { state, dispatch } = useContext(Store);
  const props = state.editor.inspector;
  let blockType = "block";

  if (
    state.form.blocks === undefined ||
    props.blockIndex === state.form.blocks.length
  ) {
    return <div></div>;
  }

  if (props.blockIndex > -1 && props.blockIndex < state.form.blocks.length) {
    blockType = state.form.blocks[props.blockIndex].type;
  }

  function closeInspector() {
    dispatch({ type: "INSPECTOR_CLOSE" });
  }

  const type = blockType.charAt(0).toUpperCase() + blockType.slice(1);
  const TheInspector = Block[`Block${type}Inspector`];

  if (!TheInspector) {
    return null;
  }

  return (
    <>
      <div className={"quickview" + (props.isOpen ? " reveal" : "")}>
        <header className="quickview-header">
          <h5>{blockType.replace("_", " ")} settings</h5>
        </header>

        <div className="quickview-content">
          {props.blockIndex >= 0 && (
            <TheInspector type={blockType} index={props.blockIndex} />
          )}

          {props.blockIndex < 0 && (
            <div className="quickview-body">
              <p className="text-muted">
                Click on a message to change settings.
              </p>
              {/*
              <div className="form-group row">
                <label className="col-4 col-form-label">Name</label>
                <div className="col-8">
                  <input type="text" className="form-control" placeholder="Name" />
                </div>
              </div>

              <h6 className="divider">Controls</h6>

              <div className="form-group row">
                <label className="col-6 col-form-label">Email</label>
                <div className="col-6">
                  <input type="email" className="form-control" placeholder="Email" />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-6 col-form-label">Select</label>
                <div className="col-6">
                  <select className="custom-select">
                    <option>Personal</option>
                    <option>Startup</option>
                    <option>Business</option>
                    <option>Enterprise</option>
                  </select>
                </div>
              </div>

              <div className="form-group flexbox">
                <label className="col-form-label">Save changes automatically</label>
                <div className="switch switch-inline">
                  <input type="checkbox" className="switch-input" />
                  <label className="switch-label"></label>
                </div>
              </div>

              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" />
                  <label className="custom-control-label">Required input</label>
                </div>
              </div>

              <div className="form-group">
                <div className="flexbox">
                  <label>Email address</label>
                  <a className="help-toggle" tabIndex="0" data-toggle="popover" data-trigger="focus" data-content="And here's some amazing content. It's very engaging. Right?"><IoMdHelpCircle /></a>
                </div>
                <input type="email" className="form-control" placeholder="name@example.com" />
              </div>

              <div className="form-group">
                <label>Select an option</label>
                <select className="custom-select">
                  <option>Personal</option>
                  <option>Startup</option>
                  <option>Business</option>
                  <option>Enterprise</option>
                </select>
              </div>

              <div className="form-group">
                <label>Example textarea</label>
                <textarea className="form-control" rows="3"></textarea>
              </div>

              <h6 className="divider">Validation</h6>

              <div className="form-group row">
                <label className="col-4 col-form-label">Delay <small>(sec)</small></label>
                <div className="col-8">
                  <input type="number" className="form-control" min="0" max="20" step="1" />
                </div>
              </div>
              */}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
