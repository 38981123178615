import React, { useContext, useState, useEffect, useRef } from "react";
import $ from "jquery";
import Store from "../store";
import axios from "axios";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "components/CheckoutForm";
import UpdateCard from "components/UpdateCard";

export default function Onboarding() {
  const { state, dispatch } = useContext(Store);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState({});
  const [newPlan, setNewPlan] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [changePlanError, setChangePlanError] = useState("");

  useEffect(() => {
    getInvoices();
    const subscriptionStatus = async () => {
      await axios
        .get("/user/subscription/status")
        .then(response => {
          setStatus(response.data);
          setIsLoading(false);
          setNewPlan(response.data.stripe_plan);
        })
        .catch(error => {
          console.log(error);
        });
    };

    subscriptionStatus();
  }, []);

  const getInvoices = () => {
    axios
      .get("/user/info/invoices")
      .then(response => {
        setInvoices(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const planCancel = () => {
    setIsLoading(true);
    axios
      .post("/user/subscription/cancel")
      .then(response => {
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const planResume = () => {
    setIsLoading(true);
    axios
      .post("/user/subscription/resume")
      .then(response => {
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const planChange = () => {
    setIsLoading(true);
    axios
      .post("/user/subscription/update", { plan: newPlan })
      .then(response => {
        window.location.reload();
      })
      .catch(error => {
        setChangePlanError(error.data.message);
        setIsLoading(false);
      });
  };

  return (
    <section className="main-content">
      <div className="container">
        {isLoading && (
          <div className="spinner-wrapper" style={{ minHeight: "140px" }}>
            <div className="spinner-grow text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}

        {!isLoading && !status.subscribed && status.on_trial && (
          <div className="card card-body text-center">
            <p className="lead">
              You are currently enjoying the <strong>Pro trial</strong>.
            </p>
            <p className="mb-0">
              On <mark>{status.trial_ends_at}</mark> you'll be downgraded to the
              Free plan.
            </p>
            <hr />
            <div>
              <button
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#modal-upgrade"
              >
                Upgrade Now
              </button>
            </div>
          </div>
        )}

        {!isLoading && status.subscribed && !status.plan_canceled && (
          <div className="card card-body text-center">
            <p className="lead">
              You are currently on the{" "}
              <strong>
                {status.plan.period} {status.plan.name}
              </strong>{" "}
              plan.
            </p>
            <p className="mb-0">
              On <mark>{status.subscription_ends_at}</mark> you'll be charged $
              {status.plan.price} from your {status.card_brand} card ending{" "}
              {status.card_last_four}.
            </p>
            <hr />
            <div>
              <button className="btn btn-danger mx-3" onClick={planCancel}>
                Cancel Plan
              </button>
              <button
                className="btn btn-secondary mx-3"
                data-toggle="modal"
                data-target="#modal-change-plan"
              >
                Change Plan
              </button>
              <button
                className="btn btn-secondary mx-3"
                data-toggle="modal"
                data-target="#modal-update-card"
              >
                Change Card
              </button>
              <button
                className="btn btn-secondary mx-3"
                data-toggle="modal"
                data-target="#modal-invoices"
              >
                View Invoices
              </button>
            </div>
          </div>
        )}

        {!isLoading && status.subscribed && status.plan_on_grace && (
          <div className="card card-body text-center">
            <p className="lead">
              You are currently on the{" "}
              <strong>
                {status.plan.period} {status.plan.name}
              </strong>{" "}
              plan.
            </p>
            <p className="mb-0">
              You canceled your subscription and we won't charge you anymore.
              But you can stay on this plan until {status.subscription_ends_at}.
            </p>
            <hr />
            <div>
              <button className="btn btn-success mx-3" onClick={planResume}>
                Resume Plan
              </button>
              <button
                className="btn btn-secondary mx-3"
                data-toggle="modal"
                data-target="#modal-invoices"
              >
                View Invoices
              </button>
            </div>
          </div>
        )}

        {!isLoading && !status.subscribed && !status.on_trial && (
          <div className="card card-body text-center">
            <p className="lead">
              You are currently enjoying the <strong>Free</strong> plan.
            </p>
            <p className="mb-0">
              Your free 14-day trial on Pro plan has ended on{" "}
              {status.trial_ends_at}.
            </p>
            <hr />
            <div>
              <button
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#modal-upgrade"
              >
                Upgrade Your Plan
              </button>
            </div>
          </div>
        )}

        <h6 className="divider mt-8">Pricing Plans</h6>

        <div className="table-responsive">
          <table className="table table-hover table-pricing">
            <thead>
              <tr>
                <th>
                  <strong>Product Feature</strong>
                </th>
                <th>
                  <strong>Free</strong>
                </th>
                <th>
                  <strong>Lite</strong>
                </th>
                <th>
                  <strong>Standard</strong>
                </th>
                <th>
                  <strong>Pro</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Chatbots</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
              </tr>

              <tr>
                <td>Responses/month</td>
                <td>100</td>
                <td>500</td>
                <td>3,000</td>
                <td>10,000</td>
              </tr>

              <tr>
                <td>Question Types</td>
                <td>Basic</td>
                <td>All</td>
                <td>All</td>
                <td>All</td>
              </tr>

              <tr>
                <td>Email Notification</td>
                <td>Self</td>
                <td>Self & Respondent</td>
                <td>Self & Respondent</td>
                <td>Self & Respondent</td>
              </tr>

              <tr>
                <td>Logical Jump</td>
                <td className="text-danger">&times;</td>
                <td className="text-success">✓</td>
                <td className="text-success">✓</td>
                <td className="text-success">✓</td>
              </tr>

              <tr>
                <td>Launcher Buttons</td>
                <td className="text-danger">&times;</td>
                <td className="text-success">✓</td>
                <td className="text-success">✓</td>
                <td className="text-success">✓</td>
              </tr>

              <tr>
                <td>Webhook Integration</td>
                <td className="text-danger">&times;</td>
                <td className="text-success">✓</td>
                <td className="text-success">✓</td>
                <td className="text-success">✓</td>
              </tr>

              <tr>
                <td>Calculator (variables)</td>
                <td className="text-danger">&times;</td>
                <td className="text-danger">&times;</td>
                <td className="text-success">✓</td>
                <td className="text-success">✓</td>
              </tr>

              <tr>
                <td>Native Integrations</td>
                <td className="text-danger">&times;</td>
                <td className="text-danger">&times;</td>
                <td className="text-success">✓</td>
                <td className="text-success">✓</td>
              </tr>

              <tr>
                <td>Remove Formito branding</td>
                <td className="text-danger">&times;</td>
                <td className="text-danger">&times;</td>
                <td className="text-success">✓</td>
                <td className="text-success">✓</td>
              </tr>

              <tr>
                <td>Tracking Integrations</td>
                <td className="text-danger">&times;</td>
                <td className="text-danger">&times;</td>
                <td className="text-danger">&times;</td>
                <td className="text-success">✓</td>
              </tr>

              <tr>
                <td colSpan="5">
                  <strong>Price</strong>
                </td>
              </tr>

              <tr>
                <td>
                  Monthly Subscription <small>(per month)</small>
                </td>
                <td>$0</td>
                <td>$18</td>
                <td>$35</td>
                <td>$70</td>
              </tr>

              <tr>
                <td>
                  Yearly Subscription <small>(per month)</small>
                </td>
                <td>$0</td>
                <td>$15</td>
                <td>$30</td>
                <td>$60</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h6 className="divider mt-8">Frequently Asked Questions</h6>

        <div className="card card-body">
          <div className="row gap-y">
            <div className="col-md-6">
              <h6>Is this a secure site for purchases?</h6>
              <p>
                Absolutely! We work with top payment companies which guarantees
                your safety and security. All billing information is stored on
                Stripe which has the most stringent level of certification
                available in the payments industry.
              </p>
            </div>

            <div className="col-md-6">
              <h6>Can I cancel my subscription?</h6>
              <p>
                You can cancel your subscription anytime in your account. Once
                the subscription is cancelled, you will not be charged anymore.
                You will continue to have access to your account until your
                current subscription expires.
              </p>
            </div>

            <div className="col-md-6">
              <h6>Can I request refund?</h6>
              <p>
                Unfortunately, not. You can start using our service for free and
                for long time enough to decide about upgrading your plan. So you
                can make sure our service is valuable before making any payment.
              </p>
            </div>

            <div className="col-md-6">
              <h6>Are there discounts for yearly plans?</h6>
              <p>
                Yes! As you can see in the pricing table, if you pay for a year
                in advance you save about 20% compared to paying per month. That
                means you get 12 months usage for the price of 10 months.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-upgrade" tabIndex="-1">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <p className="lead">You're almost there!</p>
              <p>Pick your desire plan from the list and make payment.</p>
              <StripeProvider apiKey={state.app.apiKeys.stripe}>
                <div className="example">
                  <Elements>
                    <CheckoutForm />
                  </Elements>
                </div>
              </StripeProvider>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-update-card" tabIndex="-1">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <p className="lead">Enter your card details</p>
              <p>
                Once you submit this new card details, we'll make this card your
                default payment method.
              </p>
              <StripeProvider apiKey={state.app.apiKeys.stripe}>
                <div className="example">
                  <Elements>
                    <UpdateCard />
                  </Elements>
                </div>
              </StripeProvider>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-change-plan" tabIndex="-1">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <p className="lead">Change your plan</p>
              <p>
                If you upgrade, we'll reduce the charge amount based on the
                remaining days of your current plan.
                <br />
                If you downgrade, your remaining credit would be use toward
                paying your next invoice. We can't refund your remaining credit.
              </p>
              <hr />
              <p className="text-danger">{changePlanError}</p>
              <select
                className="custom-select"
                value={newPlan}
                onChange={e => {
                  setNewPlan(e.target.value);
                  setChangePlanError("");
                }}
              >
                <option value="lite-m1-v1">
                  Lite Plan (monthly) — $18 now and every month
                </option>
                <option value="standard-m1-v1">
                  Standard Plan (monthly) — $35 now and every month
                </option>
                <option value="pro-m1-v1">
                  Pro Plan (monthly) — $70 now and every month
                </option>
                <option value="lite-y1-v1">
                  Lite Plan (yearly) — $180 now and every year
                </option>
                <option value="standard-y1-v1">
                  Standard Plan (yearly) — $360 now and every year
                </option>
                <option value="pro-y1-v1">
                  Pro Plan (yearly) — $720 now and every year
                </option>
              </select>
              <br />
              <br />
              <button
                className="btn btn-block btn-info"
                onClick={planChange}
                disabled={isLoading}
              >
                {isLoading ? "One Second..." : "Change Plan"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-invoices" tabIndex="-1">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p className="lead">Your invoices</p>

              {invoices.map((invoice, index) => {
                return (
                  <div className="border rounded shadow-4 p-4 mb-4" key={index}>
                    <div className="row align-items-center">
                      <div className="col lh-5">
                        ${invoice.total > 0 ? invoice.total / 100 : 0} on{" "}
                        <em>{invoice.date}</em>
                      </div>
                      <div className="col-auto">
                        <div className="small">
                          <a href={invoice.url} target="_blank">
                            View Online
                          </a>{" "}
                          —{" "}
                          <a href={invoice.url + "/pdf"} target="_blank">
                            Download PDF
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
