import React, { useContext, useState } from "react";
import Store from "store";
import { NONAME } from "dns";

export default function Previewer({match}) {
  const { state, dispatch } = useContext(Store);

  return (
    <section className="main-content">
      <div>
        <iframe src={`http://localhost:3001/e/${match.params.slug}`} style={{border: 0, height: '600px', width: '380px', backgroundColor: '#fff'}} />
      </div>

    </section>
  )
}
