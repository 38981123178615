import React, { useContext, useState } from "react";
import Store from "store";
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';

export default function Recover({match}) {
  const { state, dispatch } = useContext(Store);
  const [ email, setEmail] = useState();
  const [ hasSent, setHasSent] = useState();
  const [ hasError, setHasError] = useState();

  if (state.user.auth) {
    return <Redirect to='/' />
  }

  const doRecover = async () => {
    setHasError(false);
    await axios.post('/user/password/email', {email})
    .then(response => {
      console.log(response.data);
      setHasSent(true);
    })
    .catch(error => {
      console.log(error);
      setHasError(true);
      //return(<Redirect to='/user/login' />);
    })
  }

  return (
    <main className="main-content centered-box bg-img-auth">
      <div className="card-auth">
        <h5 className="mb-6">Recover your password</h5>

        {hasSent &&
          <div className="alert alert-info">Please check your inbox. We sent you an email containing instructions to change your password.</div>
        }

        {hasError && <div className="alert alert-danger">We couldn't recognize this email address.</div>}

        {!hasSent &&
          <>
            <div className="form-group">
              <input type="email" className="form-control" name="email" placeholder="Email Address" onChange={ (e) => setEmail(e.target.value) } />
            </div>

            <button className="btn btn-block btn-primary" onClick={doRecover}>Reset Password</button>
          </>
        }

      </div>
    </main>
  )
}

