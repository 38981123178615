
export default function app(state={}, {type, payload}) {
  switch (type) {
    case "APP_READY":
      return {
        ...state,
        ready: true
      };

    case "APP_NOT_READY":
      return {
        ...state,
        ready: false
      };

    default:
      return state;
  }
}
