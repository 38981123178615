
export function next(name, value, block) {

  let nextBlock = Object.assign({}, JSON.parse(JSON.stringify(block)));

  if (name.indexOf('.') > 0) {
    const names = name.split('.');
    nextBlock[ names[0] ] = {
      ...nextBlock[ names[0] ],
      [ names[1] ]: value,
    }
  } else {
    nextBlock = {
      ...nextBlock,
      [name]: value,
    }
  }

  return nextBlock;
}


export function update(name, value, block, index) {
  let nextBlock = Object.assign({}, JSON.parse(JSON.stringify(block)));

  if (value.target !== undefined) {
    value = value.target.value;
  }

  if (name.indexOf('.') > 0) {
    const names = name.split('.');
    nextBlock[ names[0] ] = {
      ...nextBlock[ names[0] ],
      [ names[1] ]: value,
    }
  } else {
    nextBlock = {
      ...nextBlock,
      [name]: value,
    }
  }

  return {
    type: "BLOCK_UPDATE",
    payload: {
      index,
      item: nextBlock,
    },
  };
}
