
export default function user(state={}, {type, payload}) {
  switch (type) {
    case "USER_LOGOUT":
      return {
        ...state,
        auth: false,
        id: 0,
        name: '',
        token: '',
      };

    case "USER_SYNC":
      return {
        ...state,
        ...payload,
      };

    case "USER_UPDATE":
      return {
        ...state,
        ...payload
      };

    case "USER_RENAME":
      return {
        ...state,
        name: payload
      };

    case "USER_SETTINGS":
      return {
        ...state,
        settings: {
          ...state.settings,
          ...payload
        }
      };

    case "USER_UPDATE_TOKEN":
      return {
        ...state,
        token: payload,
        auth: true,
      };

    case "USER_SET_PREFERENCE":
      return {
        ...state,
        preferences: {
          ...state.preference,
          ...payload,
        }
      };

    default:
      return state;
  }
}
