import React, { useContext, useState } from "react";
import Store from "store";

export default function Sharer({match}) {
  const { state, dispatch } = useContext(Store);

  //const script = `<script async>!function(e,t,n,s,u,a){e.formito_form='${state.form.slug}';u=t.createElement(n),u.async=!0,u.src='//script.formito.com/v1/formito.js',a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a)}(window,document,'script');</script>`;
  const script = `<script async>!function(w,d,s,t,a){w.formito_form='${state.form.slug}';t=d.createElement(s),t.async=!0,t.src='//script.formito.com/v1/formito.js',a=d.getElementsByTagName(s)[0],a.parentNode.insertBefore(t,a)}(window,document,'script');</script>`;
  const doUrl = 'https://do.formito.com/e/'+ state.form.slug;

  return (
    <section className="main-content">

      <div className="container">
        <br />
        <div className="accordion accordion-arrow-right" id="acc-share">


          <div className="card">
            <h6 className="card-title">
              <a data-toggle="collapse" href="#tab-widget">Display launcher in your website</a>
            </h6>

            <div id="tab-widget" className="collapse show" data-parent="#acc-share">
              <div className="card-body">
                <p>Launcher is a rounded icon that places on bottom-right corner of your website and opens the chatbot if your visitor clicks on the icon or the welcome message. To display launcher on your website, copy the below code snippet and paste it into your HTML code.</p>
                <pre>{script}</pre>
                <hr />
                <h6>Custom Trigger</h6>
                <p>Once the script added to your website, you can trigger your chatbot from a link or button. It can be done by having <code>href="#formito-{state.form.slug}"</code> on your anchor tags or <code>data-formito="{state.form.slug}"</code> attribute on any element.</p>
                <p>If you'd like to open the chatbot in a popup instead of from launcher, simply add <code>data-popup="true"</code> attribute to your trigger.</p>
              </div>
            </div>
          </div>


          <div className="card">
            <h6 className="card-title">
              <a className="collapsed" data-toggle="collapse" href="#tab-share">Landing page</a>
            </h6>

            <div id="tab-share" className="collapse" data-parent="#acc-share">
              <div className="card-body">
                <p>Here is the unique link to your chatbot. Simply share this url with your followers on social networks, email your prospects, or link to it from your website.</p>
                <a href={doUrl} target="_blank">{doUrl}</a>
              </div>
            </div>
          </div>



          <div className="card">
            <h6 className="card-title">
              <a className="collapsed" data-toggle="collapse" href="#tab-embed">Embed in a web page</a>
            </h6>

            <div id="tab-embed" className="collapse" data-parent="#acc-share">
              <div className="card-body">
                <p>Copy the below code and place it inside your website's HTML source code where you need to display your chatbot.</p>
                <pre>&lt;iframe src="{doUrl}" width="100%" height="640" frameBorder="0">&lt;/iframe></pre>
              </div>
            </div>
          </div>
        </div>

        <div className="alert alert-info mt-5">Your chatbot ID is: <strong>{state.form.slug}</strong></div>

      </div>




      <div className="row d-none">
        <div className="col-md-8 mx-auto">

          <ul className="nav nav-tabs mt-6" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#tab-link">Hosted</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#tab-widget">Widget</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#tab-embed">Embed</a>
            </li>
          </ul>

          <div className="tab-content pt-6">
            <div className="tab-pane fade show active" id="tab-link">
              <p>Here is the unique link to your Formito. Simply share this url with your followers on social networks, email your prospects, or link to it from your website.</p>
              <pre>http://localhost:3000/form/slug1/share</pre>
            </div>

            <div className="tab-pane fade" id="tab-widget">
              <p>Copy the below code snippet and paste it into your HTML code.</p>
              <pre>{script}</pre>
            </div>

            <div className="tab-pane fade" id="tab-embed">
              <p>Copy the below code and place it inside your website's HTML source code where you need to display your formito.</p>
              <pre>&lt;iframe src="http://localhost:3000/form/slug1/share" width="100%" height="640" frameBorder="0">&lt;/iframe></pre>
            </div>
          </div>

        </div>
      </div>

    </section>
  )
}
