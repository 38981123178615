import React, { useContext, useState, useEffect, useCallback } from "react";
import Store from "store";
import axios from "axios";
import moment from "moment";

export default function ResponsePrint({ match }) {
  const { state, dispatch } = useContext(Store);
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState({});

  useEffect(() => {
    axios
      .get(`/response/show/${match.params.id}`)
      .then(response => {
        setResponse(response.data);
        setIsLoading(false);

        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.innerHTML =
          "document.title = 'Response " +
          match.params.id +
          "'; setTimeout(function(){window.print()}, 1000);";
        document.head.appendChild(s);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="spinner-wrapper">
        <div className="spinner-grow text-info" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const pairs = Object.entries(response.data.pairs).map(([key, val], index) => (
    <div className="key-val" key={index}>
      <h6>{key}</h6>
      {Array.isArray(val) ? (
        <ul>
          {val.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: val }} />
      )}
    </div>
  ));

  return (
    <div className="print-area key-val-wrapper">
      <div className="row">
        <div className="col-md-6">
          <span className="caption">Entry ID: </span> {response.id}
        </div>

        <div className="col-md-6">
          <span className="caption">Date: </span>{" "}
          {moment
            .utc(response.created_at)
            .local()
            .format("MMMM Do YYYY, HH:mm")}
        </div>
      </div>

      <h6 className="divider mb-3">Details</h6>
      {pairs}
    </div>
  );
}
