import React, { useContext, useState, useEffect, useCallback } from "react";
import $ from "jquery";
import Store from "store";
import axios from "axios";
import moment from "moment";
import daterangepicker from "daterangepicker";
import Respondent from "form/editor/Respondent";
import TheResponse from "form/blocks/TheResponse";
import * as Utils from "utils";
import MaterialTable from "material-table";

export default function Responses({ match }) {
  const { state, dispatch } = useContext(Store);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTabel, setIsLoadingTable] = useState(false);
  const [vars, setVars] = useState({});
  const [columns, setColumns] = useState([]);
  const [selectedRow, setSelectedRow] = useState({
    id: 0,
    data: { pairs: {} }
  });

  let pref_rangepicker = {
    start: moment().subtract(6, "days"),
    end: moment(),
    label: "Last 7 Days"
  };

  if (state.user.preferences && state.user.preferences.response_range) {
    pref_rangepicker = Object.assign(
      {},
      pref_rangepicker,
      state.user.preferences.response_range
    );
  }

  useEffect(() => {
    let cols_exist = [];
    let cols = [
      { title: "#", field: "id", hidden: true },
      { title: "", field: "completed", hidden: true },
      {
        title: "Date",
        field: "created_at",
        customSort: (a, b) => a.id - b.id,
        render: rowData => (
          <>
            {rowData.completed ? (
              <span
                className="badge badge-dot badge-success mr-2"
                title="Completed"
              ></span>
            ) : (
              <span className="badge badge-dot bg-transparent mr-2"></span>
            )}
            <span>{rowData.created_at}</span>
          </>
        )
      }
    ];
    state.form.blocks.map(block => {
      if (
        (block.align === "right" || block.type === "variable") &&
        block.name
      ) {
        if (cols_exist.indexOf(block.name) > -1) {
          return;
        }
        cols_exist.push(block.name);
        cols.push({
          title: block.name,
          field: block.name,
          render: rowData => {
            return (
              <div className="key-val">
                {Array.isArray(rowData[block.name]) ? (
                  <ul>
                    {rowData[block.name].map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                ) : (
                  <div
                    className="ignore-br text-truncate"
                    style={{ maxWidth: "250px" }}
                    dangerouslySetInnerHTML={{ __html: rowData[block.name] }}
                  />
                )}
              </div>
            );
          }
        });
      }
    });
    setColumns(cols);
  }, []);

  const rangepickerInput = useCallback(node => {
    if (node !== null) {
      let rangepicker = $(node); // $('[data-provide="daterangepicker"]:first');
      let ranges = {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment()
            .subtract(1, "month")
            .startOf("month"),
          moment()
            .subtract(1, "month")
            .endOf("month")
        ]
      };
      let start_date = moment(pref_rangepicker.start);
      let end_date = moment(pref_rangepicker.end);

      if (pref_rangepicker.label in ranges) {
        let dates = ranges[pref_rangepicker.label];
        start_date = dates[0];
        end_date = dates[1];

        dispatch({
          type: "RESPONSE_SET_RANGE",
          payload: {
            start: start_date.format("YYYY-MM-DD"),
            end: end_date.format("YYYY-MM-DD")
          }
        });

        dispatch({
          type: "USER_SET_PREFERENCE",
          payload: {
            response_range: {
              start: start_date.format("YYYY-MM-DD"),
              end: end_date.format("YYYY-MM-DD"),
              label: pref_rangepicker.label
            }
          }
        });
      }

      rangepicker.daterangepicker(
        {
          ranges: ranges,
          alwaysShowCalendars: true,
          startDate: start_date,
          endDate: end_date
        },
        function(start, end, label) {
          setIsLoadingTable(true);
          rangepicker.text(label);
          dispatch({ type: "FORM_SYNC_RESPONSES", payload: [] });
          dispatch({ type: "RESPONSE_ACTIVATE", payload: -99 });

          dispatch({
            type: "USER_SET_PREFERENCE",
            payload: {
              response_range: {
                start: start.format("YYYY-MM-DD"),
                end: end.format("YYYY-MM-DD"),
                label: label
              }
            }
          });

          dispatch({
            type: "RESPONSE_SET_RANGE",
            payload: {
              start: start.format("YYYY-MM-DD"),
              end: end.format("YYYY-MM-DD")
            }
          });

          //console.log('New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')');
        }
      );
    }
  }, []);

  useEffect(() => {
    let data = {
      dateStart: state.form.rangeStart,
      dateEnd: state.form.rangeEnd
    };

    if (state.user.preferences && state.user.preferences.response_range) {
      data = {
        dateStart: state.user.preferences.response_range.start,
        dateEnd: state.user.preferences.response_range.end
      };
    }
    axios
      .get(`/response/index/${match.params.slug}`, { params: data })
      .then(response => {
        dispatch({ type: "FORM_SYNC_RESPONSES", payload: response.data });
        dispatch({ type: "RESPONSE_ACTIVATE", payload: 0 });
        setIsLoading(false);
        setIsLoadingTable(false);
      })
      .catch(error => {
        console.log(error);
      });
  }, [state.form.rangeStart, state.form.rangeEnd]);

  useEffect(() => {
    if (
      state.form.activeResponse !== undefined &&
      state.form.responses[state.form.activeResponse] !== undefined
    ) {
      setVars(state.form.responses[state.form.activeResponse].data.pairs);
    } else {
      setVars({});
    }
  }, [state.form.activeResponse]);

  const destroy = id => {
    axios
      .post(`/response/destroy/${id}`)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
    dispatch({ type: "FORM_RESPONSES_DELETE_BY_ID", payload: id });
  };

  if (isLoading) {
    return (
      <div className="spinner-wrapper">
        <div className="spinner-grow text-info" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <section className="main-content">
      <div className="mb-4 text-right">
        <button
          className="btn btn-secondary px-2"
          data-provide="daterangepicker"
          ref={rangepickerInput}
        >
          {pref_rangepicker.label}
        </button>
      </div>

      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          title={state.form.name}
          columns={columns}
          isLoading={isLoadingTabel}
          data={state.form.responses.map(response => {
            return {
              ...response.data.pairs,
              id: response.id,
              completed: response.completed,
              created_at: moment
                .utc(response.created_at)
                .local()
                .fromNow()
            };
          })}
          onRowClick={(evt, selectedRow) => {
            let id = selectedRow.id;
            let response = state.form.responses.filter(
              (element, index) => element.id === id
            );
            setSelectedRow(response[0]);
            $("#modal-response").modal("show");
          }}
          actions={[
            rowData => ({
              icon: "print",
              tooltip: "Print",
              onClick: (event, rowData) => {
                const w = window.open(`responses/${rowData.id}/print`);
                //w.print();
                //w.close();
              }
            }),
            rowData => ({
              icon: "delete",
              tooltip: "Delete",
              onClick: (event, rowData) => destroy(rowData.id)
            })
          ]}
          options={{
            pageSize: 10,
            actionsColumnIndex: -1,
            exportButton: true
          }}
        />
      </div>

      <div className="modal fade" id="modal-response" tabIndex="-1">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLabel">
                Response #{selectedRow.id}
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body key-val-wrapper">
              <div className="row">
                <div className="col-md-6">
                  <span className="caption mr-2">Completed: </span>
                  {selectedRow.completed ? (
                    <span>
                      <span
                        className="badge badge-dot badge-success mr-1"
                        title="Completed"
                      ></span>{" "}
                      Yes
                    </span>
                  ) : (
                    <span>No</span>
                  )}
                </div>
                <div className="col-md-6">
                  <span className="caption">Date: </span>{" "}
                  {moment
                    .utc(selectedRow.created_at)
                    .local()
                    .format("MMMM Do, HH:mm")}
                </div>
                {selectedRow.url && (
                  <div className="col-12 mt-3 text-truncate">
                    <span className="caption">Filled from: </span>{" "}
                    <a href={selectedRow.url} target="_blank">
                      {selectedRow.url}
                    </a>
                  </div>
                )}
              </div>

              <h6 className="divider mb-3">User Provided Values</h6>

              {Object.entries(selectedRow.data.pairs).map(
                ([key, val], index) => (
                  <div className="key-val" key={index}>
                    <h6>{key}</h6>
                    {Array.isArray(val) ? (
                      <ul>
                        {val.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    ) : (
                      <div dangerouslySetInnerHTML={{ __html: val }} />
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
