import React, { useContext, useState, useEffect } from "react";
import Store from "store";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect
} from "react-router-dom";
import axios from "axios";
import PlaceholderAvatar from "assets/img/avatar.png";

export default function Settings() {
  const { state, dispatch } = useContext(Store);
  const [icon, setIcon] = useState();
  const [btnName, setBtnName] = useState("Save Changes");

  const [btnApiName, setBtnApiName] = useState("Generate New API Key");
  const [apiKey, setApiKey] = useState("");
  const [btnPassName, setBtnPassName] = useState("Change Password");
  const [errPass, setErrPass] = useState();
  const [objPass, setObjPass] = useState({});

  useEffect(() => {
    axios
      .get(`/user/info/api_key`, {})
      .then(response => {
        setApiKey(response.data.api_key);
      })
      .catch(error => {});
  }, []);

  const saveProfile = () => {
    setBtnName("Saving...");
    axios
      .post(`/user/update`, { name: state.user.name })
      .then(response => {
        setBtnName("Save Changes");
      })
      .catch(error => {});
  };

  const generateNewApiKey = () => {
    setBtnApiName("Generating...");
    axios
      .post(`/user/info/api_key`, {})
      .then(response => {
        setBtnApiName("Generate New API Key");
        setApiKey(response.data.api_key);
      })
      .catch(error => {});
  };

  const saveObjPass = (e, key) => {
    setObjPass({
      ...objPass,
      [key]: e.target.value
    });
  };

  const saveNewPassword = () => {
    setErrPass("");
    setBtnPassName("Saving...");
    axios
      .post(`/user/change_pass`, objPass)
      .then(response => {
        setBtnPassName("Change Password");
      })
      .catch(error => {
        console.log(error);
        setBtnPassName("Change Password");
        setErrPass(error.data.message);
      });
  };

  const saveLauncher = () => {
    setBtnName("Saving...");
    const data = new FormData();
    data.append("icon", icon);
    data.append("default_form_id", state.site.default_form_id);

    axios
      .post(`/site/update/${state.site.id}`, data)
      .then(response => {
        setBtnName("Save Changes");
      })
      .catch(error => {});
  };

  const deleteUser = () => {
    axios
      .delete(`/user/destroy`, {})
      .then(response => {
        dispatch({ type: "USER_LOGOUT" });
        window.location.href = "https://formito.com";
      })
      .catch(error => {});
  };

  return (
    <section className="main-content">
      <div className="container">
        <br />
        <div className="accordion accordion-arrow-right" id="acc-settings">
          <div className="card">
            <h6 className="card-title">
              <a data-toggle="collapse" href="#tab-profile">
                Profile
              </a>
            </h6>

            <div
              id="tab-profile"
              className="collapse show"
              data-parent="#acc-settings"
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-3 col-form-label">Email Address</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={state.user.email}
                      readOnly
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Name</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={state.user.name}
                      onChange={e =>
                        dispatch({
                          type: "USER_UPDATE",
                          payload: { name: e.target.value }
                        })
                      }
                    />
                  </div>
                </div>

                <hr />

                <div className="text-right">
                  <button className="btn btn-secondary" onClick={saveProfile}>
                    {btnName}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <h6 className="card-title">
              <a data-toggle="collapse" href="#tab-apiKey">
                API Key
              </a>
            </h6>

            <div
              id="tab-apiKey"
              className="collapse"
              data-parent="#acc-settings"
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-3 col-form-label">Your API Key</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={apiKey}
                      readOnly
                    />
                  </div>
                </div>

                <hr />

                <div className="text-right">
                  <button
                    className="btn btn-secondary"
                    onClick={generateNewApiKey}
                  >
                    {btnApiName}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <h6 className="card-title">
              <a data-toggle="collapse" href="#tab-pass">
                Change Password
              </a>
            </h6>

            <div id="tab-pass" className="collapse" data-parent="#acc-settings">
              <div className="card-body">
                {!!errPass && (
                  <div className="alert alert-danger mb-6">{errPass}</div>
                )}

                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    Current Password
                  </label>
                  <div className="col-9">
                    <input
                      type="password"
                      className="form-control form-control-sm"
                      onChange={e => saveObjPass(e, "current")}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">New Password</label>
                  <div className="col-9">
                    <input
                      type="password"
                      className="form-control form-control-sm"
                      onChange={e => saveObjPass(e, "password")}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    Confirm New Password
                  </label>
                  <div className="col-9">
                    <input
                      type="password"
                      className="form-control form-control-sm"
                      onChange={e => saveObjPass(e, "password_confirmation")}
                    />
                  </div>
                </div>

                <hr />

                <div className="text-right">
                  <button
                    className="btn btn-secondary"
                    onClick={saveNewPassword}
                  >
                    {btnPassName}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <h6 className="card-title">
              <a data-toggle="collapse" href="#tab-danger-zone">
                Danger Zone
              </a>
            </h6>

            <div
              id="tab-danger-zone"
              className="collapse"
              data-parent="#acc-settings"
            >
              <div className="card-body">
                <p>
                  Deleting your account would remove all responses, all
                  chatbots, all uploaded files, all your user settings and any
                  record we have for this email address. Please notice that this
                  action is not reversible.
                </p>
                <button
                  className="btn btn-danger"
                  href="#modal-delete"
                  data-toggle="modal"
                >
                  Delete My Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-delete" tabIndex="-1">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <p className="mb-0">
                Are you sure you want to delete your account and all the data
                related to this account?
              </p>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                No, cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={deleteUser}
              >
                Yes, DELETE
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  /*
  return (
    <section className="main-content">
      <div className="paged">
        <nav className="nav nav-paged">
          <NavLink className="nav-link" to="/settings/profile">Profile</NavLink>
          <NavLink className="nav-link" to="/settings/badge">Badge</NavLink>
        </nav>

        <Switch>
          <Route exact path="/settings/badge" component={Badge} />
          <Route component={Profile} />
        </Switch>
      </div>
    </section>
  )
  */
}
