
export default function responses(state=[], {type, payload}) {
  switch (type) {

    /*
    case 'RESPONSE_ACTIVATE':
      return {
        ...state,
        activeResponse: payload
      };

    case 'FORM_SYNC_RESPONSES':
      return {
        ...state,
        activeResponse: 0,
        responses: payload
      };

    case 'FORM_RESPONSES_DELETE':
      return {
        ...state,
        responses: state.responses.filter((element, index) => index !== payload),
      };
*/
    default:
      return state;
  }
}

