import React, { useContext, useState, useEffect, useRef } from "react";
import $ from 'jquery';
import Store from "../store";
import axios from 'axios';
import { Link } from "react-router-dom";
import { IoMdMore, IoIosAddCircleOutline } from "react-icons/io";

export default function Home() {
  const { state, dispatch } = useContext(Store);
  const [ badge, setBadge ] = useState();
  const [ activeForm, setActiveForm ] = useState({});
  const [ templates, setTemplates ] = useState([]);


  const fetchData = () => {
    axios.get('/form/index')
    .then(response => {
      dispatch({ type: "FORMS_SYNC", payload: response.data });
    })
    .catch(error => {
      console.log(error);
    })
  }


  const fetchTemplates = () => {
    axios.get('/form/templates')
    .then(response => {
      setTemplates(response.data);
    })
    .catch(error => {
      console.log(error);
    })
  }

  useEffect( () => {
    //fetchData();
  }, []);

  const save = () => {
    const data = new FormData();
    data.append('img', badge);

    axios.post('/badge', data)
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error);
    })
  }



  const formSetupCreate = () => {
    fetchTemplates();
    setActiveForm({
      name: 'New Formito',
      starter: 'template-blank',
    })
  }

  const formCreate = () => {
    axios.post('/form/store', activeForm)
    .then(response => {
      setActiveForm({});
      dispatch({ type: "FORMS_APPEND", payload: response.data });
      fetchData();
    })
    .catch(error => {
      console.log(error);
    })
  }


  const formDuplicate = (form) => {
    axios.post('/form/duplicate/'+ form.slug, {name: form.name + ' (Copy)'})
    .then(response => {
      dispatch({ type: "FORMS_APPEND", payload: response.data });
    })
    .catch(error => {
      console.log(error);
    })
    return false;
  }


  const formRename = () => {
    axios.post('/form/rename/'+ activeForm.slug, {name: activeForm.name})
    .then(response => {
      //console.log(response);
    })
    .catch(error => {
      console.log(error);
    })
    dispatch({ type: "FORMS_RENAME", payload: {slug: activeForm.slug, name: activeForm.name } });
  }


  const formDelete = () => {
    axios.post('/form/destroy/'+ activeForm.slug)
    .then(response => {
      //console.log(response);
    })
    .catch(error => {
      console.log(error);
    })
    dispatch({ type: "FORMS_DELETE", payload: activeForm.slug });
  }


  const setNewResponsesToZero = (index) => {
    dispatch({ type: "FORM_RESPONSE_NEW_ZERO", payload: index });
  }


  return (
    <section className="main-content">
      <div className="row">

        {state.forms.map( (form, index) => (
          <div className="col-md-6 col-lg-4 col-xl-3" key={index}>
            <div className="card card-forms">
              <div className="card-body">
                <div className="flexbox mb-5">
                  <h6 className="card-title">{form.name}</h6>
                  <div className="dropdown">
                    <a className="dropdown-toggle no-caret" href="#" data-toggle="dropdown">
                      <IoMdMore />
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a className="dropdown-item" href={`${state.app.url.do}/e/${form.slug}`} target="_blank">View</a>
                      <Link className="dropdown-item" to={`/form/${form.slug}/settings`}>Settings</Link>
                      <Link className="dropdown-item" to={`/form/${form.slug}/share`}>Share</Link>
                      <Link className="dropdown-item" to={`/form/${form.slug}/connect`}>Connect</Link>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="#modal-rename" data-toggle="modal" onClick={() => setActiveForm(form)}>Rename</a>
                      <a className="dropdown-item" href="#" onClick={() => formDuplicate(form)}>Duplicate</a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item text-danger" href="#modal-delete" data-toggle="modal" onClick={() => setActiveForm(form)}>Delete</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-btns">
                <Link className="btn btn-edit" to={`/form/${form.slug}/editor`}>Edit</Link>
                <Link className="btn btn-responses" to={`/form/${form.slug}/responses`} onClick={() => setNewResponsesToZero(index)}>
                  Res
                  <small className="pl-1">({form.responses_count || 0})</small>
                  {form.new_responses_count > 0 &&
                    <span className="badge badge-xs badge-pill badge-danger">{form.new_responses_count} new</span>
                  }
                </Link>
              </div>

            </div>
          </div>
        ))}

        <div className="col-md-6 col-lg-4 col-xl-3">
          <div className="card card-forms-new" data-toggle="modal" data-target="#modal-create" onClick={formSetupCreate}>
            <IoIosAddCircleOutline />
            <div>New Chatbot</div>
          </div>
        </div>

      </div>






      <div className="modal fade" id="modal-create" tabIndex="-1">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Create New Chatbot</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="form-group row">
                <label className="col-3 col-lg-2 col-form-label">Name</label>
                <div className="col-9 col-lg-6">
                  <input type="text" className="form-control" value={activeForm.name} onChange={(e) => setActiveForm({...activeForm, name: e.target.value})} />
                </div>
              </div>

              <hr/>

              <h6 className="mb-5">Starter Template</h6>

              <div className="row starter-picker">
                {templates.length === 0 &&
                  <div className="spinner-wrapper" style={{minHeight: '140px'}}>
                    <div className="spinner-grow text-info" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                }


                {templates.map((item, index) => {
                  if (item.slug.indexOf('template-') === -1) {
                    return null;
                  }
                  return (
                  <div className="col-4 col-lg-3 col-xl-21" key={index}>
                    <div className={`starter-item` + (activeForm.starter === item.slug ? ' active' : '')}>
                      <span onClick={(e) => setActiveForm({...activeForm, starter: item.slug})}>{item.name}</span>
                      {item.name !== 'blank' &&
                        <a href={"https://do.formito.com/e/" + item.slug} target="_blank">Preview</a>
                      }
                    </div>
                  </div>
                )})}

              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={formCreate}>Create</button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="modal-rename" tabIndex="-1">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <p className="text-dark mb-3">New Name</p>
              <input type="text" className="form-control" value={activeForm.name} onChange={(e) => setActiveForm({...activeForm, name: e.target.value})} />
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={formRename}>Rename</button>
            </div>
          </div>
        </div>
      </div>



      <div className="modal fade" id="modal-delete" tabIndex="-1">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <p className="lead">Are you sure you want to delete <strong>{activeForm.name}</strong>?</p>
              <p className="mb-0">Once you delete a form, all the responses and settings would be removed and you won't be able to access to them anymore.</p>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={formDelete}>Delete</button>
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}
