import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  Fragment
} from "react";
import Store from "store";
import { blocksList } from "form/blocks";
import smoothscroll from "smoothscroll-polyfill";
import { next } from "utils/block";
import shortid from "shortid";

smoothscroll.polyfill();

export default function Inserter(props) {
  const { state, dispatch } = useContext(Store);

  const labels = state.form.blocks.filter(
    (element, index) => element.type == "label"
  );

  const variables = state.form.blocks.filter(
    (element, index) => element.type == "variable"
  );

  function addBlock(widget, name = null) {
    let nextBlock = JSON.parse(JSON.stringify(widget));
    nextBlock = {
      id: "col" + shortid.generate(),
      ...nextBlock
    };

    const nameables = ["input", "select"];

    if (nextBlock.name !== undefined) {
      if (name === null) {
        name = nextBlock.name;
      }

      let exist =
        state.form.blocks.filter((element, index) => element.name === name) ||
        [];
      let i = 0;
      while (exist.length > 0) {
        i++;
        name = nextBlock.name + "_" + i;
        exist =
          state.form.blocks.filter((element, index) => element.name === name) ||
          [];
      }

      nextBlock = {
        ...nextBlock,
        name
      };
    }

    dispatch({ type: "BLOCKS_ADD", payload: nextBlock });
    dispatch({ type: "INSPECTOR_OPEN", payload: state.form.blocks.length });

    window.scroll({
      top: document.body.scrollHeight,
      left: 0,
      behavior: "smooth"
    });
  }

  return (
    <ul
      className={
        `inserter inserter-${props.position}` + (props.sticky ? " sticky" : "")
      }
    >
      {blocksList[props.position].map((block, index) => {
        if (block.type === "assign" && variables.length === 0) {
          return null;
        }

        if (block.type === "jump" && labels.length === 0) {
          return null;
        }

        return (
          <Fragment key={index}>
            {block.type === "label" && <li className="inserter-divider"></li>}
            {block.type === "variable" && (
              <li className="inserter-divider"></li>
            )}

            {block.multiple ? (
              <li className={"inserter-item dropdown insert-" + block.type}>
                <div className="icon">{block.icon}</div>
                <div className="dropdown-toggle text" data-toggle="dropdown">
                  {block.title}
                </div>
                <div className="dropdown-menu dropdown-menu-right">
                  {Object.keys(block.default).map((key, index) => {
                    if (key === "divider") {
                      return (
                        <div className="dropdown-divider" key={index}></div>
                      );
                    } else {
                      return (
                        <div
                          className="dropdown-item"
                          key={index}
                          onClick={() => addBlock(block.default[key], key)}
                        >
                          {key.replace(/_/g, " ")}
                        </div>
                      );
                    }
                  })}
                </div>
              </li>
            ) : (
              <li
                className={"inserter-item insert-" + block.type}
                onClick={() => addBlock(block.default)}
              >
                <div className="icon">{block.icon}</div>
                <div className="text">{block.title}</div>
              </li>
            )}
          </Fragment>
        );
      })}
    </ul>
  );
}
