
export default function site(state={}, {type, payload}) {
  switch (type) {

    case "SITE_SYNC":
      return payload;

    case "SITE_ACTIVE_FORM":
      return {
        ...state,
        default_form_id: payload,
      };

    default:
      return state;
  }
}
