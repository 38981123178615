import arrayMove from "array-move";
import deepmerge from "deepmerge";

export default function form(state = {}, { type, payload }) {
  switch (type) {
    // Form
    //
    case "FORM_SYNC":
      let def_settings = {
        popup: {},
        landing: {},
        launcher: {
          buttons: [],
          buttonsAlwaysVisible: false,
          devices: {
            mobile: true,
            tablet: true,
            desktop: true
          }
        },
        notification: {
          email_each_response: false,
          email_me: {},
          email_respondent: {}
        },
        connect: {
          mailchimp: {},
          zendesk: {},
          google_sheets: {},
          google_calendar: {},
          ga: {},
          facebook: {},
          twitter: {},
          webhook: {},
          zapier: { hooks: [] }
        },
        design: {
          progressbar: false
        }
      };

      let settings = deepmerge(def_settings, payload.settings);

      return {
        id: payload.id,
        slug: payload.slug,
        name: payload.name,
        blocks: payload.data.blocks,
        meta: payload.data.meta || {},
        cover: payload.data.blocks[0] || {},
        popup: settings.popup || {},
        settings: settings || {}
      };

    case "FORM_CLEAR":
      return {};

    case "FORM_UPDATE_COVER":
      return {
        ...state,
        cover: payload
      };

    // Blocks
    //
    case "BLOCKS_ADD":
      return {
        ...state,
        blocks: [...state.blocks, payload]
      };

    case "BLOCKS_DELETE":
      return {
        ...state,
        blocks: state.blocks.filter((element, index) => index !== payload)
      };

    case "BLOCKS_DUPLICATE":
      let clone = state.blocks.slice(0);
      clone.splice(payload, 0, clone[payload]);
      return {
        ...state,
        blocks: clone.slice(0)
      };

    case "BLOCKS_REORDER":
      return {
        ...state,
        blocks: arrayMove(state.blocks, payload.oldIndex, payload.newIndex)
      };

    case "BLOCKS_CLEAR":
      return {
        ...state,
        blocks: []
      };

    // Block
    case "BLOCK_UPDATE":
      return {
        ...state,
        blocks: state.blocks.map((item, index) => {
          if (index === payload.index) {
            return {
              ...item,
              ...payload.item
            };
          }
          return item;
        })
      };

    case "BLOCK_REPEATER_REORDER":
      return {
        ...state,
        blocks: state.blocks.map((item, index) => {
          if (index === payload.index) {
            return {
              ...item,
              props: {
                ...item.props,
                items: arrayMove(
                  item.props.items,
                  payload.oldIndex,
                  payload.newIndex
                )
              }
            };
          }
          return item;
        })
      };

    // Popup
    //
    case "FORM_POPUP_SET":
      return {
        ...state,
        popup: {
          ...state.popup,
          ...payload,
          updated_at: Date.now()
        }
      };

    case "FORM_LANDING_SET":
      return {
        ...state,
        settings: {
          ...state.settings,
          landing: {
            ...state.settings.landing,
            ...payload
          }
        }
      };

    case "FORM_LAUNCHER_SET":
      return {
        ...state,
        settings: {
          ...state.settings,
          launcher: {
            ...state.settings.launcher,
            ...payload
          }
        }
      };

    case "FORM_BUTTONS_SET":
      return {
        ...state,
        settings: {
          ...state.settings,
          launcher: {
            ...state.settings.launcher,
            buttons: payload
          }
        }
      };

    case "FORM_LAUNCHER_DEVICES_SET":
      return {
        ...state,
        settings: {
          ...state.settings,
          launcher: {
            ...state.settings.launcher,
            devices: {
              ...state.settings.launcher.devices,
              ...payload
            }
          }
        }
      };

    case "FORM_ICON_SET":
      return {
        ...state,
        settings: {
          ...state.settings,
          icon: payload
        }
      };

    case "FORM_DESIGN_SET":
      return {
        ...state,
        settings: {
          ...state.settings,
          design: {
            ...state.settings.design,
            ...payload
          }
        }
      };

    // -- Deprecated
    case "FORM_LAUNCHER_COLOR":
      return {
        ...state,
        settings: {
          ...state.settings,
          design: {
            ...state.settings.design,
            primary_color: payload
          }
        }
      };

    case "FORM_SETTINGS_NOTIFY_EMAIL":
      return {
        ...state,
        settings: {
          ...state.settings,
          notification: {
            ...state.settings.notification,
            email_each_response: payload
          }
        }
      };

    case "FORM_SETTINGS_NOTIFY_EMAIL_INCOMPLETE":
      return {
        ...state,
        settings: {
          ...state.settings,
          notification: {
            ...state.settings.notification,
            email_incomplete_response: payload
          }
        }
      };

    case "FORM_SETTINGS_EMAIL_ME":
      return {
        ...state,
        settings: {
          ...state.settings,
          notification: {
            ...state.settings.notification,
            email_me: {
              ...state.settings.notification.email_me,
              ...payload
            }
          }
        }
      };

    case "FORM_SETTINGS_EMAIL_RESPONDENT":
      return {
        ...state,
        settings: {
          ...state.settings,
          notification: {
            ...state.settings.notification,
            email_respondent: {
              ...state.settings.notification.email_respondent,
              ...payload
            }
          }
        }
      };

    // Connect
    //
    case "FORM_CONNECT_MAILCHIMP_API":
      return {
        ...state,
        settings: {
          ...state.settings,
          connect: {
            ...state.settings.connect,
            mailchimp: {
              ...state.settings.connect.mailchimp,
              api_key: payload
            }
          }
        }
      };

    case "FORM_CONNECT_MAILCHIMP_AUDIENCE":
      return {
        ...state,
        settings: {
          ...state.settings,
          connect: {
            ...state.settings.connect,
            mailchimp: {
              ...state.settings.connect.mailchimp,
              audience_id: payload
            }
          }
        }
      };

    case "FORM_CONNECT_ZAPIER_REMOVE":
      const nextHooks = {
        ...state.settings.connect.zapier.hooks
      };
      delete nextHooks[payload];

      return {
        ...state,
        settings: {
          ...state.settings,
          connect: {
            ...state.settings.connect,
            zapier: {
              ...state.settings.connect.zapier,
              hooks: nextHooks
            }
          }
        }
      };

    case "FORM_CONNECT_GOOGLE_SHEETS":
      return {
        ...state,
        settings: {
          ...state.settings,
          connect: {
            ...state.settings.connect,
            google_sheets: {
              ...state.settings.connect.google_sheets,
              ...payload
            }
          }
        }
      };

    case "FORM_CONNECT_GOOGLE_CALENDAR":
      return {
        ...state,
        settings: {
          ...state.settings,
          connect: {
            ...state.settings.connect,
            google_calendar: {
              ...state.settings.connect.google_calendar,
              ...payload
            }
          }
        }
      };

    case "FORM_CONNECT_ZENDESK":
      return {
        ...state,
        settings: {
          ...state.settings,
          connect: {
            ...state.settings.connect,
            zendesk: {
              ...state.settings.connect.zendesk,
              ...payload
            }
          }
        }
      };

    case "FORM_CONNECT_ZENDESK_CUSTOM_FIELDS":
      return {
        ...state,
        settings: {
          ...state.settings,
          connect: {
            ...state.settings.connect,
            zendesk: {
              ...state.settings.connect.zendesk,
              custom_fields: payload
            }
          }
        }
      };

    case "FORM_CONNECT_GA_TRACKING_ID":
      return {
        ...state,
        settings: {
          ...state.settings,
          connect: {
            ...state.settings.connect,
            ga: {
              ...state.settings.connect.ga,
              tracking_id: payload
            }
          }
        }
      };

    case "FORM_CONNECT_FACEBOOK":
      return {
        ...state,
        settings: {
          ...state.settings,
          connect: {
            ...state.settings.connect,
            facebook: {
              ...state.settings.connect.facebook,
              ...payload
            }
          }
        }
      };

    case "FORM_CONNECT_TWITTER":
      return {
        ...state,
        settings: {
          ...state.settings,
          connect: {
            ...state.settings.connect,
            twitter: {
              ...state.settings.connect.twitter,
              ...payload
            }
          }
        }
      };

    case "FORM_CONNECT_WEBHOOK":
      return {
        ...state,
        settings: {
          ...state.settings,
          connect: {
            ...state.settings.connect,
            webhook: {
              ...state.settings.connect.webhook,
              ...payload
            }
          }
        }
      };

    // Response
    //
    case "RESPONSE_ACTIVATE":
      return {
        ...state,
        activeResponse: payload
      };

    case "RESPONSE_SET_RANGE":
      return {
        ...state,
        rangeStart: payload.start,
        rangeEnd: payload.end
      };

    case "FORM_SYNC_RESPONSES":
      return {
        ...state,
        activeResponse: 0,
        responses: [...payload]
      };

    case "FORM_RESPONSES_DELETE":
      return {
        ...state,
        responses: state.responses.filter((element, index) => index !== payload)
      };

    case "FORM_RESPONSES_DELETE_BY_ID":
      return {
        ...state,
        responses: state.responses.filter(
          (element, index) => element.id !== payload
        )
      };

    default:
      return state;
  }
}
