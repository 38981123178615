
export default function forms(state=[], {type, payload}) {
  switch (type) {

    case 'FORMS_SYNC':
      return payload;

    case 'FORMS_CLEAR':
      return [];

    case 'FORMS_APPEND':
      return [
        ...state,
        payload,
      ];

    case 'FORMS_DELETE':
      return state.filter((element, index) => element.slug !== payload);

    case 'FORMS_RENAME':
      return state.map((item, index) => {
        if (item.slug === payload.slug) {
          return {
            ...item,
            name: payload.name,
          }
        }
        return item;
      });


    case 'FORM_RESPONSE_NEW_ZERO':
      return state.map((item, index) => {
        if (index === payload) {
          return {
            ...item,
            new_responses_count: 0,
          }
        }
        return item;
      });

    default:
      return state;
  }
}

