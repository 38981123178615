import React, { useContext, useState } from "react";
import Store from "store";
import * as Utils from "utils";

/**
 * Block Preview
 */
export function Preview(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  return (
    <>
      <h6>{block.props.title}</h6>
      <p>{block.props.subtitle}</p>
    </>
  );
}

/**
 * Inspector
 */
export function Inspector(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];
  const set = (name, value) =>
    dispatch(Utils.Block.update(name, value, block, props.index));

  return (
    <div className="quickview-body">
      <div className="form-group row">
        <label className="col-4 col-form-label">Title</label>
        <div className="col-8">
          <input
            type="text"
            className="form-control"
            onChange={e => set("props.title", e)}
            value={block.props.title}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">Subtitle</label>
        <div className="col-8">
          <input
            type="text"
            className="form-control"
            onChange={e => set("props.subtitle", e)}
            value={block.props.subtitle}
          />
        </div>
      </div>

      <div className="form-group flexbox">
        <label className="col-form-label">Visible</label>
        <div
          className="switch switch-inline"
          onClick={() => set("props.visible", !block.props.visible)}
        >
          <input
            type="checkbox"
            className="switch-input"
            checked={block.props.visible || false}
            onChange={e => set("props.visible", e.target.checked)}
          />
          <label className="switch-label"></label>
        </div>
      </div>
    </div>
  );
}

function nl2br(str, is_xhtml) {
  var breakTag =
    is_xhtml || typeof is_xhtml === "undefined" ? "<br " + "/>" : "<br>";
  return (str + "").replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    "$1" + breakTag + "$2"
  );
}
