import React, { useContext, useState, useEffect } from "react";
import Store from "store";
import * as Utils from "utils";

export function Preview(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  const actionSign = (action) => {
    switch (action) {
      case "set":
        return <span>=</span>;
      case "add":
        return <span>+</span>;
      case "subtract":
        return <span>-</span>;
      case "multiply":
        return <span>&times;</span>;
      case "divide":
        return <span>&divide;</span>;
    }
  };

  return (
    <>
      <span className="prefix">assign</span>
      <span className="value">
        <var>{block.props.variable}</var> {actionSign(block.props.action)}{" "}
        {block.props.value}
      </span>
    </>
  );
}

export function Inspector(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];
  const variables = state.form.blocks.filter(
    (element, index) => element.type == "variable"
  );

  const set = (name, value) =>
    dispatch(Utils.Block.update(name, value, block, props.index));

  useEffect(() => {
    if (block.props.variable === "") {
      set("props.variable", variables[0].name);
    }
  }, []);

  if (variables.length < 1) {
    return (
      <div className="quickview-body">
        <p>You don't have any variable in your chatbot.</p>
      </div>
    );
  }

  return (
    <div className="quickview-body">
      <div className="form-row mb-4">
        <div className="col-6">
          <label className="col-form-label">Action</label>
          <select
            className="custom-select"
            value={block.props.action}
            onChange={(e) => set("props.action", e)}
          >
            <option value="set">Set (=)</option>
            <option value="add">Add (+)</option>
            <option value="subtract">Subtract (-)</option>
            <option value="multiply">Multiply (&times;)</option>
            <option value="divide">Divide (&divide;)</option>
          </select>
        </div>

        <div className="col-6">
          <label className="col-form-label">Variable</label>
          <select
            className="custom-select"
            value={block.props.variable || ""}
            onChange={(e) => set("props.variable", e)}
          >
            {variables.map((variable, index) => (
              <option value={variable.name} key={index}>
                {variable.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-row">
        <label className="col-6 col-form-label text-right">
          {block.props.action === "set" ? "To" : "By"}
        </label>

        <div className="col-6">
          <input
            type="text"
            className="form-control"
            placeholder={
              block.props.action === "set" ? "Any Value" : "Integer Value"
            }
            onChange={(e) => set("props.value", e)}
            value={block.props.value || ""}
          />
        </div>
      </div>

      <hr />

      <p>
        The above modification to your variable performs if a user reached by
        this section.
      </p>
    </div>
  );
}
