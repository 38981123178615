import React, { useContext, useState, useEffect } from "react";

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export default function TheEditor(props) {
  const [ content, setContent ] = useState(EditorState.createEmpty());

  const toolbar = {
    options: ['inline', 'blockType', 'fontSize', 'list', 'link'],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    blockType: {
      options: ['Normal', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
    },
    fontSize: {
      options: [12, 14, 15, 16, 18, 24, 30],
    },
    list: {
      options: ['unordered', 'ordered'],
    },
    link: {
      defaultTargetOption: '_blank',
    }
  };

  const toolbarSlim = {
    options: ['link', 'inline', 'fontSize'],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    fontSize: {
      options: [12, 14, 15, 16, 18, 24, 30],
    },
    list: {
      options: ['unordered', 'ordered'],
    },
    link: {
      defaultTargetOption: '_blank',
    }
  };

  useEffect( () => {
    const html = props.content || '';
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setContent(editorState);
    }
  }, [])

  const saveContent = (editorState) => {
    setContent(editorState);
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    //html = html.replace(/&lt;br&gt;/g,"");
    props.onChange(html);
  }

  return (
    <Editor
      editorState={content}
      //wrapperClassName="demo-wrapper"
      //editorClassName="demo-editor"
      onEditorStateChange={saveContent}
      toolbar={props.toolbar === 'slim' ? toolbarSlim : toolbar}
    />
  )
}
