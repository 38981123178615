import React, { useContext, useState, useEffect } from "react";
import Store from "store";
import axios from "axios";
import { Link, Route, Switch } from "react-router-dom";
import PlaceholderAvatar from "assets/img/avatar.png";
import {
  IoMdHome,
  IoMdConstruct,
  IoMdBrush,
  IoMdEye,
  IoMdSettings,
  IoMdShare,
  IoMdChatbubbles,
  IoMdAlbums,
  IoIosAddCircle,
  IoIosAddCircleOutline,
  IoMdGitBranch,
  IoMdCreate,
  IoMdHelpCircle,
  IoMdCard
} from "react-icons/io";
import { FiExternalLink } from "react-icons/fi";

export default function Navbar({ match }) {
  const { state, dispatch } = useContext(Store);

  const ActionSave = () => {
    const [btnTitle, setBtnTitle] = useState("Save Changes");

    const save = () => {
      if (state.form.slug === null) {
        return;
      }

      setBtnTitle("Saving...");
      /*
      if (state.form.cover.img_bg !== null && state.form.cover.img_bg.name !== '') {
        const data = new FormData();
        data.append('img', state.form.cover.img_bg);

        axios.post('/form/upload/cover/'+ state.form.slug, data)
        .then(response => {
          console.log(response.data.url);
          let nextCover = Object.assign({},state.form.cover);
          nextCover.img_bg = response.data.url;
          dispatch({
            type: "FORM_UPDATE_COVER",
            payload: nextCover,
          });

          state.form.cover.img_bg = response.data.url;

          axios.post(`/form/update/${state.form.slug}`, state.form)
          .then(response => {
            console.log(response);
            setBtnTitle('Save Changes');
          })
          .catch(error => {
            console.log(error);
          })

          console.log(response.data);
        })
        .catch(error => {
          console.log(error);
        })
      }
      else {
        axios.post(`/form/update/${state.form.slug}`, state.form)
        .then(response => {
          console.log(response);
          setBtnTitle('Save Changes');
        })
        .catch(error => {
          console.log(error);
        })
      }
      */

      let requiresPremium = false;
      let minPlan = "Free";
      state.form.blocks.map(block => {
        if (
          ["jump", "label", "image", "hidden", "navigation"].includes(
            block.type
          )
        ) {
          requiresPremium = true;
          minPlan = "Lite";
          return false;
        }
      });

      state.form.blocks.map(block => {
        if (["variable", "assign"].includes(block.type)) {
          requiresPremium = true;
          minPlan = "Standard";
          return false;
        }
      });

      const postData = {
        data: {
          blocks: state.form.blocks,
          meta: {
            version: 1,
            premium: requiresPremium,
            plan: minPlan
          }
        },
        settings: {
          ...state.form.settings,
          popup: state.form.popup
        },
        name: state.form.name
      };

      axios
        .post(`/form/update/${state.form.slug}`, postData)
        .then(response => {
          //console.log(response);
          setBtnTitle("Save Changes");
        })
        .catch(error => {
          //console.log(error);
        });
    };

    const destroy = () => {
      axios
        .post(`/form/destroy/${state.form.slug}`)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    };

    useEffect(() => {
      let saving = setInterval(() => {
        save();
      }, 60000);

      return function cleanup() {
        clearInterval(saving);
      };
    }, []);

    return (
      <>
        <span className="navbar-title">
          <IoMdCreate /> {state.form.name}
        </span>
        {state.form.meta && state.form.meta.premium && !state.user.subscribed && (
          <span
            className={
              "badge badge-pale ml-5 " +
              (state.user.on_trial ? "badge-warning" : "badge-danger")
            }
            style={{ cursor: "pointer" }}
            data-toggle="modal"
            data-target="#modal-premium-chatbot-warning"
            title="Click for more details"
          >
            Premium{" "}
            {!!state.form.meta &&
            !!state.form.meta.plan &&
            state.form.meta.plan === "Standard"
              ? " — Standard"
              : ""}
          </span>
        )}
        <span className="navbar-divider"></span>
        <button
          id="btn-form-save"
          className="btn btn-sm btn-secondary mr-4"
          onClick={save}
          title="Auto save every minute"
        >
          {btnTitle}
        </button>
        <a
          className="btn btn-sm btn-link d-none d-md-inline-block"
          href={`${state.app.url.do}/e/${state.form.slug}`}
          target="_blank"
        >
          <FiExternalLink className="mr-1" /> View Chatbot
        </a>
      </>
    );
  };

  const ActionTitle = () => {
    return <div className="navbar-title">Page Title</div>;
  };

  return (
    <>
      <header className="navbar">
        <section className="navbar-left">
          <Switch>
            <Route
              exact
              path={[
                "/form/:slug/editor",
                "/form/:slug/connect",
                "/form/:slug/settings"
              ]}
              component={ActionSave}
            />
            <Route
              path="/form/:slug/connect"
              render={() => (
                <div className="navbar-title">
                  <IoMdGitBranch /> Connect
                </div>
              )}
            />
            <Route
              path="/form/:slug/share"
              render={() => (
                <div className="navbar-title">
                  <IoMdShare /> Share — {state.form.name}
                </div>
              )}
            />
            <Route
              path="/form/:slug/responses"
              render={() => (
                <div className="navbar-title">
                  <IoMdChatbubbles /> Responses
                </div>
              )}
            />
            <Route
              path="/help"
              render={() => (
                <div className="navbar-title">
                  <IoMdHelpCircle /> Help Center
                </div>
              )}
            />
            <Route
              path="/billing"
              render={() => (
                <div className="navbar-title">
                  <IoMdCard /> Billing
                </div>
              )}
            />
            <Route
              path="/settings"
              render={() => (
                <div className="navbar-title">
                  <IoMdSettings /> Settings
                </div>
              )}
            />
            <Route
              path="/"
              render={() => (
                <div className="navbar-title">
                  <IoMdAlbums /> All Forms
                </div>
              )}
            />
          </Switch>
        </section>

        <section className="navbar-right">
          <div className="dropdown">
            <a className="dropdown-toggle" href="#" data-toggle="dropdown">
              <img
                className="avatar avatar-sm"
                src={
                  state.site.icon
                    ? state.app.url.storage + state.site.icon
                    : PlaceholderAvatar
                }
                alt="avatar"
              />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <Link className="dropdown-item" to="/billing">
                Billing
              </Link>
              <Link className="dropdown-item" to="/settings">
                Settings
              </Link>
              <Link className="dropdown-item" to="/help">
                Help
              </Link>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="/user/logout">
                Sign out
              </Link>
            </div>
          </div>
        </section>
      </header>

      <div
        className="modal fade"
        id="modal-premium-chatbot-warning"
        tabIndex="-1"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <p className="lead">Premium Chatbot</p>
              {state.user.on_trial && (
                <p>
                  This chatbot is using premium features on{" "}
                  <strong>
                    {!!state.form.meta && state.form.meta.plan
                      ? state.form.meta.plan
                      : "Lite"}
                  </strong>{" "}
                  plan. Once your trial period ends, we won't be able to display
                  or collect responses for this chatbot.
                </p>
              )}
              {!state.user.on_trial && (
                <p>
                  This chatbot is using premium features on{" "}
                  <strong>
                    {!!state.form.meta && state.form.meta.plan
                      ? state.form.meta.plan
                      : "Lite"}
                  </strong>{" "}
                  plan. We won't be able to display or collect responses for
                  this chatbot unless you subscribe to a paid plan or remove the
                  premium blocks.
                </p>
              )}
              <p className="mb-0">Would you like to upgrade your plan now?</p>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Maybe Later
              </button>
              <a
                href={state.app.url.app + "/billing"}
                className="btn btn-primary"
              >
                Upgrade Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
