import React, { useContext, useState } from "react";
import Store from "store";
import * as Utils from 'utils';


export function Preview(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  return <span>{block.name}</span>
}



export function Inspector(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];
  const set = (name, value) => dispatch( Utils.Block.update(name, value, block, props.index) );

  return (
    <div className="quickview-body">
      <div className="form-group row">
        <label className="col-4 col-form-label">Name</label>
        <div className="col-8">
          <input type="text" className="form-control" onChange={(e) => set('name', e)} value={block.name} />
        </div>
      </div>
    </div>
  )
}
