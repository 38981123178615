import React from "react";
import {
  IoMdText,
  IoMdBookmark,
  IoMdArrowRoundForward,
  IoMdLink,
  IoLogoTwitter,
  IoMdReturnRight,
  IoMdCheckmarkCircleOutline,
  IoMdRadioButtonOn,
  IoIosAt,
  IoMdRemove,
  IoMdReorder,
  IoLogoUsd,
  IoMdCalendar,
  IoMdImage,
  IoIosLink,
  IoMdAlarm,
  IoMdBrowsers,
  IoMdGlasses,
  IoIosCode,
  IoIosCodeWorking,
  IoMdHappy
} from "react-icons/io";

const left = [
  {
    type: "text",
    title: "Text",
    icon: <IoMdText />,
    multiple: false,
    default: {
      type: "text",
      align: "left",
      props: {
        text: "Hi there",
        delay: 1
      }
    }
  },

  {
    type: "image",
    title: "Image",
    icon: <IoMdImage />,
    multiple: false,
    default: {
      type: "image",
      align: "left",
      props: {
        delay: 1
      }
    }
  },

  {
    type: "links",
    title: "Links",
    icon: <IoMdLink />,
    multiple: false,
    default: {
      type: "links",
      align: "left",
      props: {
        delay: 1,
        items: [
          {
            text: "Link 1",
            url: ""
          },
          {
            text: "Link 2",
            url: ""
          }
        ]
      }
    }
  },

  {
    type: "accordion",
    title: "Accordion",
    icon: <IoMdBrowsers />,
    multiple: false,
    default: {
      type: "accordion",
      align: "left",
      props: {
        delay: 1,
        items: [
          {
            title: "Title",
            text: ""
          },
          {
            title: "Title",
            text: ""
          }
        ]
      }
    }
  },

  {
    type: "navigation",
    title: "Navigation",
    icon: <IoIosLink />,
    multiple: false,
    default: {
      type: "navigation",
      align: "left",
      props: {
        delay: 1,
        items: [
          {
            title: "Formito Title",
            text:
              "Short description about the chatbot we directing the user to.",
            slug: ""
          }
        ]
      }
    }
  },

  {
    type: "social",
    title: "Social",
    icon: <IoLogoTwitter />,
    multiple: false,
    default: {
      type: "social",
      align: "left",
      props: {
        delay: 1,
        items: [
          {
            icon: "facebook",
            url: ""
          },
          {
            icon: "twitter",
            url: ""
          },
          {
            icon: "instagram",
            url: ""
          }
        ]
      }
    }
  },

  /*
  {
    type: 'cover',
    title: 'Cover',
    icon: <IoMdText />,
    multiple: false,
    default: {
      type: 'cover',
      align: 'left',
      props: {
        text: 'Cover Title',
        title: 'Cover Title',
      },
      validation: {},
    }
  },
  */

  {
    type: "label",
    title: "Label",
    icon: <IoMdBookmark />,
    multiple: false,
    default: {
      type: "label",
      align: "left",
      name: "label",
      props: {}
    }
  },

  {
    type: "jump",
    title: "Jump",
    icon: <IoMdArrowRoundForward />,
    multiple: false,
    default: {
      type: "jump",
      align: "left",
      props: {
        to: ""
      }
    }
  },

  {
    type: "variable",
    title: "Variable",
    icon: <IoIosCode />,
    multiple: false,
    default: {
      type: "variable",
      align: "left",
      name: "Variable",
      props: {
        value: ""
      }
    }
  },

  {
    type: "assign",
    title: "Assign",
    icon: <IoIosCodeWorking />,
    multiple: false,
    default: {
      type: "assign",
      align: "left",
      props: {
        variable: "",
        action: "set", // set, add, subtract, multiply, divide
        value: ""
      }
    }
  }
];

const right = [
  {
    type: "short_text",
    title: "Short Text",
    icon: <IoMdRemove />,
    multiple: false,
    default: {
      type: "short_text",
      align: "right",
      name: "Text",
      props: {
        placeholder: "",
        delay: 1
      },
      validation: {}
    }
  },

  {
    type: "long_text",
    title: "Long Text",
    icon: <IoMdReorder />,
    multiple: false,
    default: {
      type: "long_text",
      align: "right",
      name: "Message",
      props: {
        placeholder: "Message",
        delay: 1
      },
      validation: {}
    }
  },

  {
    type: "email",
    title: "Email",
    icon: <IoIosAt />,
    multiple: false,
    default: {
      type: "email",
      align: "right",
      name: "Email",
      props: {
        placeholder: "Email Address",
        delay: 1
      },
      validation: {}
    }
  },

  {
    type: "number",
    title: "Number",
    icon: <IoLogoUsd />,
    multiple: false,
    default: {
      type: "number",
      align: "right",
      name: "Number",
      props: {
        placeholder: "Number",
        default: 0,
        min: 0,
        max: 100,
        step: 1,
        delay: 1
      },
      validation: {}
    }
  },

  {
    type: "date",
    title: "Date",
    icon: <IoMdCalendar />,
    multiple: false,
    default: {
      type: "date",
      align: "right",
      name: "Date",
      props: {
        placeholder: "Pick a date",
        delay: 1
      },
      validation: {}
    }
  },

  {
    type: "appointment",
    title: "Appointment",
    icon: <IoMdAlarm />,
    multiple: false,
    default: {
      type: "appointment",
      align: "right",
      name: "Appointment",
      props: {
        placeholder_date: "Pick a date",
        placeholder_time: "Pick a time",
        time_interval: 30,
        start_hour: 10,
        end_hour: 16,
        available_days: {
          day_0: false,
          day_1: true,
          day_2: true,
          day_3: true,
          day_4: true,
          day_5: true,
          day_6: false
        },
        delay: 1
      },
      validation: {}
    }
  },

  {
    type: "single_choice",
    title: "Single Choice",
    icon: <IoMdRadioButtonOn />,
    multiple: false,
    default: {
      type: "single_choice",
      align: "right",
      name: "Single Choice",
      props: {
        multiple: false,
        delay: 1,
        items: [
          {
            text: "Yes",
            jump: "",
            assign: {
              variable: "",
              action: "set",
              value: ""
            }
          },
          {
            text: "No",
            jump: "",
            assign: {
              variable: "",
              action: "set",
              value: ""
            }
          }
        ]
      },
      validation: {}
    }
  },

  {
    type: "multiple_choice",
    title: "Multiple Choice",
    icon: <IoMdCheckmarkCircleOutline />,
    multiple: false,
    default: {
      type: "multiple_choice",
      align: "right",
      name: "Multiple Choice",
      props: {
        multiple: false,
        delay: 1,
        items: [
          {
            text: "Pepperoni"
          },
          {
            text: "Mushrooms"
          },
          {
            text: "Sausage"
          },
          {
            text: "Olives"
          }
        ]
      },
      validation: {}
    }
  },

  {
    type: "feedback",
    title: "Feedback",
    icon: <IoMdHappy />,
    multiple: false,
    default: {
      type: "feedback",
      align: "right",
      name: "Feedback",
      props: {
        multiple: false,
        delay: 1,
        items: [
          {
            value: "Terrible"
          },
          {
            value: "Bad"
          },
          {
            value: "Okay"
          },
          {
            value: "Good"
          },
          {
            value: "Great"
          }
        ]
      },
      validation: {}
    }
  },

  {
    type: "hidden",
    title: "Hidden Input",
    icon: <IoMdGlasses />,
    multiple: false,
    default: {
      type: "hidden",
      align: "right",
      name: "Hidden",
      props: {},
      validation: {}
    }
  }

  /*
  {
    type: 'number',
    title: 'Number',
    icon: <IoMdReturnRight />,
    multiple: false,
    default: {
      type: 'number',
      align: 'right',
      props: {
        min: 0,
        max: 100,
        step: 1,
        default: 1,
        delay: 1,
      },
      validation: {},
    },
  },
  */
];

export const blocksList = {
  left,
  right
};
