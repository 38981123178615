import React, { useContext, useState, useEffect } from "react";
import Store from "store";
import Repeater from "components/repeater";
import * as Utils from "utils";

export function Preview(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  return block.props.items.map((item, index) => (
    <div className="option" key={index}>
      {item.text}
      {item.jump && <span className="jmp">{item.jump}</span>}
    </div>
  ));
}

export function Inspector(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  const labels = state.form.blocks.filter(
    (element, index) => element.type == "label"
  );

  const variables = state.form.blocks.filter(
    (element, index) => element.type == "variable"
  );

  const set = (name, value) =>
    dispatch(Utils.Block.update(name, value, block, props.index));

  const setProps = (name, value, e = null) => {
    let nextBlock = Object.assign({}, JSON.parse(JSON.stringify(block)));
    nextBlock.props = {
      ...nextBlock.props,
      [name]: value
    };
    dispatch({
      type: "BLOCK_UPDATE",
      payload: {
        index: props.index,
        item: nextBlock
      }
    });
  };

  return (
    <div className="quickview-body">
      <div className="form-group row">
        <label className="col-4 col-form-label">Name</label>
        <div className="col-8">
          <input
            type="text"
            className="form-control"
            onChange={e => set("name", e)}
            value={block.name}
          />
          <small className="form-text">Leave empty to ignore value.</small>
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          Delay <small>(sec)</small>
        </label>
        <div className="col-8">
          <input
            type="number"
            className="form-control"
            min="0"
            max="20"
            step="0.5"
            onChange={e => set("props.delay", e.target.value)}
            value={block.props.delay || 0}
          />
        </div>
      </div>

      <div className="form-group flexbox d-none">
        <label className="col-form-label">Multiple Choice</label>
        <div
          className="switch switch-inline"
          onClick={() => set("props.multiple", !block.props.multiple)}
        >
          <input
            type="checkbox"
            className="switch-input"
            checked={block.props.multiple || false}
            onChange={e => set("props.multiple", e.target.checked)}
          />
          <label className="switch-label"></label>
        </div>
      </div>

      <div className="form-group flexbox">
        <label className="col-form-label">Stack Vertically</label>
        <div
          className="switch switch-inline"
          onClick={() => set("props.stacked", !block.props.stacked)}
        >
          <input
            type="checkbox"
            className="switch-input"
            checked={block.props.stacked || false}
            onChange={e => set("props.stacked", e.target.checked)}
          />
          <label className="switch-label"></label>
        </div>
      </div>

      <h6 className="divider">Items</h6>
      <Repeater
        block={block}
        index={props.index}
        title="text"
        defaultAddState={{ text: "Option", jump: "", assign: {} }}
        render={(item, set, index) => {
          if (!("assign" in item) || !("action" in item.assign)) {
            set(
              "assign",
              {
                variable: "",
                action: "set",
                value: ""
              },
              index
            );
          }

          return (
            <>
              <div className="form-group row">
                <label className="col-4 col-form-label">Text</label>
                <div className="col-8">
                  <input
                    type="text"
                    className="form-control"
                    value={item.text}
                    onChange={e => set("text", e, index)}
                  />
                </div>
              </div>

              {(labels.length > 0 || variables.length > 0) && (
                <h6 className="divider mb-4">On Select</h6>
              )}

              {labels.length > 0 && (
                <div className="form-group row">
                  <label className="col-4 col-form-label">Jump To</label>
                  <div className="col-8">
                    <select
                      className="custom-select"
                      value={item.jump || ""}
                      onChange={e => set("jump", e, index)}
                    >
                      <option value="">—</option>
                      {labels.map((label, index) => (
                        <option value={label.name} key={index}>
                          {label.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {variables.length > 0 && (
                <>
                  <div className="form-group row">
                    <label
                      className="col-4 col-form-label"
                      title="Update variable"
                    >
                      Assign
                    </label>
                    <div className="col-8">
                      <select
                        className="custom-select"
                        value={item.assign ? item.assign.variable || "" : ""}
                        onChange={e => set("assign.variable", e, index)}
                      >
                        <option value="">—</option>
                        {variables.map((variable, index) => (
                          <option value={variable.name} key={index}>
                            {variable.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {item.assign && !!item.assign.variable && (
                    <>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">Action</label>
                        <div className="col-8">
                          <select
                            className="custom-select"
                            value={item.assign.action}
                            onChange={e => set("assign.action", e, index)}
                          >
                            <option value="set">Set (=)</option>
                            <option value="add">Add (+)</option>
                            <option value="subtract">Subtract (-)</option>
                            <option value="multiply">Multiply (&times;)</option>
                            <option value="divide">Divide (&divide;)</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          {item.assign.action === "set" ? "To" : "By"}
                        </label>
                        <div className="col-8">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={
                              item.assign.action === "set"
                                ? "Any Value"
                                : "Integer Value"
                            }
                            onChange={e => set("assign.value", e, index)}
                            value={item.assign.value || ""}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          );
        }}
      />
    </div>
  );
}

/**
 * Response message
 */
export function Response({ message }) {
  return (
    <div className={`message-item block-${message.type}`}>
      {message.content}
    </div>
  );
}
