import $ from "jquery";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./utils/jquery";
import "react-perfect-scrollbar/dist/css/styles.css";
import "daterangepicker/daterangepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import moment from "moment";

import React, { useState, useContext, useReducer, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import Analytics from "react-router-ga";
import axios from "axios";

import Store from "./store";
import reducer from "./reducer";

import { usePersistedContext, usePersistedReducer } from "./usePersist";

import Editor from "./form/editor";
import Settings from "page/setting";
import Help from "page/Help";

import "./scss/app.scss";
import Home from "page/Home";
import Onboarding from "page/Onboarding";
import Billing from "page/Billing";
import Inspector from "./form/editor/Inspector";
import Respondent from "./form/editor/Respondent";
import { Login, Register, Recover, Reset, Logout } from "page/user";
import { Navbar, Sidebar } from "./layout";
import {
  Designer,
  Previewer,
  Responses,
  ResponsePrint,
  Sharer,
  Connect
} from "./form";
import FormSettings from "./form/settings";

axios.defaults.baseURL = "https://fbtest-27dd8.appspot.com/v1";
let ga_tracking_id = "UA-73325209-12";
if (process.env.NODE_ENV !== "production") {
  ga_tracking_id = "";
  axios.defaults.baseURL = "http://api.formito.test/v1";
}

const Loading = () => (
  <div className="spinner-wrapper">
    <div className="spinner-grow text-info" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

const TheForm = ({ match }) => {
  const { state, dispatch } = useContext(Store);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`/form/show/${match.params.slug}`)
      .then(response => {
        dispatch({ type: "FORM_SYNC", payload: response.data });
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="spinner-wrapper">
        <div className="spinner-grow text-info" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <Switch>
      <Route exact path="/form/:slug/editor" component={Editor} />
      <Route exact path="/form/:slug/design" component={Designer} />
      <Route exact path="/form/:slug/preview" component={Previewer} />
      <Route exact path="/form/:slug/connect" component={Connect} />
      <Route exact path="/form/:slug/share" component={Sharer} />
      <Route exact path="/form/:slug/settings" component={FormSettings} />
      <Route exact path="/form/:slug/responses" component={Responses} />
      <Route
        exact
        path="/form/:slug/responses/:id/print"
        component={ResponsePrint}
      />

      <Redirect to="/" />
    </Switch>
  );
};

function App() {
  const globalStore = usePersistedContext(useContext(Store));
  const [state, dispatch] = usePersistedReducer(
    useReducer(reducer, globalStore)
  );

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + state.user.token;
    axios.defaults.headers.common["X-Formito-Site"] = "";

    axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        let { response } = error;
        if (response && response.status === 401) {
          // Do logout;
          dispatch({ type: "USER_LOGOUT" });
          return Promise.reject(error.response);
        } else if (error.response) {
          //console.log('some API_CLIENT error');
          return Promise.reject(error.response);
        }
        return Promise.reject(error);
      }
    );

    const addFormitoOnBoarding = user => {
      window.formito_form = "1bt";
      window.formito_predefined_variables = {
        user_id: user.id,
        user_email: user.email
      };
      let tag = document.createElement("script");
      tag.async = !0;
      tag.src = "https://script.formito.com/v1/formito.js";
      let a = document.getElementsByTagName("script")[0];
      a.parentNode.insertBefore(tag, a);
    };

    const authenticate = async () => {
      await axios
        .get("/user/me")
        .then(response => {
          dispatch({ type: "USER_SYNC", payload: response.data.user });
          dispatch({ type: "SITE_SYNC", payload: response.data.site });
          dispatch({ type: "FORMS_SYNC", payload: response.data.forms });

          if (
            moment().diff(moment(response.data.user.created_at), "days") < 3
          ) {
            addFormitoOnBoarding(response.data.user);
          }
        })
        .catch(error => {
          //console.log(error);
        });

      dispatch({ type: "APP_READY" });
    };

    authenticate();
  }, [state.user.token]);

  return (
    <Store.Provider value={{ state, dispatch }}>
      <Router>
        <Analytics id={ga_tracking_id} basename="APP">
          <Switch>
            <Route exact path="/user/login" component={Login} />
            <Route exact path="/user/logout" component={Logout} />
            <Route exact path="/user/register" component={Register} />
            <Route exact path="/user/password/recover" component={Recover} />
            <Route exact path="/user/password/reset/:token" component={Reset} />

            {!state.app.ready && <Route component={Loading} />}
            {!state.user.auth && <Redirect to="/user/login" />}

            <Route exact path="/user/onboarding" component={Onboarding} />

            {state.user.auth && (
              <>
                {/*<Route exact path="/form/:slug" component={Inspector} />*/}
                {/*<Route exact path="/form/:slug/responses" component={Respondent} />*/}

                {!window.location.href.includes("/print") && (
                  <>
                    <Route component={Sidebar} />
                    <Navbar />
                  </>
                )}

                <main
                  className={
                    "main-container " +
                    (state.user.subscribed
                      ? "user-subscribed"
                      : "user-not-subscribed")
                  }
                >
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/form/:slug" component={TheForm} />

                    {/*
                    <Route exact path="/form/:slug/editor" component={Editor} />
                    <Route exact path="/form/:slug/design" component={Designer} />
                    <Route exact path="/form/:slug/preview" component={Previewer} />
                    <Route exact path="/form/:slug/share" component={Sharer} />
                    <Route exact path="/form/:slug/settings" component={FormSettings} />
                    <Route exact path="/form/:slug/responses" component={Responses} />
                    */}

                    <Route path="/help" component={Help} />
                    <Route path="/settings" component={Settings} />
                    <Route path="/billing" component={Billing} />
                    <Redirect to="/" />
                    {/*<Route component={NoMatch}/>*/}
                  </Switch>
                </main>
              </>
            )}

            <Redirect to="/user/login" />
          </Switch>
        </Analytics>
      </Router>
    </Store.Provider>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
