import React, { useContext, useState } from "react";
import Store from "store";
import Repeater from 'components/repeater';
import * as Utils from 'utils';

export function Preview(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  return block.props.items.map( (item, index) => (
    <span className={`social-link social-${item.icon} fi-${item.icon}`} key={index} />
  ))
}




export function Inspector(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];
  const set = (name, value) => dispatch( Utils.Block.update(name, value, block, props.index) );


  return (
    <div className="quickview-body">

      <div className="form-group row">
        <label className="col-4 col-form-label">Delay <small>(sec)</small></label>
        <div className="col-8">
          <input type="number" className="form-control" min="0" max="20" step="0.5"
            onChange={(e) => set('props.delay', e.target.value)}
            value={block.props.delay || 0}
          />
        </div>
      </div>


      <h6 className="divider d-none">Social Networks</h6>

      <Repeater
        block={block}
        index={props.index}
        title='icon'
        defaultAddState={{icon: 'facebook', url: 'https://'}}
        render={(item, set, index) => (
          <>
            <div className="form-group row">
              <label className="col-4 col-form-label">Network</label>
              <div className="col-8">
                <select className="custom-select" value={item.icon} onChange={e => set('icon', e, index)}>
                  <option value=""></option>
                  <option value="facebook">Facebook</option>
                  <option value="twitter">Twitter</option>
                  <option value="instagram">Instagram</option>
                  <option value="linkedin">Linkedin</option>
                  <option value="dribbble">Dribbble</option>
                  <option value="github">GitHub</option>
                  <option value="slack">Slack</option>
                  <option value="skype">Skype</option>
                  <option value="whatsapp">WhatsApp</option>
                  <option value="youtube-play">Youtube</option>
                  <option value="medium">Medium</option>
                  <option value="envato">Envato</option>
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-4 col-form-label">Url</label>
              <div className="col-8">
                <input type="text" className="form-control" value={item.url} onChange={e => set('url', e, index)} />
              </div>
            </div>
          </>
      )} />

    </div>
  )
}


/**
 * Response message
 */
export function Response({message}) {
  if (!message.props || message.props.items === undefined) {
    return null;
  }

  return (
    <div className={`message-item block-${message.type}`}>
      {message.props.items.map( (item, index) => <span className={`social-link social-${item.icon} fi-${item.icon}`} key={index} /> )}
    </div>
  )
}
