import React, { useContext, useState, useEffect } from "react";
import Store from "store";
import axios from "axios";
import { Link } from "react-router-dom";
import LogoWebhook from "assets/img/integrations/webhook.png";
import LogoZapier from "assets/img/integrations/zapier.png";
import LogoMailchimp from "assets/img/integrations/mailchimp.png";
import LogoGoogleSheets from "assets/img/integrations/sheets.png";
import LogoZendesk from "assets/img/integrations/zendesk.png";
import LogoGoogleAnalytics from "assets/img/integrations/analytics.png";
import LogoGoogleCalendar from "assets/img/integrations/calendar.png";
import LogoFacebook from "assets/img/integrations/facebook.png";
import LogoTwitter from "assets/img/integrations/twitter.png";

import RepeaterArray from "components/repeaterArray";

export default function Connect({ match }) {
  const { state, dispatch } = useContext(Store);
  const [sheetSyncing, setSheetSyncing] = useState(false);
  const appointment_blocks = state.form.blocks.reduce(function (
    appointments,
    block
  ) {
    if (block.type === "appointment" && block.name !== "") {
      appointments.push(block.name);
    }
    return appointments;
  },
  []);
  let can_use_webhook = false;
  let can_use_integrations = false;
  let can_use_tracking = false;

  if (state.user.on_trial) {
    can_use_webhook = true;
    can_use_integrations = true;
    can_use_tracking = true;
  } else {
    if (state.user.subscribed) {
      can_use_webhook = true;
    }

    if (
      ["standard-y1-v1", "standard-m1-v1", "pro-y1-v1", "pro-m1-v1"].includes(
        state.user.stripe_plan
      )
    ) {
      can_use_integrations = true;
    }

    if (["pro-y1-v1", "pro-m1-v1"].includes(state.user.stripe_plan)) {
      can_use_tracking = true;
    }
  }

  let webhook_sample = {
    type: "response.new",
    data: {
      id: 123456,
      chatbot: state.form.slug,
      completed: true,
      timestamp: Date.now(),
      inputs: {},
    },
  };

  state.form.blocks.map((block, index) => {
    if (block.align === "right" && block.name) {
      webhook_sample.data.inputs[block.name] = "User Input";
    }

    if (block.type === "variable" && block.name) {
      webhook_sample.data.inputs[block.name] = "Value";
    }
  });

  let available_variables = "";
  available_variables += "{{formito_name}} \t\t" + state.form.name + "\n";
  available_variables += "{{formito_slug}} \t\t" + state.form.slug + "\n";
  available_variables +=
    "{{formito_responses_url}} \t" +
    "https://admin.formito.com/form/" +
    state.form.slug +
    "/responses" +
    "\n";
  available_variables +=
    "{{all_answers_html}} \t\tAll of the followings in HTML format" + "\n";
  available_variables +=
    "{{all_answers_plain}} \t\tAll of the followings in plain text format" +
    "\n";
  state.form.blocks.map((block, index) => {
    if (block.align === "right" && block.name) {
      available_variables += "{{" + block.name + "}}" + "\n";
    }

    if (block.type === "variable" && block.name) {
      available_variables += "{{" + block.name + "}}" + "\n";
    }
  });

  if (state.form.settings.connect.webhook.only_completed === undefined) {
    dispatch({
      type: "FORM_CONNECT_WEBHOOK",
      payload: { only_completed: true },
    });
  }

  let email_block_name = "";
  state.form.blocks.map((block) => {
    if (block.type === "email" && block.name) {
      email_block_name = "{{" + block.name + "}}";
      return false;
    }
  });

  if (
    email_block_name &&
    !state.form.settings.connect.zendesk.requester_email
  ) {
    dispatch({
      type: "FORM_CONNECT_ZENDESK",
      payload: { requester_email: email_block_name },
    });
  }

  if (
    email_block_name &&
    !state.form.settings.connect.google_calendar.attendee
  ) {
    dispatch({
      type: "FORM_CONNECT_GOOGLE_CALENDAR",
      payload: { attendee: email_block_name },
    });
  }

  let long_text_block_name = "";
  state.form.blocks.map((block) => {
    if (block.type === "long_text" && block.name) {
      long_text_block_name = "{{" + block.name + "}}";
      return false;
    }
  });

  if (
    long_text_block_name &&
    !state.form.settings.connect.zendesk.ticket_comment
  ) {
    dispatch({
      type: "FORM_CONNECT_ZENDESK",
      payload: { ticket_comment: long_text_block_name },
    });
  }

  if (
    appointment_blocks.length > 0 &&
    !state.form.settings.connect.google_calendar.appointment
  ) {
    dispatch({
      type: "FORM_CONNECT_GOOGLE_CALENDAR",
      payload: { appointment: appointment_blocks[0] },
    });
  }

  if (!state.form.settings.connect.google_calendar.calendar_id) {
    dispatch({
      type: "FORM_CONNECT_GOOGLE_CALENDAR",
      payload: { calendar_id: "primary" },
    });
  }

  const removeGoogleToken = () => {
    let payload = {
      google_access_token: "",
      google_refresh_token: "",
    };

    axios
      .post(`/user/update_settings`, {
        settings: { ...state.user.settings, ...payload },
      })
      .then((response) => {
        //console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });

    dispatch({ type: "USER_SETTINGS", payload });
  };

  const syncGoogleSheet = () => {
    setSheetSyncing(true);
    axios
      .post(`/form/integrate/google/sync/${state.form.slug}`)
      .then((response) => {
        //console.log(response);
        setSheetSyncing(false);
      })
      .catch((error) => {
        console.log(error);
        setSheetSyncing(false);
      });
  };

  const upgradeWarningAlert = (
    <div className="alert alert-warning mb-7">
      <div className="flexbox">
        <div>
          This integration is not included in your billing plan. Please upgrade
          your plan to use this integration.
        </div>
        <Link to="/billing" className="btn btn-warning">
          Upgrade
        </Link>
      </div>
    </div>
  );

  return (
    <section className="main-content">
      <div className="container">
        <br />
        <div className="accordion accordion-arrow-right" id="acc-connect">
          {!can_use_webhook && (
            <div className="alert alert-warning mb-6">
              <div className="flexbox">
                <div>
                  <strong>Webhook integration</strong> is not included in your
                  billing plan. Please upgrade to a paid plan to unlock this
                  integration.
                </div>
                <Link to="/billing" className="btn btn-sm btn-warning">
                  Upgrade
                </Link>
              </div>
            </div>
          )}

          <div className="card">
            <h6 className="card-title">
              <a
                className="collapsed"
                data-toggle="collapse"
                href="#tab-webhook"
              >
                <img src={LogoWebhook} alt="logo" /> Webhook
              </a>
            </h6>

            <div
              id="tab-webhook"
              className="collapse"
              data-parent="#acc-connect"
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-3 col-form-label">Endpoint URL</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="https://example.com/webhook/endpoint"
                      value={state.form.settings.connect.webhook.url || ""}
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_WEBHOOK",
                          payload: { url: e.target.value },
                        })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Method</label>
                  <div className="col-9">
                    <select
                      className="custom-select"
                      value={
                        state.form.settings.connect.webhook.method || "POST"
                      }
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_WEBHOOK",
                          payload: { method: e.target.value },
                        })
                      }
                    >
                      <option value="POST">POST</option>
                      <option value="GET">GET</option>
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label"></label>
                  <div className="col-9">
                    <div
                      className="switch switch-inline"
                      onClick={() =>
                        dispatch({
                          type: "FORM_CONNECT_WEBHOOK",
                          payload: {
                            only_completed: !state.form.settings.connect.webhook
                              .only_completed,
                          },
                        })
                      }
                    >
                      <input
                        type="checkbox"
                        className="switch-input"
                        checked={
                          state.form.settings.connect.webhook.only_completed
                        }
                        onChange={(e) =>
                          dispatch({
                            type: "FORM_CONNECT_WEBHOOK",
                            payload: { only_completed: e.target.checked },
                          })
                        }
                      />
                      <label className="switch-label">
                        &nbsp;&nbsp;Only send completed responses
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label"></label>
                  <div className="col-9">
                    <hr />
                    <p>
                      A JSON data like the following would send to your
                      endpoint.
                    </p>
                    <pre style={{ whiteSpace: "pre" }}>
                      {JSON.stringify(webhook_sample, null, 2)}
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h6 className="divider mt-7">Native Integrations</h6>

          {!can_use_integrations && (
            <div className="alert alert-warning mb-6">
              <div className="flexbox">
                <div>
                  <strong>Native integrations</strong> are not included in your
                  billing plan. Please upgrade to any of Standard or Pro plans
                  to unlock the following integrations.
                </div>
                <Link to="/billing" className="btn btn-sm btn-warning">
                  Upgrade
                </Link>
              </div>
            </div>
          )}

          <div className="card">
            <h6 className="card-title">
              <a
                className="collapsed"
                data-toggle="collapse"
                href="#tab-mailchimp"
              >
                <img src={LogoMailchimp} alt="logo" /> MailChimp
              </a>
            </h6>

            <div
              id="tab-mailchimp"
              className="collapse"
              data-parent="#acc-connect"
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-3 col-form-label"></label>
                  <div className="col-9">
                    <p>
                      Once you provide the following values and add an email
                      block to your chatbot, our application automatically send
                      all the emails from responders to your MailChimp account.
                    </p>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">API Key</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={
                        state.form.settings.connect.mailchimp.api_key || ""
                      }
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_MAILCHIMP_API",
                          payload: e.target.value,
                        })
                      }
                    />
                    <small className="form-text">
                      <a
                        href="https://mailchimp.com/help/about-api-keys/#Find_or_Generate_Your_API_Key"
                        target="_blank"
                      >
                        How to find my API key?
                      </a>
                    </small>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Audience ID</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={
                        state.form.settings.connect.mailchimp.audience_id || ""
                      }
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_MAILCHIMP_AUDIENCE",
                          payload: e.target.value,
                        })
                      }
                    />
                    <small className="form-text">
                      <a
                        href="https://mailchimp.com/help/find-audience-id/"
                        target="_blank"
                      >
                        How to find my Audience ID?
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <h6 className="card-title">
              <a
                className="collapsed"
                data-toggle="collapse"
                href="#tab-zapier"
              >
                <img src={LogoZapier} alt="logo" /> Zapier
                <span className="text-muted small">— BETA</span>
              </a>
            </h6>

            <div
              id="tab-zapier"
              className="collapse"
              data-parent="#acc-connect"
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-3 col-form-label"></label>
                  <div className="col-9">
                    <p>
                      Our Zapier integration is in the invitation-only phase.
                      You can get an invitation by clicking on{" "}
                      <a
                        href="https://zapier.com/developer/public-invite/25505/1f28021d1b2165fbfbca050f894c86c0/"
                        target="_blank"
                      >
                        this link
                      </a>
                      . You need an API Key for this integration. You can find
                      your API Key in the Settings page.
                    </p>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Connected Zaps</label>
                  <div className="col-9">
                    {state.form.settings.connect.zapier.hooks.length < 1 && (
                      <em className="text-muted">
                        You don't have any Zap connected to this chatbot.
                      </em>
                    )}
                    {Object.entries(
                      state.form.settings.connect.zapier.hooks
                    ).map((hook, index) => {
                      const [key, val] = hook;
                      return (
                        <div key={index} className="input-group form-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text pr-3">{key}</span>
                          </div>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            readOnly
                            value={val || ""}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-danger"
                              type="button"
                              onClick={(e) =>
                                dispatch({
                                  type: "FORM_CONNECT_ZAPIER_REMOVE",
                                  payload: key,
                                })
                              }
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <h6 className="card-title">
              <a
                className="collapsed"
                data-toggle="collapse"
                href="#tab-zendesk"
              >
                <img src={LogoZendesk} alt="logo" /> Zendesk
              </a>
            </h6>

            <div
              id="tab-zendesk"
              className="collapse"
              data-parent="#acc-connect"
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-3 col-form-label"></label>
                  <div className="col-9">
                    <p>
                      Connect your chatbot to your Zendesk account and create a
                      support ticket based on the user's responses.
                    </p>
                  </div>
                </div>

                <h6 className="divider">Zendesk Authenticating Information</h6>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Subdomain</label>
                  <div className="col-9">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text pr-3">https://</span>
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="e.g. formitohelp"
                        value={
                          state.form.settings.connect.zendesk.subdomain || ""
                        }
                        onChange={(e) =>
                          dispatch({
                            type: "FORM_CONNECT_ZENDESK",
                            payload: { subdomain: e.target.value },
                          })
                        }
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">.zendesk.com</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Email Address</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="name@example.com"
                      value={
                        state.form.settings.connect.zendesk.email_address || ""
                      }
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_ZENDESK",
                          payload: { email_address: e.target.value },
                        })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">API Token</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="e.g. hCTjOogBTwL99dq95ZOmTKKPs8Ks9cInBU1axvuC"
                      value={
                        state.form.settings.connect.zendesk.api_token || ""
                      }
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_ZENDESK",
                          payload: { api_token: e.target.value },
                        })
                      }
                    />
                    <small className="form-text">
                      <a
                        href="https://support.zendesk.com/hc/en-us/articles/226022787-Generating-a-new-API-token-"
                        target="_blank"
                      >
                        How to find my API token?
                      </a>
                    </small>
                  </div>
                </div>

                <h6 className="divider">Ticket Information — Required</h6>

                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    Requester Email
                  </label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={
                        state.form.settings.connect.zendesk.requester_email ||
                        ""
                      }
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_ZENDESK",
                          payload: { requester_email: e.target.value },
                        })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Ticket Content</label>
                  <div className="col-9">
                    <textarea
                      className="form-control form-control-sm"
                      rows={9}
                      value={
                        state.form.settings.connect.zendesk.ticket_comment || ""
                      }
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_ZENDESK",
                          payload: { ticket_comment: e.target.value },
                        })
                      }
                    />
                  </div>
                </div>

                <h6 className="divider">Ticket Information — Optional</h6>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Requester Name</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={
                        state.form.settings.connect.zendesk.requester_name || ""
                      }
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_ZENDESK",
                          payload: { requester_name: e.target.value },
                        })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Ticket Subject</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={
                        state.form.settings.connect.zendesk.ticket_subject || ""
                      }
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_ZENDESK",
                          payload: { ticket_subject: e.target.value },
                        })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Priority</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="e.g. high"
                      value={
                        state.form.settings.connect.zendesk.ticket_priority ||
                        ""
                      }
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_ZENDESK",
                          payload: { ticket_priority: e.target.value },
                        })
                      }
                    />
                    <small className="form-text text-muted">
                      Any of urgent, high, normal, or low. Default is normal.
                    </small>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Ticket Type</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="e.g. question"
                      value={
                        state.form.settings.connect.zendesk.ticket_type || ""
                      }
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_ZENDESK",
                          payload: { ticket_type: e.target.value },
                        })
                      }
                    />
                    <small className="form-text text-muted">
                      Any of problem, incident, question, or task. Default is
                      not set.
                    </small>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Assignee ID</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="e.g. 123456789"
                      value={
                        state.form.settings.connect.zendesk.assignee_id || ""
                      }
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_ZENDESK",
                          payload: { assignee_id: e.target.value },
                        })
                      }
                    />
                    <small className="form-text text-muted">
                      The agent ID in your Zendesk account to be assignee of
                      this ticket.
                    </small>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Brand ID</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="e.g. 123456789"
                      value={state.form.settings.connect.zendesk.brand_id || ""}
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_ZENDESK",
                          payload: { brand_id: e.target.value },
                        })
                      }
                    />
                    <small className="form-text text-muted">
                      The id of the brand this ticket is associated with
                    </small>
                  </div>
                </div>

                <hr />

                <div className="form-group row">
                  <label className="col-3 col-form-label">Custom Fields</label>
                  <div className="col-9">
                    <RepeaterArray
                      array={
                        state.form.settings.connect.zendesk.custom_fields || []
                      }
                      title="label"
                      dispatch="FORM_CONNECT_ZENDESK_CUSTOM_FIELDS"
                      defaultAddState={{ id: "", label: "", value: "" }}
                      render={(item, set, index) => (
                        <>
                          <div className="form-group row">
                            <label className="col-4 col-form-label">
                              Label
                            </label>
                            <div className="col-8">
                              <input
                                type="text"
                                className="form-control"
                                value={item.label || ""}
                                onChange={(e) => set("label", e, index)}
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-4 col-form-label">
                              Field ID
                            </label>
                            <div className="col-8">
                              <input
                                type="text"
                                className="form-control"
                                value={item.id || ""}
                                onChange={(e) => set("id", e, index)}
                                placeholder="e.g. 360023240054"
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-4 col-form-label">
                              Value
                            </label>
                            <div className="col-8">
                              <input
                                type="text"
                                className="form-control"
                                value={item.value || ""}
                                onChange={(e) => set("value", e, index)}
                                placeholder=""
                              />
                            </div>
                          </div>
                        </>
                      )}
                    />
                  </div>
                </div>

                {/*
                <div className="form-group row align-items-center">
                  <label className="col-3 col-form-label"></label>
                  <div className="col-9">
                    <div className="switch switch-inline1" onClick={() => dispatch({type: 'FORM_CONNECT_ZENDESK', payload: {ticket_incomplete_response: !state.form.settings.connect.zendesk.ticket_incomplete_response} })}>
                      <input type="checkbox" className="switch-input"
                        checked={state.form.settings.connect.zendesk.ticket_incomplete_response || false}
                        onChange={(e) => dispatch({type: 'FORM_CONNECT_ZENDESK', payload: {ticket_incomplete_response: e.target.checked} })}
                      />
                      <label className="switch-label">Create ticket for even incomplete conversations</label>
                    </div>
                  </div>
                </div>
                */}

                <h6 className="divider">Available variables</h6>

                <div className="form-group row">
                  <label className="col-3 col-form-label"></label>
                  <div className="col-9">
                    <p>
                      You can use any of the following values inside the ticket
                      information inputs and custom field's value.
                    </p>
                    <pre style={{ whiteSpace: "pre" }}>
                      {available_variables}
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <h6 className="card-title">
              <a
                className="collapsed"
                data-toggle="collapse"
                href="#tab-sheets"
              >
                <img src={LogoGoogleSheets} alt="logo" /> Google Sheets{" "}
                <span className="text-muted small">— BETA</span>
              </a>
            </h6>

            <div
              id="tab-sheets"
              className="collapse"
              data-parent="#acc-connect"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-auto">
                    Connect your Google account, so Formito can append new
                    responses directly to your Spreadsheet.
                  </div>
                  <div className="col-auto ml-auto">
                    {state.user.settings.google_access_token && (
                      <button
                        className="btn btn-danger"
                        onClick={removeGoogleToken}
                      >
                        Disconnect from Google
                      </button>
                    )}

                    {!state.user.settings.google_access_token && (
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          let w = 640;
                          let h = 800;
                          let left = 150;
                          let tops = 150;
                          let popup = window.open(
                            `${state.app.url.apiWeb}/socialite/google/login?token=${state.user.token}`,
                            "myWindow",
                            `width=${w}, height=${h}, left=${left}, top=${tops},centerscreen`
                          );

                          let timer = setInterval(function () {
                            if (popup.closed) {
                              clearInterval(timer);
                              window.location.reload();
                            }
                          }, 1000);
                        }}
                      >
                        Connect to Google
                      </button>
                    )}
                  </div>
                </div>

                {state.user.settings.google_access_token && (
                  <>
                    <hr />

                    <div className="form-group row">
                      <label className="col-3 col-form-label">
                        Spreadsheet ID
                      </label>
                      <div className="col-9">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="e.g. 1hUYxrxsgZnlPa5qoP_qG53B0gjmTq7GD0Q-1YhG4vkE"
                          value={
                            state.form.settings.connect.google_sheets
                              .sheet_id || ""
                          }
                          onChange={(e) =>
                            dispatch({
                              type: "FORM_CONNECT_GOOGLE_SHEETS",
                              payload: { sheet_id: e.target.value },
                            })
                          }
                        />
                        <small className="form-text">
                          <a
                            href="https://developers.google.com/sheets/api/guides/concepts#common_terms"
                            target="_blank"
                          >
                            How to find my Spreadsheet ID?
                          </a>
                        </small>
                      </div>
                    </div>

                    <hr />

                    <div className="form-group row">
                      <label className="col-3 col-form-label">
                        Sync Spreadsheet
                      </label>
                      <div className="col-9">
                        <p>
                          By pressing the below button, all the data in your
                          Google Sheet will be deleted and all the responses
                          will be inserted into your spreadsheet. If you need
                          the current data in your sheet, make sure you'll make
                          a backup before syncing.
                        </p>
                        <button
                          className="btn btn-primary"
                          onClick={syncGoogleSheet}
                          disabled={sheetSyncing}
                        >
                          {sheetSyncing ? "Syncing..." : "Sync Now"}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="card">
            <h6 className="card-title">
              <a
                className="collapsed"
                data-toggle="collapse"
                href="#tab-calendar"
              >
                <img src={LogoGoogleCalendar} alt="logo" /> Google Calendar{" "}
                <span className="text-muted small">— BETA</span>
              </a>
            </h6>

            <div
              id="tab-calendar"
              className="collapse"
              data-parent="#acc-connect"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-auto">
                    Connect your Google account, so Formito can insert new
                    appointments into your Google Calendar.
                  </div>
                  <div className="col-auto ml-auto">
                    {state.user.settings.google_access_token && (
                      <button
                        className="btn btn-danger"
                        onClick={removeGoogleToken}
                      >
                        Disconnect from Google
                      </button>
                    )}

                    {!state.user.settings.google_access_token && (
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          let w = 640;
                          let h = 800;
                          let left = 150;
                          let tops = 150;
                          let popup = window.open(
                            `${state.app.url.apiWeb}/socialite/google/login?token=${state.user.token}`,
                            "myWindow",
                            `width=${w}, height=${h}, left=${left}, top=${tops},centerscreen`
                          );

                          let timer = setInterval(function () {
                            if (popup.closed) {
                              clearInterval(timer);
                              window.location.reload();
                            }
                          }, 1000);
                        }}
                      >
                        Connect to Google
                      </button>
                    )}
                  </div>
                </div>

                <hr />

                {appointment_blocks.length === 0 && (
                  <div className="alert alert-primary">
                    You need to have an appointment block in your chatbot to
                    enable this integration.
                  </div>
                )}

                {state.user.settings.google_access_token &&
                  appointment_blocks.length > 0 && (
                    <div className="form-group row">
                      <label className="col-3 col-form-label">
                        Enable Integration
                      </label>
                      <div className="col-9">
                        <div
                          className="switch switch-inline"
                          onClick={() =>
                            dispatch({
                              type: "FORM_CONNECT_GOOGLE_CALENDAR",
                              payload: {
                                enable: !state.form.settings.connect
                                  .google_calendar.enable,
                              },
                            })
                          }
                        >
                          <input
                            type="checkbox"
                            className="switch-input"
                            checked={
                              state.form.settings.connect.google_calendar.enable
                            }
                            onChange={(e) =>
                              dispatch({
                                type: "FORM_CONNECT_GOOGLE_CALENDAR",
                                payload: { enable: e.target.checked },
                              })
                            }
                          />
                          <label className="switch-label">
                            &nbsp;&nbsp;Automatically send my scheduled
                            appointment to Google Calendar
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                {state.user.settings.google_access_token &&
                  appointment_blocks.length > 0 &&
                  state.form.settings.connect.google_calendar.enable && (
                    <>
                      <div className="form-group row">
                        <label className="col-3 col-form-label">
                          Calendar ID
                        </label>
                        <div className="col-9">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Type primary for default calendar"
                            value={
                              state.form.settings.connect.google_calendar
                                .calendar_id || ""
                            }
                            onChange={(e) =>
                              dispatch({
                                type: "FORM_CONNECT_GOOGLE_CALENDAR",
                                payload: { calendar_id: e.target.value },
                              })
                            }
                          />
                          <small className="form-text">
                            <a
                              href="http://help.crystalcommerce.com/en/articles/1389811-how-to-find-your-google-calendar-id"
                              target="_blank"
                            >
                              How to find my Calendar ID?
                            </a>
                          </small>
                        </div>
                      </div>

                      <h6 className="divider">Event Details</h6>

                      <div className="form-group row">
                        <label className="col-3 col-form-label">Title</label>
                        <div className="col-9">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="e.g. 15 minutes demo session for Formito"
                            value={
                              state.form.settings.connect.google_calendar
                                .title || ""
                            }
                            onChange={(e) =>
                              dispatch({
                                type: "FORM_CONNECT_GOOGLE_CALENDAR",
                                payload: { title: e.target.value },
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-3 col-form-label">
                          Description
                        </label>
                        <div className="col-9">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="More details about the appointment"
                            value={
                              state.form.settings.connect.google_calendar
                                .description || ""
                            }
                            onChange={(e) =>
                              dispatch({
                                type: "FORM_CONNECT_GOOGLE_CALENDAR",
                                payload: { description: e.target.value },
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-3 col-form-label">Location</label>
                        <div className="col-9">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="e.g. 800 Howard St., San Francisco, CA 94103"
                            value={
                              state.form.settings.connect.google_calendar
                                .location || ""
                            }
                            onChange={(e) =>
                              dispatch({
                                type: "FORM_CONNECT_GOOGLE_CALENDAR",
                                payload: { location: e.target.value },
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-3 col-form-label">
                          Attendee Email Address
                        </label>
                        <div className="col-9">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder=""
                            value={
                              state.form.settings.connect.google_calendar
                                .attendee || ""
                            }
                            onChange={(e) =>
                              dispatch({
                                type: "FORM_CONNECT_GOOGLE_CALENDAR",
                                payload: { attendee: e.target.value },
                              })
                            }
                          />
                          <small className="form-text text-muted">
                            The visitor email address to send appointment
                            notification from Google Calendar.
                          </small>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-3 col-form-label">Timezone</label>
                        <div className="col-9">
                          <select
                            className="custom-select"
                            value={
                              state.form.settings.connect.google_calendar
                                .timezone || "America/New_York"
                            }
                            onChange={(e) =>
                              dispatch({
                                type: "FORM_CONNECT_GOOGLE_CALENDAR",
                                payload: { timezone: e.target.value },
                              })
                            }
                          >
                            <option value="Pacific/Midway">
                              (GMT-11:00) Midway Island, Samoa
                            </option>
                            <option value="America/Adak">
                              (GMT-10:00) Hawaii-Aleutian
                            </option>
                            <option value="Etc/GMT+10">
                              (GMT-10:00) Hawaii
                            </option>
                            <option value="Pacific/Marquesas">
                              (GMT-09:30) Marquesas Islands
                            </option>
                            <option value="Pacific/Gambier">
                              (GMT-09:00) Gambier Islands
                            </option>
                            <option value="America/Anchorage">
                              (GMT-09:00) Alaska
                            </option>
                            <option value="America/Ensenada">
                              (GMT-08:00) Tijuana, Baja California
                            </option>
                            <option value="Etc/GMT+8">
                              (GMT-08:00) Pitcairn Islands
                            </option>
                            <option value="America/Los_Angeles">
                              (GMT-08:00) Pacific Time (US & Canada)
                            </option>
                            <option value="America/Denver">
                              (GMT-07:00) Mountain Time (US & Canada)
                            </option>
                            <option value="America/Chihuahua">
                              (GMT-07:00) Chihuahua, La Paz, Mazatlan
                            </option>
                            <option value="America/Dawson_Creek">
                              (GMT-07:00) Arizona
                            </option>
                            <option value="America/Belize">
                              (GMT-06:00) Saskatchewan, Central America
                            </option>
                            <option value="America/Cancun">
                              (GMT-06:00) Guadalajara, Mexico City, Monterrey
                            </option>
                            <option value="Chile/EasterIsland">
                              (GMT-06:00) Easter Island
                            </option>
                            <option value="America/Chicago">
                              (GMT-06:00) Central Time (US & Canada)
                            </option>
                            <option value="America/New_York">
                              (GMT-05:00) Eastern Time (US & Canada)
                            </option>
                            <option value="America/Havana">
                              (GMT-05:00) Cuba
                            </option>
                            <option value="America/Bogota">
                              (GMT-05:00) Bogota, Lima, Quito, Rio Branco
                            </option>
                            <option value="America/Caracas">
                              (GMT-04:30) Caracas
                            </option>
                            <option value="America/Santiago">
                              (GMT-04:00) Santiago
                            </option>
                            <option value="America/La_Paz">
                              (GMT-04:00) La Paz
                            </option>
                            <option value="Atlantic/Stanley">
                              (GMT-04:00) Faukland Islands
                            </option>
                            <option value="America/Campo_Grande">
                              (GMT-04:00) Brazil
                            </option>
                            <option value="America/Goose_Bay">
                              (GMT-04:00) Atlantic Time (Goose Bay)
                            </option>
                            <option value="America/Glace_Bay">
                              (GMT-04:00) Atlantic Time (Canada)
                            </option>
                            <option value="America/St_Johns">
                              (GMT-03:30) Newfoundland
                            </option>
                            <option value="America/Araguaina">
                              (GMT-03:00) UTC-3
                            </option>
                            <option value="America/Montevideo">
                              (GMT-03:00) Montevideo
                            </option>
                            <option value="America/Miquelon">
                              (GMT-03:00) Miquelon, St. Pierre
                            </option>
                            <option value="America/Godthab">
                              (GMT-03:00) Greenland
                            </option>
                            <option value="America/Argentina/Buenos_Aires">
                              (GMT-03:00) Buenos Aires
                            </option>
                            <option value="America/Sao_Paulo">
                              (GMT-03:00) Brasilia
                            </option>
                            <option value="America/Noronha">
                              (GMT-02:00) Mid-Atlantic
                            </option>
                            <option value="Atlantic/Cape_Verde">
                              (GMT-01:00) Cape Verde Is.
                            </option>
                            <option value="Atlantic/Azores">
                              (GMT-01:00) Azores
                            </option>
                            <option value="Europe/Belfast">
                              (GMT) Greenwich Mean Time : Belfast
                            </option>
                            <option value="Europe/Dublin">
                              (GMT) Greenwich Mean Time : Dublin
                            </option>
                            <option value="Europe/Lisbon">
                              (GMT) Greenwich Mean Time : Lisbon
                            </option>
                            <option value="Europe/London">
                              (GMT) Greenwich Mean Time : London
                            </option>
                            <option value="Africa/Abidjan">
                              (GMT) Monrovia, Reykjavik
                            </option>
                            <option value="Europe/Amsterdam">
                              (GMT+01:00) Amsterdam, Berlin, Bern, Rome,
                              Stockholm, Vienna
                            </option>
                            <option value="Europe/Belgrade">
                              (GMT+01:00) Belgrade, Bratislava, Budapest,
                              Ljubljana, Prague
                            </option>
                            <option value="Europe/Brussels">
                              (GMT+01:00) Brussels, Copenhagen, Madrid, Paris
                            </option>
                            <option value="Africa/Algiers">
                              (GMT+01:00) West Central Africa
                            </option>
                            <option value="Africa/Windhoek">
                              (GMT+01:00) Windhoek
                            </option>
                            <option value="Asia/Beirut">
                              (GMT+02:00) Beirut
                            </option>
                            <option value="Africa/Cairo">
                              (GMT+02:00) Cairo
                            </option>
                            <option value="Asia/Gaza">(GMT+02:00) Gaza</option>
                            <option value="Africa/Blantyre">
                              (GMT+02:00) Harare, Pretoria
                            </option>
                            <option value="Asia/Jerusalem">
                              (GMT+02:00) Jerusalem
                            </option>
                            <option value="Europe/Minsk">
                              (GMT+02:00) Minsk
                            </option>
                            <option value="Asia/Damascus">
                              (GMT+02:00) Syria
                            </option>
                            <option value="Europe/Moscow">
                              (GMT+03:00) Moscow, St. Petersburg, Volgograd
                            </option>
                            <option value="Africa/Addis_Ababa">
                              (GMT+03:00) Nairobi
                            </option>
                            <option value="Asia/Tehran">
                              (GMT+03:30) Tehran
                            </option>
                            <option value="Asia/Dubai">
                              (GMT+04:00) Abu Dhabi, Muscat
                            </option>
                            <option value="Asia/Yerevan">
                              (GMT+04:00) Yerevan
                            </option>
                            <option value="Asia/Kabul">
                              (GMT+04:30) Kabul
                            </option>
                            <option value="Asia/Yekaterinburg">
                              (GMT+05:00) Ekaterinburg
                            </option>
                            <option value="Asia/Tashkent">
                              (GMT+05:00) Tashkent
                            </option>
                            <option value="Asia/Kolkata">
                              (GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi
                            </option>
                            <option value="Asia/Katmandu">
                              (GMT+05:45) Kathmandu
                            </option>
                            <option value="Asia/Dhaka">
                              (GMT+06:00) Astana, Dhaka
                            </option>
                            <option value="Asia/Novosibirsk">
                              (GMT+06:00) Novosibirsk
                            </option>
                            <option value="Asia/Rangoon">
                              (GMT+06:30) Yangon (Rangoon)
                            </option>
                            <option value="Asia/Bangkok">
                              (GMT+07:00) Bangkok, Hanoi, Jakarta
                            </option>
                            <option value="Asia/Krasnoyarsk">
                              (GMT+07:00) Krasnoyarsk
                            </option>
                            <option value="Asia/Hong_Kong">
                              (GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi
                            </option>
                            <option value="Asia/Irkutsk">
                              (GMT+08:00) Irkutsk, Ulaan Bataar
                            </option>
                            <option value="Australia/Perth">
                              (GMT+08:00) Perth
                            </option>
                            <option value="Australia/Eucla">
                              (GMT+08:45) Eucla
                            </option>
                            <option value="Asia/Tokyo">
                              (GMT+09:00) Osaka, Sapporo, Tokyo
                            </option>
                            <option value="Asia/Seoul">
                              (GMT+09:00) Seoul
                            </option>
                            <option value="Asia/Yakutsk">
                              (GMT+09:00) Yakutsk
                            </option>
                            <option value="Australia/Adelaide">
                              (GMT+09:30) Adelaide
                            </option>
                            <option value="Australia/Darwin">
                              (GMT+09:30) Darwin
                            </option>
                            <option value="Australia/Brisbane">
                              (GMT+10:00) Brisbane
                            </option>
                            <option value="Australia/Hobart">
                              (GMT+10:00) Hobart
                            </option>
                            <option value="Asia/Vladivostok">
                              (GMT+10:00) Vladivostok
                            </option>
                            <option value="Australia/Lord_Howe">
                              (GMT+10:30) Lord Howe Island
                            </option>
                            <option value="Etc/GMT-11">
                              (GMT+11:00) Solomon Is., New Caledonia
                            </option>
                            <option value="Asia/Magadan">
                              (GMT+11:00) Magadan
                            </option>
                            <option value="Pacific/Norfolk">
                              (GMT+11:30) Norfolk Island
                            </option>
                            <option value="Asia/Anadyr">
                              (GMT+12:00) Anadyr, Kamchatka
                            </option>
                            <option value="Pacific/Auckland">
                              (GMT+12:00) Auckland, Wellington
                            </option>
                            <option value="Etc/GMT-12">
                              (GMT+12:00) Fiji, Kamchatka, Marshall Is.
                            </option>
                            <option value="Pacific/Chatham">
                              (GMT+12:45) Chatham Islands
                            </option>
                            <option value="Pacific/Tongatapu">
                              (GMT+13:00) Nuku'alofa
                            </option>
                            <option value="Pacific/Kiritimati">
                              (GMT+14:00) Kiritimati
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-3 col-form-label">
                          Date and Time
                        </label>
                        <div className="col-9">
                          <select
                            className="custom-select"
                            value={
                              state.form.settings.connect.google_calendar
                                .appointment || ""
                            }
                            onChange={(e) =>
                              dispatch({
                                type: "FORM_CONNECT_GOOGLE_CALENDAR",
                                payload: { appointment: e.target.value },
                              })
                            }
                          >
                            {appointment_blocks.map((block, index) => (
                              <option
                                value={block}
                                key={index}
                              >{`{{${block}}}`}</option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <h6 className="divider">Available variables</h6>

                      <div className="form-group row">
                        <label className="col-3 col-form-label"></label>
                        <div className="col-9">
                          <p>
                            You can use any of the following values inside the
                            ticket information inputs and custom field's value.
                          </p>
                          <pre style={{ whiteSpace: "pre" }}>
                            {available_variables}
                          </pre>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>

          <h6 className="divider mt-7">Tracking Integrations</h6>

          {!can_use_tracking && (
            <div className="alert alert-warning mb-6">
              <div className="flexbox">
                <div>
                  <strong>Tracking integrations</strong> are not included in
                  your billing plan. Please upgrade to Pro plan to unlock the
                  following integrations.
                </div>
                <Link to="/billing" className="btn btn-sm btn-warning">
                  Upgrade
                </Link>
              </div>
            </div>
          )}

          <div className="card">
            <h6 className="card-title">
              <a className="collapsed" data-toggle="collapse" href="#tab-ga">
                <img src={LogoGoogleAnalytics} alt="logo" /> Google Analytics
              </a>
            </h6>

            <div id="tab-ga" className="collapse" data-parent="#acc-connect">
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-3 col-form-label">Tracking ID</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="e.g. UA-12345678-9"
                      value={state.form.settings.connect.ga.tracking_id || ""}
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_GA_TRACKING_ID",
                          payload: e.target.value,
                        })
                      }
                    />
                    <small className="form-text">
                      <a
                        href="https://support.google.com/analytics/answer/1008080#GAID"
                        target="_blank"
                      >
                        How to find my Google Analytics ID?
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <h6 className="card-title">
              <a className="collapsed" data-toggle="collapse" href="#tab-fb">
                <img src={LogoFacebook} alt="logo" /> Facebook Pixel
              </a>
            </h6>

            <div id="tab-fb" className="collapse" data-parent="#acc-connect">
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-3 col-form-label">Pixel ID</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="e.g. 4501234567890123"
                      value={
                        state.form.settings.connect.facebook.pixel_id || ""
                      }
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_FACEBOOK",
                          payload: { pixel_id: e.target.value },
                        })
                      }
                    />
                    <small className="form-text">
                      <a
                        href="https://www.facebook.com/business/help/314143995668266"
                        target="_blank"
                      >
                        How to find my Facebook Pixel ID?
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <h6 className="card-title">
              <a className="collapsed" data-toggle="collapse" href="#tab-tw">
                <img src={LogoTwitter} alt="logo" /> Twitter Pixel
              </a>
            </h6>

            <div id="tab-tw" className="collapse" data-parent="#acc-connect">
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-3 col-form-label">Website Tag ID</label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="e.g. nsue2"
                      value={state.form.settings.connect.twitter.pixel_id || ""}
                      onChange={(e) =>
                        dispatch({
                          type: "FORM_CONNECT_TWITTER",
                          payload: { pixel_id: e.target.value },
                        })
                      }
                    />
                    <small className="form-text">
                      <a
                        href="https://business.twitter.com/en/solutions/twitter-ads/website-clicks/set-up-conversion-tracking.html"
                        target="_blank"
                      >
                        How to find my Twitter Website Tag ID?
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
