import React, { useContext, useState, useEffect } from "react";
import Store from "store";
import { IoMdSend } from "react-icons/io";
import * as Utils from "utils";

export function Preview(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        placeholder={block.props.placeholder_date}
        autoComplete="off"
      />
      <input
        type="text"
        className="form-control"
        placeholder={block.props.placeholder_time}
        autoComplete="off"
      />
      <button>
        <IoMdSend />
      </button>
    </div>
  );
}

export function Inspector(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];
  const set = (name, value) =>
    dispatch(Utils.Block.update(name, value, block, props.index));

  return (
    <div className="quickview-body">
      <div className="form-group row">
        <label className="col-4 col-form-label">Name</label>
        <div className="col-8">
          <input
            type="text"
            className="form-control"
            onChange={e => set("name", e)}
            value={block.name}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          Delay <small>(sec)</small>
        </label>
        <div className="col-8">
          <input
            type="number"
            className="form-control"
            min="0"
            max="20"
            step="0.5"
            onChange={e => set("props.delay", e.target.value)}
            value={block.props.delay || 0}
          />
        </div>
      </div>

      <div className="form-group flexbox d-none">
        <label className="col-form-label">Multiline</label>
        <div
          className="switch switch-inline"
          onClick={() => set("props.multiline", !block.props.multiline)}
        >
          <input
            type="checkbox"
            className="switch-input"
            checked={block.props.multiline || false}
            onChange={e => set("props.multiline", e.target.checked)}
          />
          <label className="switch-label"></label>
        </div>
      </div>

      <div className="form-group flexbox">
        <label className="col-form-label">Is required</label>
        <div
          className="switch switch-inline"
          onClick={() => set("validation.required", !block.validation.required)}
        >
          <input
            type="checkbox"
            className="switch-input"
            checked={block.validation.required || false}
            onChange={e => set("validation.required", e.target.checked)}
          />
          <label className="switch-label"></label>
        </div>
      </div>

      <div className="divider">Date</div>

      <div className="form-group row">
        <label className="col-4 col-form-label">Placeholder</label>
        <div className="col-8">
          <input
            type="text"
            className="form-control"
            onChange={e => set("props.placeholder_date", e)}
            value={block.props.placeholder_date}
          />
        </div>
      </div>

      <div className="form-group">
        <label className="col-form-label">Available days of week</label>

        <div className="btn-group btn-group-toggle" data-toggle="buttons">
          {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day, index) => (
            <label
              key={index}
              className={
                "btn btn-outline-secondary btn-sm " +
                (block.props.available_days["day_" + index] ? "active" : "")
              }
            >
              <input
                type="checkbox"
                autoComplete="off"
                checked={block.props.available_days["day_" + index]}
                onClick={() => {
                  set("props.available_days", {
                    ...block.props.available_days,
                    ["day_" + index]: !block.props.available_days[
                      "day_" + index
                    ]
                  });
                }}
                onChange={e => true}
              />{" "}
              {day}
            </label>
          ))}
        </div>
      </div>

      <div className="divider">Time</div>

      <div className="form-group row">
        <label className="col-4 col-form-label">Placeholder</label>
        <div className="col-8">
          <input
            type="text"
            className="form-control"
            onChange={e => set("props.placeholder_time", e)}
            value={block.props.placeholder_time}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">Slot interval</label>
        <div className="col-8">
          <select
            className="custom-select"
            onChange={e => set("props.time_interval", e)}
            value={block.props.time_interval}
          >
            {[5, 10, 15, 20, 30, 40, 45, 50, 60, 70, 80, 90, 100, 120].map(
              (x, i) => (
                <option key={x} value={x}>
                  {x} mins
                </option>
              )
            )}
          </select>
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">Availability</label>
        <div className="col-8">
          <select
            className="custom-select w-40"
            onChange={e => set("props.start_hour", e)}
            value={block.props.start_hour}
          >
            {[...Array(24)].map((x, i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </select>

          <span className="d-inline-block text-center w-20"> To </span>

          <select
            className="custom-select w-40"
            onChange={e => set("props.end_hour", e)}
            value={block.props.end_hour}
          >
            {[...Array(24)].map((x, i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

/**
 * Response message
 */
export function Response({ message }) {
  return (
    <div className={`message-item block-${message.type}`}>
      {message.content}
    </div>
  );
}
