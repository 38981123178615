import React, { useContext, useState } from "react";
import Store from "store";
import Repeater from 'components/repeater';
import * as Utils from 'utils';


export function Preview(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  return block.props.items.map( (item, index) => (
    <div className="option" key={index}>
      {item.text}
      {item.jump &&
        <span className="jmp">{item.jump}</span>
      }
    </div>
  ));
}



export function Inspector(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];
  const labels = state.form.blocks.filter((element, index) => element.type == 'label');
  const set = (name, value) => dispatch( Utils.Block.update(name, value, block, props.index) );



  const setProps = (name, value, e=null) => {
    let nextBlock = Object.assign({}, JSON.parse(JSON.stringify(block)));
    nextBlock.props = {
      ...nextBlock.props,
      [name]: value,
    }
    dispatch({
      type: "BLOCK_UPDATE",
      payload: {
        index: props.index,
        item: nextBlock,
      },
    });
  }


  return (
    <div className="quickview-body">
      <div className="form-group row">
        <label className="col-4 col-form-label">Name</label>
        <div className="col-8">
          <input type="text" className="form-control" onChange={(e) => set('name', e)} value={block.name} />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">Delay <small>(sec)</small></label>
        <div className="col-8">
          <input type="number" className="form-control" min="0" max="20" step="0.5"
            onChange={(e) => set('props.delay', e.target.value)}
            value={block.props.delay || 0}
          />
        </div>
      </div>

      <div className="form-group flexbox">
        <label className="col-form-label">Multiple Choice</label>
        <div className="switch switch-inline" onClick={() => set('props.multiple', !block.props.multiple)}>
          <input type="checkbox" className="switch-input" checked={block.props.multiple || false} onChange={(e) => set('props.multiple', e.target.checked)} />
          <label className="switch-label"></label>
        </div>
      </div>

      <h6 className="divider">Repeater</h6>
      <Repeater
        block={block}
        index={props.index}
        title='text'
        defaultAddState={{text: 'Option', jump: ''}}
        render={(item, set, index) => (
          <>
            <div className="form-group row">
              <label className="col-4 col-form-label">Text</label>
              <div className="col-8">
                <input type="text" className="form-control" value={item.text} onChange={e => set('text', e, index)} />
              </div>
            </div>

            {!block.props.multiple &&
              <div className="form-group row">
                <label className="col-4 col-form-label">Jump To</label>
                <div className="col-8">
                  <select className="custom-select" value={block.jump} onChange={e => set('jump', e, index)}>
                    <option value="">No Jump</option>
                    {labels.map( (label, index) => (
                      <option value={label.name} key={index}>{label.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            }

          </>
      )} />

    </div>
  )
}


/**
 * Response message
 */
export function Response({message}) {
  return <div className={`message-item block-${message.type}`}>{message.content}</div>
}
