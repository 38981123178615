import app from './app';
import user from './user';
import forms from './forms';
import form from './form';
import editor from './editor';
import site from './site';
import responses from './responses';


export default function reducer(state, action) {

  return {
    ...state,
    app:       app(state.app, action),
    user:      user(state.user, action),
    forms:     forms(state.forms, action),
    form:      form(state.form, action),
    editor:    editor(state.editor, action),
    site:      site(state.site, action),
    responses: responses(state.responses, action),
  }

}
