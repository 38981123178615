import React, { useContext, useState, useEffect } from "react";
import Store from "store";
import * as Utils from "utils";

export function Preview(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  return (
    <ol className="feedback">
      <li className="feedback-1">
        <svg
          aria-hidden="true"
          focusable="false"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 496 512"
        >
          <g className="fa-group">
            <path
              fill="currentColor"
              d="M248,8C111,8,0,119,0,256S111,504,248,504,496,393,496,256,385,8,248,8ZM118.8,248.3,152.4,208l-33.6-40.3c-8.6-10.3,3.8-24.9,15.4-18l80,48a12,12,0,0,1,0,20.6l-80,48c-11.5,6.8-24-7.6-15.4-18ZM353.5,415.1C327.6,404,289.1,397.7,248,397.7s-79.6,6.3-105.5,17.4c-9.8,4.2-19.4-7-17.7-20.4C132.7,331.8,196.1,288,248,288s115.3,43.8,123.2,106.7C372.9,408.3,363.2,419.3,353.5,415.1Zm8.3-148.8-80-48a12.07,12.07,0,0,1,0-20.6l80-48c11.6-6.9,24,7.7,15.4,18L343.6,208l33.6,40.3c8.7,10.4-3.9,24.8-15.4,18Z"
              className="fa-secondary"
            ></path>
            <path
              fill="currentColor"
              d="M214.2,197.7l-80-48c-11.6-6.9-24,7.7-15.4,18L152.4,208l-33.6,40.3c-8.6,10.4,3.9,24.8,15.4,18l80-48a12,12,0,0,0,0-20.6Zm163,50.6L343.6,208l33.6-40.3c8.6-10.3-3.8-24.9-15.4-18l-80,48a12.07,12.07,0,0,0,0,20.6l80,48C373.3,273.1,385.9,258.7,377.2,248.3Z"
              className="fa-primary"
            ></path>
          </g>
        </svg>
      </li>

      <li className="feedback-2">
        <svg
          aria-hidden="true"
          focusable="false"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 496 512"
        >
          <g className="fa-group">
            <path
              fill="currentColor"
              d="M248,8C111,8,0,119,0,256S111,504,248,504,496,393,496,256,385,8,248,8Zm80,168a32,32,0,1,1-32,32A32,32,0,0,1,328,176Zm-160,0a32,32,0,1,1-32,32A32,32,0,0,1,168,176ZM338.2,394.2a117.5,117.5,0,0,0-180.4,0c-13.5,16.3-38.1-4.2-24.6-20.5a149.34,149.34,0,0,1,229.5.1C376.3,390,351.7,410.5,338.2,394.2Z"
              className="fa-secondary"
            ></path>
            <path
              fill="currentColor"
              d="M168,176a32,32,0,1,0,32,32A32,32,0,0,0,168,176Zm160,0a32,32,0,1,0,32,32A32,32,0,0,0,328,176Z"
              className="fa-primary"
            ></path>
          </g>
        </svg>
      </li>

      <li className="feedback-3">
        <svg
          aria-hidden="true"
          focusable="false"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 496 512"
        >
          <g className="fa-group">
            <path
              fill="currentColor"
              d="M248,8C111,8,0,119,0,256S111,504,248,504,496,393,496,256,385,8,248,8Zm80,168a32,32,0,1,1-32,32A32,32,0,0,1,328,176Zm-160,0a32,32,0,1,1-32,32A32,32,0,0,1,168,176ZM362.8,346.2a149.38,149.38,0,0,1-229.6,0c-13.6-16.3,11-36.7,24.6-20.5a117.5,117.5,0,0,0,180.4,0C351.6,309.5,376.3,329.9,362.8,346.2Z"
              className="fa-secondary"
            ></path>
            <path
              fill="currentColor"
              d="M328,176a32,32,0,1,0,32,32A32,32,0,0,0,328,176Zm-160,0a32,32,0,1,0,32,32A32,32,0,0,0,168,176Z"
              className="fa-primary"
            ></path>
          </g>
        </svg>
      </li>

      <li className="feedback-4">
        <svg
          aria-hidden="true"
          focusable="false"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 496 512"
        >
          <g className="fa-group">
            <path
              fill="currentColor"
              d="M245.47,8C108.5,9.43-1.36,121.56,0,258.53S113.56,505.36,250.53,504,497.36,390.44,496,253.47,382.44,6.64,245.47,8ZM112,223.4c3.3-42.1,32.2-71.38,56-71.38s52.7,29.28,56,71.38c.7,8.6-10.8,11.9-14.9,4.5l-9.5-17c-7.7-13.7-19.2-21.6-31.5-21.6s-23.78,7.9-31.48,21.6l-9.5,17C122.82,235.3,111.32,231.9,112,223.4ZM363.69,345a149.34,149.34,0,0,1-229.54,2.35c-13.67-16.17,10.62-36.81,24.37-20.75a117.57,117.57,0,0,0,180.39-1.84c13.41-16.32,38.08,3.93,24.78,20.24ZM369,227.9l-9.5-17c-7.7-13.7-19.18-21.6-31.48-21.6s-23.8,7.9-31.5,21.6l-9.5,17c-4.1,7.3-15.6,4-14.9-4.5,3.3-42.1,32.2-71.38,56-71.38s52.68,29.28,56,71.38c.58,8.6-11,11.9-15.11,4.5Z"
              className="fa-secondary"
            ></path>
            <path
              fill="currentColor"
              d="M168,152c-23.78,0-52.68,29.28-56,71.38-.7,8.5,10.8,11.9,15.1,4.5l9.5-17c7.7-13.7,19.18-21.6,31.48-21.6s23.8,7.9,31.5,21.6l9.5,17c4.1,7.4,15.6,4.1,14.9-4.5C220.7,181.3,191.8,152,168,152ZM384.08,223.4c-3.3-42.1-32.2-71.38-56-71.38s-52.7,29.28-56,71.38c-.7,8.5,10.8,11.8,14.9,4.5l9.5-17c7.7-13.7,19.2-21.6,31.5-21.6s23.78,7.9,31.48,21.6l9.5,17c4.11,7.4,15.71,4.1,15.11-4.5Z"
              className="fa-primary"
            ></path>
          </g>
        </svg>
      </li>

      <li className="feedback-5">
        <svg
          aria-hidden="true"
          focusable="false"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 496 512"
        >
          <g className="fa-group">
            <path
              fill="currentColor"
              d="M248,8C111,8,0,119,0,256S111,504,248,504,496,393,496,256,385,8,248,8ZM94.6,168.9l34.9-5L145,132.3a7.73,7.73,0,0,1,13.9,0l15.5,31.6,34.9,5a7.78,7.78,0,0,1,4.3,13.2l-25.4,24.6,6,34.9a7.48,7.48,0,0,1-11,7.9L152,233.3l-31.3,16.3a7.48,7.48,0,0,1-11-7.9l6-34.9L90.3,182.2a7.85,7.85,0,0,1,4.3-13.3ZM391.8,338.7c-9.3,55-83.2,93.3-143.8,93.3s-134.5-38.3-143.8-93.3a16.06,16.06,0,0,1,20.7-17.9C155.1,330.5,200,336,248,336s92.9-5.5,123.1-15.2a16,16,0,0,1,20.7,17.9Zm13.9-156.6-25.4,24.6,6,34.9a7.48,7.48,0,0,1-11,7.9L344,233.3l-31.3,16.3a7.48,7.48,0,0,1-11-7.9l6-34.9-25.4-24.6a7.81,7.81,0,0,1,4.3-13.2l34.9-5L337,132.4a7.73,7.73,0,0,1,13.9,0L366.4,164l34.9,5a7.7,7.7,0,0,1,4.4,13.1Z"
              className="fa-secondary"
            ></path>
            <path
              fill="currentColor"
              d="M209.3,168.9l-34.9-5-15.5-31.6a7.73,7.73,0,0,0-13.9,0l-15.5,31.6-34.9,5a7.85,7.85,0,0,0-4.3,13.3l25.4,24.6-6,34.9a7.48,7.48,0,0,0,11,7.9L152,233.3l31.2,16.2a7.48,7.48,0,0,0,11-7.9l-6-34.9,25.4-24.6a7.78,7.78,0,0,0-4.3-13.2Zm192,.1-34.9-5-15.5-31.6a7.73,7.73,0,0,0-13.9,0L321.5,164l-34.9,5a7.81,7.81,0,0,0-6.47,8.95V178a7.92,7.92,0,0,0,2.16,4.24l25.4,24.6-6,34.9a7.48,7.48,0,0,0,11,7.9L344,233.3l31.3,16.2a7.48,7.48,0,0,0,11-7.9l-6-34.9,25.4-24.6a7.68,7.68,0,0,0-4.4-13.1Z"
              className="fa-primary"
            ></path>
          </g>
        </svg>
      </li>
    </ol>
  );
}

export function Inspector(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  const set = (name, value) => {
    dispatch(Utils.Block.update(name, value, block, props.index));
  };

  const setItemValue = (index, value) => {
    const items = [...block.props.items];
    items[index].value = value;
    setProps("items", items);
  };

  const setProps = (name, value, e = null) => {
    let nextBlock = Object.assign({}, JSON.parse(JSON.stringify(block)));
    nextBlock.props = {
      ...nextBlock.props,
      [name]: value
    };
    dispatch({
      type: "BLOCK_UPDATE",
      payload: {
        index: props.index,
        item: nextBlock
      }
    });
  };

  return (
    <div className="quickview-body">
      <div className="form-group row">
        <label className="col-4 col-form-label">Name</label>
        <div className="col-8">
          <input
            type="text"
            className="form-control"
            onChange={e => set("name", e)}
            value={block.name}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          Delay <small>(sec)</small>
        </label>
        <div className="col-8">
          <input
            type="number"
            className="form-control"
            min="0"
            max="20"
            step="0.5"
            onChange={e => set("props.delay", e.target.value)}
            value={block.props.delay || 0}
          />
        </div>
      </div>

      <h6 className="divider">Values</h6>

      {["1", "2", "3", "4", "5"].map((item, index) => (
        <div key={index} className="form-group row">
          <label className="col-4 col-form-label">Option {item}</label>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              onChange={e => setItemValue(index, e.target.value)}
              value={block.props.items[index].value}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

/**
 * Response message
 */
export function Response({ message }) {
  return (
    <div className={`message-item block-${message.type}`}>
      {message.content}
    </div>
  );
}
