import React, { useContext, useState } from "react";
import Store from "store";
import TheEditor from 'components/TheEditor';
import * as Utils from 'utils';


/**
 * Block Preview
 */
export function Preview(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  return <div dangerouslySetInnerHTML={{__html: Utils.Misc.nl2br(block.props.text)}} />;
}


/**
 * Inspector
 */
export function Inspector(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];
  const set = (name, value) => dispatch( Utils.Block.update(name, value, block, props.index) );


  return (
    <div className="quickview-body">
      <div className="form-group">
        <label>Text</label>
        {/*
        <TheEditor
          content={block.props.text}
          onChange={e => set('props.text', e)}
          toolbar="slim"
        />
        */}
        <textarea className="form-control" rows="3" onChange={(e) => set('props.text', e.target.value)} value={block.props.text} />

      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">Delay <small>(sec)</small></label>
        <div className="col-8">
          <input type="number" className="form-control" min="0" max="20" step="0.5"
            onChange={(e) => set('props.delay', e.target.value)}
            value={block.props.delay || 0}
          />
        </div>
      </div>
    </div>
  )
}


/**
 * Response message
 */
export function Response({message}) {
  return <div className={`message-item block-${message.type}`}>{message.content}</div>
}
