import React, { useContext, useState } from "react";
import Store from "store";
import * as Utils from 'utils';
import Repeater from 'components/repeater';
import TheEditor from 'components/TheEditor';


export function Preview(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  return block.props.items.map( (item, index) => (
    <div key={index} className="link-item">{item.title}</div>
  ))
}



export function Inspector(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];
  const set = (name, value) => dispatch( Utils.Block.update(name, value, block, props.index) );


  return (
    <div className="quickview-body">
      <div className="form-group row">
        <label className="col-4 col-form-label">Delay <small>(sec)</small></label>
        <div className="col-8">
          <input type="number" className="form-control" min="0" max="20" step="0.5"
            onChange={(e) => set('props.delay', e.target.value)}
            value={block.props.delay || 0}
          />
        </div>
      </div>


      <h6 className="divider d-none">Links</h6>

      <Repeater
        block={block}
        index={props.index}
        title='title'
        defaultAddState={{title: '', text: ''}}
        render={(item, set, index) => (
          <>
            <div className="form-group">
              <input type="text" className="form-control" value={item.title || ''} onChange={e => set('title', e, index)} placeholder="Title" />
            </div>

            <div className="form-group">
              <TheEditor
                content={item.text}
                onChange={e => set('text', e, index)}
                toolbar="slim"
              />
            </div>


            {/*
            <div className="form-group">
              <textarea type="text" className="form-control" value={item.text} onChange={e => set('text', e, index)} placeholder="Content" />
            </div>
            */}
          </>
        )} />

    </div>
  )
}


/**
 * Response message
 */
export function Response({message}) {
  if (!message.props || message.props.items === undefined) {
    return null;
  }

  return (
    <div className={`message-item block-${message.type}`}>
      {message.props.items.map( (item, index) => <div key={index} className="link-item">{item.text}</div> )}
    </div>
  )
}
