import $ from 'jquery';

$(document).ready(function() {

  $('[data-toggle="popover"]').popover();

  /**
   * Add/remove .focus to .input-group
   */
  $(document).on('focusin', '.input-group', function() {
    $(this).addClass('focus');
  });

  $(document).on('focusout', '.input-group', function() {
    $(this).removeClass('focus');
  });


  // Switch
  //
  $(document).on('click', '.switch', function() {
    var input = $(this).children('.switch-input').not(':disabled');
    input.prop('checked', ! input.prop('checked')).trigger("change");
  });


  // Custom control check
  //
  // Since BS4-beta-3, custom-controls needs id and for attributes.
  // We bypass this requirement.
  //
  $(document).on('click', '.custom-checkbox', function() {
    var input = $(this).children('.custom-control-input').not(':disabled');
    input.prop('checked', ! input.prop('checked')).trigger( "change" );
  });

  $(document).on('click', '.custom-radio', function() {
    var input = $(this).children('.custom-control-input').not(':disabled');
    input.prop('checked', true).trigger( "change" );
  });


  // Image uploader
  //
  $(document).on('change', '.uploader-image input[type="file"]', function(e) {
    const input = e.target;
    const img = $(this).closest('.uploader-image').find('img');

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function(r) {
        img.attr('src', r.target.result);
      }

      reader.readAsDataURL(input.files[0]);
    }
  })


})
