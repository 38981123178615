import React, { useContext, useState, useEffect } from "react";
import Store from "store";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";

export default function Register() {
  const { state, dispatch } = useContext(Store);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [pass2, setPass2] = useState("");
  const [error, setError] = useState("");

  const [btnDisable, setBtnDisable] = useState(false);

  useEffect(() => {
    const cb = event => {
      try {
        const response = JSON.parse(event.data);
        if ("access_token" in response) {
          setAccessToken(response.access_token);
        }
      } catch (e) {
        //console.log(e);
      }
    };
    window.addEventListener("message", cb, false);
    return () => {
      window.removeEventListener("message", cb, false);
    };
  }, []);

  useEffect(() => {
    var url = new URL(window.location.href);
    var GET_email = url.searchParams.get("email");
    if (GET_email) {
      setEmail(GET_email);
    }
  }, []);

  if (state.user.auth) {
    return <Redirect to="/" />;
  }

  const setAccessToken = token => {
    dispatch({
      type: "USER_UPDATE_TOKEN",
      payload: token
    });
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  };

  const doRegister = async () => {
    const validEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!validEmail.test(email)) {
      setError("Please insert a correct email address.");
      return;
    }

    if (pass.length < 6) {
      setError("Your password should be at least 6 characters long.");
      return;
    }

    /*
    if (pass !== pass2) {
      setError('Password confirmation is not equal to the password.');
      return;
    }
    */

    const data = {
      name: name,
      email: email,
      password: pass
      //password_confirmation: pass,
    };

    setBtnDisable(true);

    await axios
      .post("/user/register", data)
      .then(response => {
        setAccessToken(response.data.access_token);
        dispatch({ type: "APP_READY" });
        return <Redirect to="/" />;
      })
      .catch(error => {
        let msg = "Something went wrong. Please try again later.";
        if (error.data.errors) {
          msg = error.data.errors[Object.keys(error.data.errors)[0]][0];
        }
        setError(msg);
        setBtnDisable(false);
      });
  };

  return (
    <main className="main-content centered-box bg-img-auth">
      <div className="card-auth">
        <h5 className="mb-7">Create an account</h5>

        <button
          className="btn btn-lg btn-block btn-outline-secondary position-relative"
          onClick={() => {
            let w = 640;
            let h = 800;
            let left = 150;
            let tops = 150;
            let popup = window.open(
              `${state.app.url.apiWeb}/socialite/auth/redirect/google`,
              "myWindow",
              `width=${w}, height=${h}, left=${left}, top=${tops},centerscreen`
            );

            let timer = setInterval(function() {
              if (popup.closed) {
                clearInterval(timer);
                //window.location.reload();
              }
            }, 1000);
          }}
        >
          <span className="btn-fixed-icon">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="18px"
              height="18px"
              viewBox="0 0 48 48"
            >
              <g>
                <path
                  fill="#EA4335"
                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                ></path>
                <path
                  fill="#4285F4"
                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                ></path>
                <path
                  fill="#FBBC05"
                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                ></path>
                <path
                  fill="#34A853"
                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                ></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </g>
            </svg>
          </span>
          <span>Continue with Google</span>
        </button>

        <h3 className="divider">OR</h3>

        {error !== "" && <div className="alert alert-danger">{error}</div>}

        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="name"
            placeholder="Your Name"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>

        <div className="form-group">
          <input
            type="email"
            className="form-control"
            name="email"
            placeholder="Email Address"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>

        <div className="form-group">
          <input
            type="password"
            className="form-control"
            name="password"
            placeholder="Password"
            value={pass}
            onChange={e => setPass(e.target.value)}
          />
        </div>

        <div className="form-group d-none">
          <input
            type="password"
            className="form-control"
            name="password-confirm"
            placeholder="Password (confirm)"
            value={pass2}
            onChange={e => setPass2(e.target.value)}
          />
        </div>

        <div className="form-group">
          By registering in our service, you should be agree to our{" "}
          <a href="https://formito.com/terms.html" target="_blank">
            terms of service
          </a>
          .
        </div>

        <div className="form-group">
          <button
            className="btn btn-block btn-primary"
            onClick={doRegister}
            disabled={btnDisable}
          >
            Register with Email
          </button>
        </div>

        <hr className="w-30" />

        <p className="text-center text-muted small-2">
          Already a member? <Link to="/user/login">Login here</Link>
        </p>
      </div>
    </main>
  );
}
