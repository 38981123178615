import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Store from "store";
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from "react-sortable-hoc";
import TheBlock from "form/blocks/TheBlock";
import { IoMdTrash, IoMdMove, IoMdCopy, IoMdAlbums } from "react-icons/io";
import { blocksList } from "form/blocks";

//const leftBlocks  = blocksList.left.map(x => x.type);
//const rightBlocks = blocksList.right.map(x => x.type);

const SortableContainer = sortableContainer(({ children }) => {
  return <ol className="messages">{children}</ol>;
});

const SortableItem = sortableElement(({ item, theindex }) => {
  const { state, dispatch } = useContext(Store);
  const index = theindex;

  let classes = `message message-${item.align} block-${item.type}-wrapper`;
  classes += state.editor.inspector.blockIndex === index ? " active" : "";
  if (item.type === "cover" && item.props.visible === false) {
    classes += " message-invisible";
  }

  /*
  if (leftBlocks.indexOf(item.type) > -1) {
    classes += ' message-left';
  } else {
    classes += ' message-right';
  }
  */

  function removeWidget(index, e) {
    e.stopPropagation();

    const block = state.form.blocks[index];
    if (block.type === "image") {
      let src = block.props.src || null;
      if (src) {
        axios
          .post(`/form/upload/remove`, { path: src })
          .then(response => {})
          .catch(error => {});
      }
    }

    dispatch({ type: "BLOCKS_DELETE", payload: index });
  }

  function duplicateWidget(index, e) {
    e.stopPropagation();
    dispatch({ type: "BLOCKS_DUPLICATE", payload: index });
  }

  function editWidget(index) {
    dispatch({ type: "INSPECTOR_OPEN", payload: index });
  }

  return (
    <li className={classes} onClick={e => editWidget(index)}>
      <div
        className={`message-item block-${item.type} ${
          item.props.stacked ? "stacked" : ""
        }`}
      >
        <TheBlock type={item.type} index={index} />
      </div>

      <div className="message-tools">
        {item.type !== "cover" && (
          <>
            <DragHandle />
            <button className="do-remove" onClick={e => removeWidget(index, e)}>
              <IoMdTrash /> <span>Remove</span>
            </button>
            {item.type !== "image" && (
              <button onClick={e => duplicateWidget(index, e)}>
                <IoMdAlbums /> <span>Duplicate</span>
              </button>
            )}
          </>
        )}
      </div>
    </li>
  );
});

const DragHandle = sortableHandle(() => (
  <button className="message-move">
    <IoMdMove /> <span>Move</span>
  </button>
));

export default function Builder() {
  const { state, dispatch } = useContext(Store);

  function doSort({ oldIndex, newIndex }) {
    dispatch({ type: "BLOCKS_REORDER", payload: { oldIndex, newIndex } });
    dispatch({ type: "INSPECTOR_OPEN", payload: newIndex });
  }

  useEffect(() => {}, []);

  return (
    <>
      <div className="editor">
        <SortableContainer
          onSortEnd={doSort}
          useDragHandle
          useWindowAsScrollContainer={true}
        >
          {state.form.blocks.map((item, index) => (
            <SortableItem
              key={index}
              index={index}
              theindex={index}
              item={item}
            />
          ))}
        </SortableContainer>
      </div>

      <div className="formito-container d-none">
        <div className="fo-app">
          <iframe className="fo-iframe" src="about:blank"></iframe>
        </div>
        <div className="fo-badge">
          <div className="fo-close">&times;</div>
        </div>
      </div>
    </>
  );
}
