import React, { useContext, useState } from "react";
import Store from "store";
import * as Utils from 'utils';

export function Preview(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  return (
    <span>{block.props.to}</span>
  );
}


export function Inspector(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];
  const labels = state.form.blocks.filter((element, index) => element.type == 'label');
  const set = (name, value) => dispatch( Utils.Block.update(name, value, block, props.index) );


  return (
    <div className="quickview-body">
      <div className="form-group row">
        <label className="col-4 col-form-label">Target Label</label>
        <div className="col-8">
          <select className="custom-select" value={block.props.to} onChange={(e) => set('props.to', e)}>
            <option value="">No Jump</option>
            {labels.map( (label, index) => (
              <option value={label.name} key={index}>{label.name}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}
