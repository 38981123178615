import React, { useContext, useState } from "react";
import Store from "store";
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';

export default function Reset({match}) {
  const { state, dispatch } = useContext(Store);
  const [ pass, setPass] = useState();
  const [ pass2, setPass2] = useState();
  const [ hasError, setHasError] = useState();
  const [ hasAuth, setHasAuth] = useState(false);

  const doResetPass = async () => {
    setHasError(false);
    let data = {
      password: pass,
      password_confirmation: pass2,
    }
    await axios.post('/user/password/reset/'+ match.params.token, data)
    .then(response => {
      setHasAuth(true);
    })
    .catch(error => {
      setHasError(true);
    })
  }

  if (state.user.auth || hasAuth) {
    return <Redirect to='/' />
  }

  return (
    <main className="main-content centered-box bg-img-auth">
      <div className="card-auth">
        <h5 className="mb-6">Reset your password</h5>

        {hasError && <div className="alert alert-danger">The given data was invalid.</div>}

        <div className="form-group">
          <input type="password" className="form-control" name="password" placeholder="New Password" onChange={ (e) => setPass(e.target.value) } />
        </div>

        <div className="form-group">
          <input type="password" className="form-control" name="password_confirmation" placeholder="Repeat New Password" onChange={ (e) => setPass2(e.target.value) } />
        </div>

        <button className="btn btn-block btn-primary" onClick={doResetPass}>Save New Password</button>

      </div>
    </main>
  )
}

