import React, { useContext, useState } from "react";
import Store from "store";
import axios from 'axios';
import * as Utils from 'utils';
import PlaceholderImage from 'assets/img/placeholder/image.png';

/**
 * Block Preview
 */
export function Preview(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];

  return (
    <img src={block.props.src ? state.app.url.storage + block.props.src : PlaceholderImage} />
  );
}


/**
 * Inspector
 */
export function Inspector(props) {
  const { state, dispatch } = useContext(Store);
  const block = state.form.blocks[props.index];
  const set = (name, value) => dispatch( Utils.Block.update(name, value, block, props.index) );

  const [ btnName, setBtnName ] = useState('Save Image');
  const [ file, setFile ] = useState();


  const saveImage = () => {
    setBtnName('Saving...');
    const data = new FormData();
    data.append('media', file);
    data.append('removeOld', block.props.src);

    axios.post(`/form/upload/block_image/${state.form.slug}`, data)
    .then(response => {
      setBtnName('Saved');
      set('props.src', response.data.url);
      document.getElementById('btn-form-save').click();
      setTimeout(() => {
        setBtnName('Save Image');
        setFile(null);
      }, 500);
    })
    .catch(error => {
    })
  }


  return (
    <div className="quickview-body">
      <div className="form-group row">
        <label className="col-4 col-form-label">Image</label>
        <div className="col-8">
          <div className="uploader-image custom-file file-group">
            <input type="file" className="custom-file-input" id="customFile" accept=".png, .jpg, .jpeg, .gif" onChange={ (e) => setFile(e.target.files[0]) } />
            <label className="file-value" htmlFor="customFile">
              <img src={block.props.src ? state.app.url.storage + block.props.src : PlaceholderImage} />
            </label>

            {file && <button className="btn btn-block btn-sm btn-secondary" onClick={saveImage}>{btnName}</button>}
          </div>
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">Delay <small>(sec)</small></label>
        <div className="col-8">
          <input type="number" className="form-control" min="0" max="20" step="0.5"
            onChange={(e) => set('props.delay', e.target.value)}
            value={block.props.delay || 0}
          />
        </div>
      </div>
    </div>
  )
}


/**
 * Response message
 */
export function Response({message}) {
  const { state, dispatch } = useContext(Store);
  return (
    <div className={`message-item block-${message.type}`}>
      <img src={message.props.src ? state.app.url.storage + message.props.src : PlaceholderImage} />
    </div>
  )
}
